<template>
  <div class="aistart" style="margin-right: 20px;">
    <span class="title">Operation Status</span>
    <el-card class="startcard">
      <div class="time" v-if="modellist.result.SystemRunningTime">
        <div style="margin-bottom: 5px">
          System Installation Date：
          <span>
            {{ modellist.result.SystemInstallationTime }}
          </span>
        </div>
        <div>
          System Running since：
          <span>
            {{ modellist.result.SystemRunningTime }}
          </span>
        </div>
      </div>

      <div class="contents" v-if="modellist.result.SystemRunningTime">
        <div class="chart">
          <pie :codMain="modellist.index + 'modellist'" :ChartData="modellist.chartdata"
            style="width: 100%; height: 100%; margin-right:20px">
          </pie>
        </div>

      </div>
    </el-card>
  </div>
</template>

<script>
import pie from "../component/pie";
export default {
  components: {
    pie,
  },
  data() {
    return {
      warningData: [],
      openIndex: "",
      warningAllData: null,
      timer: "",
      animate: false,
      modellist: {},
      allmodellist: [
        {
          index: 0,
          id: 0,
          name: 'Smart Aeration System(AO)',
          chartdata: [
            {
              name: "Manual Control",
              number: "3d 21h 48min",
              value: 3
            },
            {
              name: "AI Control ",
              number: "101d 1h 8min",
              value: 97
            }
          ],
          result: {
            SystemRunningTime: "2023-12-27 10:03:52",
            SystemInstallationTime: "2023-12-27 10:13:32"
          }
        },
        {
          index: 1,
          id: 1,
          name: 'Smart Denitrification Control System',
          chartdata: [
            {
              name: "Manual Control",
              number: "23d 21h 48min",
              value: 21.6
            },
            {
              name: "AI Control ",
              number: "84d 1h 8min",
              value: 78.4
            }
          ],
          result: {
            SystemRunningTime: "2023-12-07 10:03:52",
            SystemInstallationTime: "2023-12-07 10:13:32"
          }
        },
        {
          index: 2,
          id: 2,
          name: 'Smart Carrier Control System(MBBR)',
          chartdata: [
            {
              name: "Manual Control",
              number: "53d 21h 48min",
              value: 76
            },
            {
              name: "AI Control ",
              number: "17d 1h 8min",
              value: 24
            }
          ],
          result: {
            SystemRunningTime: "2024-01-07 10:03:52",
            SystemInstallationTime: "2024-01-07 10:13:32"
          }
        },
        {
          index: 3,
          id: 3,
          name: 'Smart Chemical Treatment Control System(PAC/PAM)',
          chartdata: [
            {
              name: "Manual Control",
              number: "0d 0h 0min",
              value: 0
            },
            {
              name: "AI Control ",
              number: "95d 1h 8min",
              value: 100
            }
          ],
          result: {
            SystemRunningTime: "2024-03-13 10:03:52",
            SystemInstallationTime: "2023-12-21 09:42:15"
          }
        },
        {
          index: 4,
          id: 4,
          name: 'Smart Ozonation Control System',
          chartdata: [
            {
              name: "Manual Control",
              number: "29d 21h 48min",
              value: 36
            },
            {
              name: "AI Control ",
              number: "53d 1h 8min",
              value: 64
            }
          ],
          result: {
            SystemRunningTime: "2024-03-13 10:03:52",
            SystemInstallationTime: "2023-12-25 10:56:38"
          }
        },
        {
          index: 5,
          id: 5,
          name: 'Smart Carrier Control System(CBR)',
          chartdata: [
            {
              name: "Manual Control",
              number: "9d 21h 48min",
              value: 15
            },
            {
              name: "AI Control ",
              number: "52d 1h 8Min",
              value: 85
            }
          ],
          result: {
            SystemRunningTime: "2024-03-13 10:03:52",
            SystemInstallationTime: "2024-01-09 09:12:00"
          }
        },
        {
          index: 6,
          id: 6,
          name: 'Smart Chemical Treatment Control System(NaClO)',
          chartdata: [
            {
              name: "Manual Control",
              number: "0d 0h 0min",
              value: 0
            },
            {
              name: "AI Control ",
              number: "93d 1h 8min",
              value: 100
            }
          ],
          result: {
            SystemRunningTime: "2024-03-13 10:03:52",
            SystemInstallationTime: "2023-12-15 10:38:43"
          }
        },
        {
          index: 7,
          id: 7,
          name: 'Effluent Parameters',
          chartdata: [
            {
              name: "Manual Control",
              number: "50d 21h 48min",
              value: 81
            },
            {
              name: "AI Control ",
              number: "12d 1h 8min",
              value: 19
            }
          ],
          result: {
            SystemRunningTime: "2024-03-13 10:03:52",
            SystemInstallationTime: "2024-01-15 10:13:32"
          }
        },
      ],
      type: 'visit'
    };
  },
  watch: {
    "$route.query.index"(newVal, oldVal) {
      console.log(newVal);
      this.openIndex = newVal;
    },

  },
  created() {
    this.openIndex = this.$route.query.index;
    this.type = localStorage.getItem("type")
      ? JSON.parse(localStorage.getItem("type"))
      : "visit1"
    console.log(localStorage.getItem("type"), JSON.parse(localStorage.getItem("type")) == 'visit');
    this.modellist = this.allmodellist[this.openIndex]
    this.timer = setInterval(this.scroll, 3000);
  },
  mounted() { },

  methods: {
  },
};
</script>

<style lang="scss" scoped>
.aistart {
  width: 100%;
  height: 100%;

  .title {
    font-size: 16px;
    display: block;
    text-align: left;
    padding-top: 20px;
    font-weight: 600;
    color: rgb(76, 76, 76);
    margin-left: 8px;
  }

  .startcard {
    margin-top: 20px;
    height: 510px;
    border-radius: 16px;

    .time {
      margin: 16px;
      margin-top: 12px;
      font-size: 16px;
    }
  }

  ::v-deep .el-table {
    width: 100% !important;
    margin-top: 20px;
    border-radius: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
  }

  .contents {
    display: flex;
    position: relative;
    width: 100%;

    .chart {
      flex: 1;
      height: 400px;
    }

    .text {
      position: absolute;
      flex: 1;
      top: 56%;
      transform: translateY(-50%);
      right: 15%;

      .legend {
        height: 100%;
        display: flex;
        vertical-align: middle;
        margin-bottom: 20px;
        margin-top: 25px;
      }

      .point {
        // flex: 1;
        margin-top: 16px;
        width: 12px;
        height: 12px;
        // line-height: 50px;
        border-radius: 6px;
        background-color: red;
        margin-right: 10px;
      }
    }
  }
}
</style>