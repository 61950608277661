<template>
  <div class="total-effluent-container">
    <div class="incoming-water-box" v-loading="incomingWaterLoading">
      <LineChart :chartOptions="incomingWaterOptions" height="100%" chartTitle="Influent Characteristics" />
    </div>
    <div class="linkage-module-box">
      <LinkageModuleTN v-if="code === 'tn'" :tabName="tabName" :moduleList="moduleList" />
      <LinkageModuleCOD v-else :tabName="tabName" :moduleList="moduleList" />
    </div>
    <div class="post-linkage-box">
      <div class="line-chart-box" v-loading="postLinkageLoading">
        <LineChart :chartOptions="postLinkageLineOptions" height="100%" :chartTitle="postLinkageLineOptions.chartTitle"
          @onChartDataZoom="onChartDataZoom(postLinkageLineOptions.chartTitle)" />
      </div>
      <div class="pie-chart-box" v-loading="postLinkagePieLoading">
        <PieChart :chartOptions="postLinkagePieOptions" height="100%" />
      </div>
    </div>
  </div>
</template>

<script>
import LineChart from "@/components/SimModule/components/LineChart.vue";
import PieChart from "@/components/ChartsComponents/PieChart.vue";
import LinkageModuleTN from "./LinkageModuleTN.vue";
import LinkageModuleCOD from "./LinkageModuleCOD.vue";
import commonApi from "@/api/common.js";

let timer = null;
export default {
  components: {
    LineChart,
    PieChart,
    LinkageModuleTN,
    LinkageModuleCOD,
  },
  props: {
    tabName: String,
    code: String,
    moduleList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      initChartOptions: {
        animation: true,
        animationDuration: 1500,
        dataZoom: [
          {
            type: "inside",
          },
        ],
        color: ['rgba(0, 128, 128, 1)'],
        legend: {
          data: "",
          color: ['red'],
          itemHeight: 11,
          itemWidth: 22,
          textStyle: {
            rich: {
              a: {
                verticalAlign: "middle",
              },
            },
            padding: [0, 0, -2, 0],
          },
        },
        grid: {
          top: "24%",
          left: "3%",
          right: "4%",
          bottom: "5%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: false,
          },
        },
        // tooltip: {
        //   confine: true,
        //   trigger: "axis",
        //   axisPointer: {
        //     type: "line",
        //     z: 0,
        //     lineStyle: {
        //       color: "#2D3443",
        //     },
        //   },
        //   textStyle: {
        //     align: "left",
        //     // color: 'red'
        //   },
        //   extraCssText: "z-index: 99999",
        // },
        series: [
          {
            name: "",
            data: "",
            symbol: "none",
            smooth: true,
            lineStyle: { width: 1.6 },
            type: "line",
            label: {
              show: false,
              position: "right",
            },
            itemStyle: {
              lineStyle: {
                color: 'rgba(0, 128, 128, 1)',
                width: 1.2,
              },
            },
            zlevel: 999,
          },
        ],
      },
      incomingWaterOptions: {},
      postLinkageLineOptions: {},
      postLinkagePieOptions: {
        // tooltip: {
        //   trigger: "item",
        //   formatter: "{b}: {c} ({d}%)",
        // },
        color: ['rgba(64, 224, 208, 1)', 'rgba(255, 99, 71, 1)'],
        title: [
          {
            text: "",
            left: "49%",
            top: "48%",
            textAlign: "center",
            textBaseline: "middle",
            textStyle: {
              color: "#ffff",
              fontWeight: "normal",
              fontSize: 14,
            },
          },
          {
            text: "Removal Distribution",
            left: "2%",
            top: "2%",
            textBaseline: "middle",
            textStyle: {
              color: "#ffff",
              fontWeight: "normal",
              fontSize: 14,
            },
          },
        ],
        legend: {
          orient: "horizontal",
          left: "center",
          bottom: "4%",
          itemWidth: 12,
          itemHeight: 12,
          icon: "circle",
          itemGap: 30,
          textStyle: {
            color: "#ffff",
            fontSize: 14,
            padding: [0, 0, 0, 4],
          },
        },
        series: [
          {
            hoverAnimation: false,
            radius: [84, 56],
            name: "pie",
            type: "pie",
            selectedMode: "single",
            selectedOffset: 16,
            clockwise: true,
            startAngle: 90,
            label: {
              normal: {
                show: false,
              },
              color: '#fffff'
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            data: [],
          },
        ],
      },
      incomingWaterLoading: false,
      postLinkageLoading: false,
      postLinkagePieLoading: false,
      incomingWatertndata: {
        "xAxis": [
          "2024-09-19 13:29:02",
          "2024-09-19 13:30:02",
          "2024-09-19 13:31:02",
          "2024-09-19 13:32:02",
          "2024-09-19 13:33:02",
          "2024-09-19 13:34:02",
          "2024-09-19 13:35:02",
          "2024-09-19 13:36:02",
          "2024-09-19 13:37:02",
          "2024-09-19 13:38:02",
          "2024-09-19 13:39:02",
          "2024-09-19 13:40:02",
          "2024-09-19 13:41:02",
          "2024-09-19 13:42:02",
          "2024-09-19 13:43:02",
          "2024-09-19 13:44:02",
          "2024-09-19 13:45:02",
          "2024-09-19 13:46:02",
          "2024-09-19 13:47:02",
          "2024-09-19 13:48:02",
          "2024-09-19 13:49:02",
          "2024-09-19 13:50:02",
          "2024-09-19 13:51:02",
          "2024-09-19 13:52:02",
          "2024-09-19 13:53:02",
          "2024-09-19 13:54:02",
          "2024-09-19 13:55:02",
          "2024-09-19 13:56:02",
          "2024-09-19 13:57:02",
          "2024-09-19 13:58:02",
          "2024-09-19 13:59:02",
          "2024-09-19 14:00:02",
          "2024-09-19 14:01:02",
          "2024-09-19 14:02:02",
          "2024-09-19 14:03:02",
          "2024-09-19 14:04:02",
          "2024-09-19 14:05:02",
          "2024-09-19 14:06:02",
          "2024-09-19 14:07:02",
          "2024-09-19 14:08:02",
          "2024-09-19 14:09:02",
          "2024-09-19 14:10:02",
          "2024-09-19 14:11:02",
          "2024-09-19 14:12:02",
          "2024-09-19 14:13:02",
          "2024-09-19 14:14:02",
          "2024-09-19 14:15:02",
          "2024-09-19 14:16:02",
          "2024-09-19 14:17:02",
          "2024-09-19 14:18:02",
          "2024-09-19 14:19:02",
          "2024-09-19 14:20:02",
          "2024-09-19 14:21:02",
          "2024-09-19 14:22:02",
          "2024-09-19 14:23:02",
          "2024-09-19 14:24:02",
          "2024-09-19 14:25:02",
          "2024-09-19 14:26:02",
          "2024-09-19 14:27:02",
          "2024-09-19 14:28:02",
          "2024-09-19 14:29:02",
          "2024-09-19 14:30:02",
          "2024-09-19 14:31:02",
          "2024-09-19 14:32:02",
          "2024-09-19 14:33:02",
          "2024-09-19 14:34:02",
          "2024-09-19 14:35:02",
          "2024-09-19 14:36:02",
          "2024-09-19 14:37:02",
          "2024-09-19 14:38:02",
          "2024-09-19 14:39:02",
          "2024-09-19 14:40:02",
          "2024-09-19 14:41:02",
          "2024-09-19 14:42:02",
          "2024-09-19 14:43:02",
          "2024-09-19 14:44:02",
          "2024-09-19 14:45:02",
          "2024-09-19 14:46:02",
          "2024-09-19 14:47:02",
          "2024-09-19 14:48:02",
          "2024-09-19 14:49:02",
          "2024-09-19 14:50:02",
          "2024-09-19 14:51:02",
          "2024-09-19 14:52:02",
          "2024-09-19 14:53:02",
          "2024-09-19 14:54:02",
          "2024-09-19 14:55:02",
          "2024-09-19 14:56:02",
          "2024-09-19 14:57:02",
          "2024-09-19 14:58:02",
          "2024-09-19 14:59:02",
          "2024-09-19 15:00:02",
          "2024-09-19 15:01:02",
          "2024-09-19 15:02:02",
          "2024-09-19 15:03:02",
          "2024-09-19 15:04:02",
          "2024-09-19 15:05:02",
          "2024-09-19 15:06:02",
          "2024-09-19 15:07:03",
          "2024-09-19 15:08:02",
          "2024-09-19 15:09:03",
          "2024-09-19 15:10:02",
          "2024-09-19 15:11:03",
          "2024-09-19 15:12:02",
          "2024-09-19 15:13:02",
          "2024-09-19 15:14:02",
          "2024-09-19 15:15:02",
          "2024-09-19 15:16:03",
          "2024-09-19 15:17:03",
          "2024-09-19 15:18:02",
          "2024-09-19 15:19:03",
          "2024-09-19 15:20:03",
          "2024-09-19 15:21:03",
          "2024-09-19 15:22:03",
          "2024-09-19 15:23:03",
          "2024-09-19 15:24:03",
          "2024-09-19 15:25:03",
          "2024-09-19 15:26:03",
          "2024-09-19 15:27:03",
          "2024-09-19 15:28:03",
          "2024-09-19 15:29:03",
          "2024-09-19 15:30:03",
          "2024-09-19 15:31:03",
          "2024-09-19 15:32:03",
          "2024-09-19 15:33:03",
          "2024-09-19 15:34:03",
          "2024-09-19 15:35:03",
          "2024-09-19 15:36:03",
          "2024-09-19 15:37:03",
          "2024-09-19 15:38:03",
          "2024-09-19 15:39:03",
          "2024-09-19 15:40:03",
          "2024-09-19 15:41:03",
          "2024-09-19 15:42:03",
          "2024-09-19 15:43:03",
          "2024-09-19 15:44:03",
          "2024-09-19 15:45:03",
          "2024-09-19 15:46:03",
          "2024-09-19 15:47:03",
          "2024-09-19 15:48:03",
          "2024-09-19 15:49:03",
          "2024-09-19 15:50:03",
          "2024-09-19 15:51:03",
          "2024-09-19 15:52:03",
          "2024-09-19 15:53:03",
          "2024-09-19 15:54:03",
          "2024-09-19 15:55:03",
          "2024-09-19 15:56:03",
          "2024-09-19 15:57:03",
          "2024-09-19 15:58:03",
          "2024-09-19 15:59:03",
          "2024-09-19 16:00:03",
          "2024-09-19 16:01:03",
          "2024-09-19 16:02:03",
          "2024-09-19 16:03:03",
          "2024-09-19 16:04:03",
          "2024-09-19 16:05:03",
          "2024-09-19 16:06:03",
          "2024-09-19 16:07:03",
          "2024-09-19 16:08:03",
          "2024-09-19 16:09:03",
          "2024-09-19 16:10:03",
          "2024-09-19 16:11:03",
          "2024-09-19 16:12:03",
          "2024-09-19 16:13:03",
          "2024-09-19 16:14:03",
          "2024-09-19 16:15:03",
          "2024-09-19 16:16:03",
          "2024-09-19 16:17:03",
          "2024-09-19 16:18:03",
          "2024-09-19 16:19:03",
          "2024-09-19 16:20:03",
          "2024-09-19 16:21:03",
          "2024-09-19 16:22:03",
          "2024-09-19 16:23:03",
          "2024-09-19 16:24:03",
          "2024-09-19 16:25:03",
          "2024-09-19 16:26:03",
          "2024-09-19 16:27:03",
          "2024-09-19 16:28:03",
          "2024-09-19 16:29:03",
          "2024-09-19 16:30:03",
          "2024-09-19 16:31:03",
          "2024-09-19 16:32:03",
          "2024-09-19 16:33:03",
          "2024-09-19 16:34:03",
          "2024-09-19 16:35:03",
          "2024-09-19 16:36:03",
          "2024-09-19 16:37:03",
          "2024-09-19 16:38:03",
          "2024-09-19 16:39:03",
          "2024-09-19 16:40:03",
          "2024-09-19 16:41:03",
          "2024-09-19 16:42:03",
          "2024-09-19 16:43:03",
          "2024-09-19 16:44:03",
          "2024-09-19 16:45:03",
          "2024-09-19 16:46:03",
          "2024-09-19 16:47:03",
          "2024-09-19 16:48:03",
          "2024-09-19 16:49:03",
          "2024-09-19 16:50:03",
          "2024-09-19 16:51:03",
          "2024-09-19 16:52:03",
          "2024-09-19 16:53:03",
          "2024-09-19 16:54:03",
          "2024-09-19 16:55:03",
          "2024-09-19 16:56:03",
          "2024-09-19 16:57:03",
          "2024-09-19 16:58:03",
          "2024-09-19 16:59:03",
          "2024-09-19 17:00:03",
          "2024-09-19 17:01:03",
          "2024-09-19 17:02:03",
          "2024-09-19 17:03:03",
          "2024-09-19 17:04:03",
          "2024-09-19 17:05:03",
          "2024-09-19 17:06:03",
          "2024-09-19 17:07:03",
          "2024-09-19 17:08:03",
          "2024-09-19 17:09:03",
          "2024-09-19 17:10:03",
          "2024-09-19 17:11:03",
          "2024-09-19 17:12:03",
          "2024-09-19 17:13:03",
          "2024-09-19 17:14:03",
          "2024-09-19 17:15:03",
          "2024-09-19 17:16:03",
          "2024-09-19 17:17:03",
          "2024-09-19 17:18:03",
          "2024-09-19 17:19:03",
          "2024-09-19 17:20:03",
          "2024-09-19 17:21:03",
          "2024-09-19 17:22:03",
          "2024-09-19 17:23:03",
          "2024-09-19 17:24:03",
          "2024-09-19 17:25:03",
          "2024-09-19 17:26:03",
          "2024-09-19 17:27:03",
          "2024-09-19 17:28:03",
          "2024-09-19 17:29:03",
          "2024-09-19 17:30:03",
          "2024-09-19 17:31:03",
          "2024-09-19 17:32:03",
          "2024-09-19 17:33:03",
          "2024-09-19 17:34:03",
          "2024-09-19 17:35:03",
          "2024-09-19 17:36:03",
          "2024-09-19 17:37:03",
          "2024-09-19 17:38:03",
          "2024-09-19 17:39:03",
          "2024-09-19 17:40:03",
          "2024-09-19 17:41:03",
          "2024-09-19 17:42:03",
          "2024-09-19 17:43:03",
          "2024-09-19 17:44:03",
          "2024-09-19 17:45:03",
          "2024-09-19 17:46:03",
          "2024-09-19 17:47:03",
          "2024-09-19 17:48:03",
          "2024-09-19 17:49:03",
          "2024-09-19 17:50:03",
          "2024-09-19 17:51:03",
          "2024-09-19 17:52:03",
          "2024-09-19 17:53:03",
          "2024-09-19 17:54:03",
          "2024-09-19 17:55:03",
          "2024-09-19 17:56:03",
          "2024-09-19 17:57:03",
          "2024-09-19 17:58:03",
          "2024-09-19 17:59:03",
          "2024-09-19 18:00:03",
          "2024-09-19 18:01:03",
          "2024-09-19 18:02:03",
          "2024-09-19 18:03:03",
          "2024-09-19 18:04:03",
          "2024-09-19 18:05:03",
          "2024-09-19 18:06:03",
          "2024-09-19 18:07:03",
          "2024-09-19 18:08:03",
          "2024-09-19 18:09:03",
          "2024-09-19 18:10:04",
          "2024-09-19 18:11:03",
          "2024-09-19 18:12:03",
          "2024-09-19 18:13:03",
          "2024-09-19 18:14:04",
          "2024-09-19 18:15:04",
          "2024-09-19 18:16:04",
          "2024-09-19 18:17:04",
          "2024-09-19 18:18:04",
          "2024-09-19 18:19:04",
          "2024-09-19 18:20:04",
          "2024-09-19 18:21:04",
          "2024-09-19 18:22:04",
          "2024-09-19 18:23:04",
          "2024-09-19 18:24:04",
          "2024-09-19 18:25:04",
          "2024-09-19 18:26:04",
          "2024-09-19 18:27:04",
          "2024-09-19 18:28:04",
          "2024-09-19 18:29:04",
          "2024-09-19 18:30:04",
          "2024-09-19 18:31:04",
          "2024-09-19 18:32:04",
          "2024-09-19 18:33:04",
          "2024-09-19 18:34:04",
          "2024-09-19 18:35:04",
          "2024-09-19 18:36:04",
          "2024-09-19 18:37:04",
          "2024-09-19 18:38:04",
          "2024-09-19 18:39:04",
          "2024-09-19 18:40:04",
          "2024-09-19 18:41:04",
          "2024-09-19 18:42:04",
          "2024-09-19 18:43:04",
          "2024-09-19 18:44:04",
          "2024-09-19 18:45:04",
          "2024-09-19 18:46:04",
          "2024-09-19 18:47:04",
          "2024-09-19 18:48:04",
          "2024-09-19 18:49:04",
          "2024-09-19 18:50:04",
          "2024-09-19 18:51:04",
          "2024-09-19 18:52:04",
          "2024-09-19 18:53:04",
          "2024-09-19 18:54:04",
          "2024-09-19 18:55:04",
          "2024-09-19 18:56:04",
          "2024-09-19 18:57:04",
          "2024-09-19 18:58:04",
          "2024-09-19 18:59:04",
          "2024-09-19 19:00:04",
          "2024-09-19 19:01:04",
          "2024-09-19 19:02:04",
          "2024-09-19 19:03:04",
          "2024-09-19 19:04:04",
          "2024-09-19 19:05:04",
          "2024-09-19 19:06:04",
          "2024-09-19 19:07:04",
          "2024-09-19 19:08:04",
          "2024-09-19 19:09:04",
          "2024-09-19 19:10:04",
          "2024-09-19 19:11:04",
          "2024-09-19 19:12:04",
          "2024-09-19 19:13:04",
          "2024-09-19 19:14:04",
          "2024-09-19 19:15:04",
          "2024-09-19 19:16:04",
          "2024-09-19 19:17:04",
          "2024-09-19 19:18:04",
          "2024-09-19 19:19:04",
          "2024-09-19 19:20:04",
          "2024-09-19 19:21:04",
          "2024-09-19 19:22:04",
          "2024-09-19 19:23:04",
          "2024-09-19 19:24:04",
          "2024-09-19 19:25:04",
          "2024-09-19 19:26:04",
          "2024-09-19 19:27:04",
          "2024-09-19 19:28:04"
        ],
        "series": [
          {
            "title": "TN",
            "tag": "ZT_TNIT1201_DIS",
            "unit": "mg/L",
            "value": [
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              24.58,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              23.66,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42,
              24.42
            ]
          }
        ]
      },
      incomingWatercoddata: {
        "xAxis": [
          "2024-09-19 13:32:02",
          "2024-09-19 13:33:02",
          "2024-09-19 13:34:02",
          "2024-09-19 13:35:02",
          "2024-09-19 13:36:02",
          "2024-09-19 13:37:02",
          "2024-09-19 13:38:02",
          "2024-09-19 13:39:02",
          "2024-09-19 13:40:02",
          "2024-09-19 13:41:02",
          "2024-09-19 13:42:02",
          "2024-09-19 13:43:02",
          "2024-09-19 13:44:02",
          "2024-09-19 13:45:02",
          "2024-09-19 13:46:02",
          "2024-09-19 13:47:02",
          "2024-09-19 13:48:02",
          "2024-09-19 13:49:02",
          "2024-09-19 13:50:02",
          "2024-09-19 13:51:02",
          "2024-09-19 13:52:02",
          "2024-09-19 13:53:02",
          "2024-09-19 13:54:02",
          "2024-09-19 13:55:02",
          "2024-09-19 13:56:02",
          "2024-09-19 13:57:02",
          "2024-09-19 13:58:02",
          "2024-09-19 13:59:02",
          "2024-09-19 14:00:02",
          "2024-09-19 14:01:02",
          "2024-09-19 14:02:02",
          "2024-09-19 14:03:02",
          "2024-09-19 14:04:02",
          "2024-09-19 14:05:02",
          "2024-09-19 14:06:02",
          "2024-09-19 14:07:02",
          "2024-09-19 14:08:02",
          "2024-09-19 14:09:02",
          "2024-09-19 14:10:02",
          "2024-09-19 14:11:02",
          "2024-09-19 14:12:02",
          "2024-09-19 14:13:02",
          "2024-09-19 14:14:02",
          "2024-09-19 14:15:02",
          "2024-09-19 14:16:02",
          "2024-09-19 14:17:02",
          "2024-09-19 14:18:02",
          "2024-09-19 14:19:02",
          "2024-09-19 14:20:02",
          "2024-09-19 14:21:02",
          "2024-09-19 14:22:02",
          "2024-09-19 14:23:02",
          "2024-09-19 14:24:02",
          "2024-09-19 14:25:02",
          "2024-09-19 14:26:02",
          "2024-09-19 14:27:02",
          "2024-09-19 14:28:02",
          "2024-09-19 14:29:02",
          "2024-09-19 14:30:02",
          "2024-09-19 14:31:02",
          "2024-09-19 14:32:02",
          "2024-09-19 14:33:02",
          "2024-09-19 14:34:02",
          "2024-09-19 14:35:02",
          "2024-09-19 14:36:02",
          "2024-09-19 14:37:02",
          "2024-09-19 14:38:02",
          "2024-09-19 14:39:02",
          "2024-09-19 14:40:02",
          "2024-09-19 14:41:02",
          "2024-09-19 14:42:02",
          "2024-09-19 14:43:02",
          "2024-09-19 14:44:02",
          "2024-09-19 14:45:02",
          "2024-09-19 14:46:02",
          "2024-09-19 14:47:02",
          "2024-09-19 14:48:02",
          "2024-09-19 14:49:02",
          "2024-09-19 14:50:02",
          "2024-09-19 14:51:02",
          "2024-09-19 14:52:02",
          "2024-09-19 14:53:02",
          "2024-09-19 14:54:02",
          "2024-09-19 14:55:02",
          "2024-09-19 14:56:02",
          "2024-09-19 14:57:02",
          "2024-09-19 14:58:02",
          "2024-09-19 14:59:02",
          "2024-09-19 15:00:02",
          "2024-09-19 15:01:02",
          "2024-09-19 15:02:02",
          "2024-09-19 15:03:02",
          "2024-09-19 15:04:02",
          "2024-09-19 15:05:02",
          "2024-09-19 15:06:02",
          "2024-09-19 15:07:03",
          "2024-09-19 15:08:02",
          "2024-09-19 15:09:03",
          "2024-09-19 15:10:02",
          "2024-09-19 15:11:03",
          "2024-09-19 15:12:02",
          "2024-09-19 15:13:02",
          "2024-09-19 15:14:02",
          "2024-09-19 15:15:02",
          "2024-09-19 15:16:03",
          "2024-09-19 15:17:03",
          "2024-09-19 15:18:02",
          "2024-09-19 15:19:03",
          "2024-09-19 15:20:03",
          "2024-09-19 15:21:03",
          "2024-09-19 15:22:03",
          "2024-09-19 15:23:03",
          "2024-09-19 15:24:03",
          "2024-09-19 15:25:03",
          "2024-09-19 15:26:03",
          "2024-09-19 15:27:03",
          "2024-09-19 15:28:03",
          "2024-09-19 15:29:03",
          "2024-09-19 15:30:03",
          "2024-09-19 15:31:03",
          "2024-09-19 15:32:03",
          "2024-09-19 15:33:03",
          "2024-09-19 15:34:03",
          "2024-09-19 15:35:03",
          "2024-09-19 15:36:03",
          "2024-09-19 15:37:03",
          "2024-09-19 15:38:03",
          "2024-09-19 15:39:03",
          "2024-09-19 15:40:03",
          "2024-09-19 15:41:03",
          "2024-09-19 15:42:03",
          "2024-09-19 15:43:03",
          "2024-09-19 15:44:03",
          "2024-09-19 15:45:03",
          "2024-09-19 15:46:03",
          "2024-09-19 15:47:03",
          "2024-09-19 15:48:03",
          "2024-09-19 15:49:03",
          "2024-09-19 15:50:03",
          "2024-09-19 15:51:03",
          "2024-09-19 15:52:03",
          "2024-09-19 15:53:03",
          "2024-09-19 15:54:03",
          "2024-09-19 15:55:03",
          "2024-09-19 15:56:03",
          "2024-09-19 15:57:03",
          "2024-09-19 15:58:03",
          "2024-09-19 15:59:03",
          "2024-09-19 16:00:03",
          "2024-09-19 16:01:03",
          "2024-09-19 16:02:03",
          "2024-09-19 16:03:03",
          "2024-09-19 16:04:03",
          "2024-09-19 16:05:03",
          "2024-09-19 16:06:03",
          "2024-09-19 16:07:03",
          "2024-09-19 16:08:03",
          "2024-09-19 16:09:03",
          "2024-09-19 16:10:03",
          "2024-09-19 16:11:03",
          "2024-09-19 16:12:03",
          "2024-09-19 16:13:03",
          "2024-09-19 16:14:03",
          "2024-09-19 16:15:03",
          "2024-09-19 16:16:03",
          "2024-09-19 16:17:03",
          "2024-09-19 16:18:03",
          "2024-09-19 16:19:03",
          "2024-09-19 16:20:03",
          "2024-09-19 16:21:03",
          "2024-09-19 16:22:03",
          "2024-09-19 16:23:03",
          "2024-09-19 16:24:03",
          "2024-09-19 16:25:03",
          "2024-09-19 16:26:03",
          "2024-09-19 16:27:03",
          "2024-09-19 16:28:03",
          "2024-09-19 16:29:03",
          "2024-09-19 16:30:03",
          "2024-09-19 16:31:03",
          "2024-09-19 16:32:03",
          "2024-09-19 16:33:03",
          "2024-09-19 16:34:03",
          "2024-09-19 16:35:03",
          "2024-09-19 16:36:03",
          "2024-09-19 16:37:03",
          "2024-09-19 16:38:03",
          "2024-09-19 16:39:03",
          "2024-09-19 16:40:03",
          "2024-09-19 16:41:03",
          "2024-09-19 16:42:03",
          "2024-09-19 16:43:03",
          "2024-09-19 16:44:03",
          "2024-09-19 16:45:03",
          "2024-09-19 16:46:03",
          "2024-09-19 16:47:03",
          "2024-09-19 16:48:03",
          "2024-09-19 16:49:03",
          "2024-09-19 16:50:03",
          "2024-09-19 16:51:03",
          "2024-09-19 16:52:03",
          "2024-09-19 16:53:03",
          "2024-09-19 16:54:03",
          "2024-09-19 16:55:03",
          "2024-09-19 16:56:03",
          "2024-09-19 16:57:03",
          "2024-09-19 16:58:03",
          "2024-09-19 16:59:03",
          "2024-09-19 17:00:03",
          "2024-09-19 17:01:03",
          "2024-09-19 17:02:03",
          "2024-09-19 17:03:03",
          "2024-09-19 17:04:03",
          "2024-09-19 17:05:03",
          "2024-09-19 17:06:03",
          "2024-09-19 17:07:03",
          "2024-09-19 17:08:03",
          "2024-09-19 17:09:03",
          "2024-09-19 17:10:03",
          "2024-09-19 17:11:03",
          "2024-09-19 17:12:03",
          "2024-09-19 17:13:03",
          "2024-09-19 17:14:03",
          "2024-09-19 17:15:03",
          "2024-09-19 17:16:03",
          "2024-09-19 17:17:03",
          "2024-09-19 17:18:03",
          "2024-09-19 17:19:03",
          "2024-09-19 17:20:03",
          "2024-09-19 17:21:03",
          "2024-09-19 17:22:03",
          "2024-09-19 17:23:03",
          "2024-09-19 17:24:03",
          "2024-09-19 17:25:03",
          "2024-09-19 17:26:03",
          "2024-09-19 17:27:03",
          "2024-09-19 17:28:03",
          "2024-09-19 17:29:03",
          "2024-09-19 17:30:03",
          "2024-09-19 17:31:03",
          "2024-09-19 17:32:03",
          "2024-09-19 17:33:03",
          "2024-09-19 17:34:03",
          "2024-09-19 17:35:03",
          "2024-09-19 17:36:03",
          "2024-09-19 17:37:03",
          "2024-09-19 17:38:03",
          "2024-09-19 17:39:03",
          "2024-09-19 17:40:03",
          "2024-09-19 17:41:03",
          "2024-09-19 17:42:03",
          "2024-09-19 17:43:03",
          "2024-09-19 17:44:03",
          "2024-09-19 17:45:03",
          "2024-09-19 17:46:03",
          "2024-09-19 17:47:03",
          "2024-09-19 17:48:03",
          "2024-09-19 17:49:03",
          "2024-09-19 17:50:03",
          "2024-09-19 17:51:03",
          "2024-09-19 17:52:03",
          "2024-09-19 17:53:03",
          "2024-09-19 17:54:03",
          "2024-09-19 17:55:03",
          "2024-09-19 17:56:03",
          "2024-09-19 17:57:03",
          "2024-09-19 17:58:03",
          "2024-09-19 17:59:03",
          "2024-09-19 18:00:03",
          "2024-09-19 18:01:03",
          "2024-09-19 18:02:03",
          "2024-09-19 18:03:03",
          "2024-09-19 18:04:03",
          "2024-09-19 18:05:03",
          "2024-09-19 18:06:03",
          "2024-09-19 18:07:03",
          "2024-09-19 18:08:03",
          "2024-09-19 18:09:03",
          "2024-09-19 18:10:04",
          "2024-09-19 18:11:03",
          "2024-09-19 18:12:03",
          "2024-09-19 18:13:03",
          "2024-09-19 18:14:04",
          "2024-09-19 18:15:04",
          "2024-09-19 18:16:04",
          "2024-09-19 18:17:04",
          "2024-09-19 18:18:04",
          "2024-09-19 18:19:04",
          "2024-09-19 18:20:04",
          "2024-09-19 18:21:04",
          "2024-09-19 18:22:04",
          "2024-09-19 18:23:04",
          "2024-09-19 18:24:04",
          "2024-09-19 18:25:04",
          "2024-09-19 18:26:04",
          "2024-09-19 18:27:04",
          "2024-09-19 18:28:04",
          "2024-09-19 18:29:04",
          "2024-09-19 18:30:04",
          "2024-09-19 18:31:04",
          "2024-09-19 18:32:04",
          "2024-09-19 18:33:04",
          "2024-09-19 18:34:04",
          "2024-09-19 18:35:04",
          "2024-09-19 18:36:04",
          "2024-09-19 18:37:04",
          "2024-09-19 18:38:04",
          "2024-09-19 18:39:04",
          "2024-09-19 18:40:04",
          "2024-09-19 18:41:04",
          "2024-09-19 18:42:04",
          "2024-09-19 18:43:04",
          "2024-09-19 18:44:04",
          "2024-09-19 18:45:04",
          "2024-09-19 18:46:04",
          "2024-09-19 18:47:04",
          "2024-09-19 18:48:04",
          "2024-09-19 18:49:04",
          "2024-09-19 18:50:04",
          "2024-09-19 18:51:04",
          "2024-09-19 18:52:04",
          "2024-09-19 18:53:04",
          "2024-09-19 18:54:04",
          "2024-09-19 18:55:04",
          "2024-09-19 18:56:04",
          "2024-09-19 18:57:04",
          "2024-09-19 18:58:04",
          "2024-09-19 18:59:04",
          "2024-09-19 19:00:04",
          "2024-09-19 19:01:04",
          "2024-09-19 19:02:04",
          "2024-09-19 19:03:04",
          "2024-09-19 19:04:04",
          "2024-09-19 19:05:04",
          "2024-09-19 19:06:04",
          "2024-09-19 19:07:04",
          "2024-09-19 19:08:04",
          "2024-09-19 19:09:04",
          "2024-09-19 19:10:04",
          "2024-09-19 19:11:04",
          "2024-09-19 19:12:04",
          "2024-09-19 19:13:04",
          "2024-09-19 19:14:04",
          "2024-09-19 19:15:04",
          "2024-09-19 19:16:04",
          "2024-09-19 19:17:04",
          "2024-09-19 19:18:04",
          "2024-09-19 19:19:04",
          "2024-09-19 19:20:04",
          "2024-09-19 19:21:04",
          "2024-09-19 19:22:04",
          "2024-09-19 19:23:04",
          "2024-09-19 19:24:04",
          "2024-09-19 19:25:04",
          "2024-09-19 19:26:04",
          "2024-09-19 19:27:04",
          "2024-09-19 19:28:04",
          "2024-09-19 19:29:04",
          "2024-09-19 19:30:04",
          "2024-09-19 19:31:04",
          "2024-09-19 19:32:04",
          "2024-09-19 19:33:04",
          "2024-09-19 19:34:04",
          "2024-09-19 19:35:04",
          "2024-09-19 19:36:04",
          "2024-09-19 19:37:04",
          "2024-09-19 19:38:04",
          "2024-09-19 19:39:04",
          "2024-09-19 19:40:04",
          "2024-09-19 19:41:04",
          "2024-09-19 19:42:04",
          "2024-09-19 19:43:04",
          "2024-09-19 19:44:04",
          "2024-09-19 19:45:04",
          "2024-09-19 19:46:04",
          "2024-09-19 19:47:04",
          "2024-09-19 19:48:04",
          "2024-09-19 19:49:04",
          "2024-09-19 19:50:04",
          "2024-09-19 19:51:04",
          "2024-09-19 19:52:04",
          "2024-09-19 19:53:04",
          "2024-09-19 19:54:04",
          "2024-09-19 19:55:04",
          "2024-09-19 19:56:04",
          "2024-09-19 19:57:04",
          "2024-09-19 19:58:04",
          "2024-09-19 19:59:04",
          "2024-09-19 20:00:04",
          "2024-09-19 20:01:04",
          "2024-09-19 20:02:04",
          "2024-09-19 20:03:04",
          "2024-09-19 20:04:04",
          "2024-09-19 20:05:04",
          "2024-09-19 20:06:04",
          "2024-09-19 20:07:04",
          "2024-09-19 20:08:04",
          "2024-09-19 20:09:04",
          "2024-09-19 20:10:04",
          "2024-09-19 20:11:04",
          "2024-09-19 20:12:04",
          "2024-09-19 20:13:04",
          "2024-09-19 20:14:04",
          "2024-09-19 20:15:04",
          "2024-09-19 20:16:04",
          "2024-09-19 20:17:04",
          "2024-09-19 20:18:04",
          "2024-09-19 20:19:04",
          "2024-09-19 20:20:04",
          "2024-09-19 20:21:04",
          "2024-09-19 20:22:04",
          "2024-09-19 20:23:04",
          "2024-09-19 20:24:04",
          "2024-09-19 20:25:04",
          "2024-09-19 20:26:04",
          "2024-09-19 20:27:04",
          "2024-09-19 20:28:04",
          "2024-09-19 20:29:04",
          "2024-09-19 20:30:04",
          "2024-09-19 20:31:04"
        ],
        "series": [
          {
            "title": "COD",
            "tag": "CT_CODIT501_DIS",
            "unit": "mg/L",
            "limit": 24,
            "value": [
              12.8,
              12.8,
              12.8,
              12.8,
              12.8,
              12.8,
              12.8,
              12.8,
              12.8,
              12.8,
              12.8,
              12.8,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              14.46,
              14.46,
              14.46,
              14.46,
              14.46,
              14.46,
              14.46,
              14.46,
              14.46,
              14.46,
              14.46,
              14.46,
              14.46,
              14.46,
              14.46,
              14.46,
              14.46,
              14.46,
              14.46,
              14.46,
              14.46,
              14.46,
              14.46,
              14.46,
              14.46,
              14.46,
              14.46,
              14.46,
              14.46,
              14.46,
              14.46,
              14.46,
              14.46,
              14.46,
              14.46,
              14.46,
              14.46,
              14.46,
              14.46,
              14.46,
              14.46,
              14.46,
              14.46,
              14.46,
              14.46,
              14.46,
              14.46,
              14.46,
              14.46,
              14.46,
              14.46,
              14.46,
              14.46,
              14.46,
              14.46,
              14.46,
              14.46,
              14.46,
              14.46,
              14.46
            ]
          }]
      },
      postLinktndata: {
        "xAxis": [
          "2024-09-19 14:34:02",
          "2024-09-19 14:35:02",
          "2024-09-19 14:36:02",
          "2024-09-19 14:37:02",
          "2024-09-19 14:38:02",
          "2024-09-19 14:39:02",
          "2024-09-19 14:40:02",
          "2024-09-19 14:41:02",
          "2024-09-19 14:42:02",
          "2024-09-19 14:43:02",
          "2024-09-19 14:44:02",
          "2024-09-19 14:45:02",
          "2024-09-19 14:46:02",
          "2024-09-19 14:47:02",
          "2024-09-19 14:48:02",
          "2024-09-19 14:49:02",
          "2024-09-19 14:50:02",
          "2024-09-19 14:51:02",
          "2024-09-19 14:52:02",
          "2024-09-19 14:53:02",
          "2024-09-19 14:54:02",
          "2024-09-19 14:55:02",
          "2024-09-19 14:56:02",
          "2024-09-19 14:57:02",
          "2024-09-19 14:58:02",
          "2024-09-19 14:59:02",
          "2024-09-19 15:00:02",
          "2024-09-19 15:01:02",
          "2024-09-19 15:02:02",
          "2024-09-19 15:03:02",
          "2024-09-19 15:04:02",
          "2024-09-19 15:05:02",
          "2024-09-19 15:06:02",
          "2024-09-19 15:07:03",
          "2024-09-19 15:08:02",
          "2024-09-19 15:09:03",
          "2024-09-19 15:10:02",
          "2024-09-19 15:11:03",
          "2024-09-19 15:12:02",
          "2024-09-19 15:13:02",
          "2024-09-19 15:14:02",
          "2024-09-19 15:15:02",
          "2024-09-19 15:16:03",
          "2024-09-19 15:17:03",
          "2024-09-19 15:18:02",
          "2024-09-19 15:19:03",
          "2024-09-19 15:20:03",
          "2024-09-19 15:21:03",
          "2024-09-19 15:22:03",
          "2024-09-19 15:23:03",
          "2024-09-19 15:24:03",
          "2024-09-19 15:25:03",
          "2024-09-19 15:26:03",
          "2024-09-19 15:27:03",
          "2024-09-19 15:28:03",
          "2024-09-19 15:29:03",
          "2024-09-19 15:30:03",
          "2024-09-19 15:31:03",
          "2024-09-19 15:32:03",
          "2024-09-19 15:33:03",
          "2024-09-19 15:34:03",
          "2024-09-19 15:35:03",
          "2024-09-19 15:36:03",
          "2024-09-19 15:37:03",
          "2024-09-19 15:38:03",
          "2024-09-19 15:39:03",
          "2024-09-19 15:40:03",
          "2024-09-19 15:41:03",
          "2024-09-19 15:42:03",
          "2024-09-19 15:43:03",
          "2024-09-19 15:44:03",
          "2024-09-19 15:45:03",
          "2024-09-19 15:46:03",
          "2024-09-19 15:47:03",
          "2024-09-19 15:48:03",
          "2024-09-19 15:49:03",
          "2024-09-19 15:50:03",
          "2024-09-19 15:51:03",
          "2024-09-19 15:52:03",
          "2024-09-19 15:53:03",
          "2024-09-19 15:54:03",
          "2024-09-19 15:55:03",
          "2024-09-19 15:56:03",
          "2024-09-19 15:57:03",
          "2024-09-19 15:58:03",
          "2024-09-19 15:59:03",
          "2024-09-19 16:00:03",
          "2024-09-19 16:01:03",
          "2024-09-19 16:02:03",
          "2024-09-19 16:03:03",
          "2024-09-19 16:04:03",
          "2024-09-19 16:05:03",
          "2024-09-19 16:06:03",
          "2024-09-19 16:07:03",
          "2024-09-19 16:08:03",
          "2024-09-19 16:09:03",
          "2024-09-19 16:10:03",
          "2024-09-19 16:11:03",
          "2024-09-19 16:12:03",
          "2024-09-19 16:13:03",
          "2024-09-19 16:14:03",
          "2024-09-19 16:15:03",
          "2024-09-19 16:16:03",
          "2024-09-19 16:17:03",
          "2024-09-19 16:18:03",
          "2024-09-19 16:19:03",
          "2024-09-19 16:20:03",
          "2024-09-19 16:21:03",
          "2024-09-19 16:22:03",
          "2024-09-19 16:23:03",
          "2024-09-19 16:24:03",
          "2024-09-19 16:25:03",
          "2024-09-19 16:26:03",
          "2024-09-19 16:27:03",
          "2024-09-19 16:28:03",
          "2024-09-19 16:29:03",
          "2024-09-19 16:30:03",
          "2024-09-19 16:31:03",
          "2024-09-19 16:32:03",
          "2024-09-19 16:33:03",
          "2024-09-19 16:34:03",
          "2024-09-19 16:35:03",
          "2024-09-19 16:36:03",
          "2024-09-19 16:37:03",
          "2024-09-19 16:38:03",
          "2024-09-19 16:39:03",
          "2024-09-19 16:40:03",
          "2024-09-19 16:41:03",
          "2024-09-19 16:42:03",
          "2024-09-19 16:43:03",
          "2024-09-19 16:44:03",
          "2024-09-19 16:45:03",
          "2024-09-19 16:46:03",
          "2024-09-19 16:47:03",
          "2024-09-19 16:48:03",
          "2024-09-19 16:49:03",
          "2024-09-19 16:50:03",
          "2024-09-19 16:51:03",
          "2024-09-19 16:52:03",
          "2024-09-19 16:53:03",
          "2024-09-19 16:54:03",
          "2024-09-19 16:55:03",
          "2024-09-19 16:56:03",
          "2024-09-19 16:57:03",
          "2024-09-19 16:58:03",
          "2024-09-19 16:59:03",
          "2024-09-19 17:00:03",
          "2024-09-19 17:01:03",
          "2024-09-19 17:02:03",
          "2024-09-19 17:03:03",
          "2024-09-19 17:04:03",
          "2024-09-19 17:05:03",
          "2024-09-19 17:06:03",
          "2024-09-19 17:07:03",
          "2024-09-19 17:08:03",
          "2024-09-19 17:09:03",
          "2024-09-19 17:10:03",
          "2024-09-19 17:11:03",
          "2024-09-19 17:12:03",
          "2024-09-19 17:13:03",
          "2024-09-19 17:14:03",
          "2024-09-19 17:15:03",
          "2024-09-19 17:16:03",
          "2024-09-19 17:17:03",
          "2024-09-19 17:18:03",
          "2024-09-19 17:19:03",
          "2024-09-19 17:20:03",
          "2024-09-19 17:21:03",
          "2024-09-19 17:22:03",
          "2024-09-19 17:23:03",
          "2024-09-19 17:24:03",
          "2024-09-19 17:25:03",
          "2024-09-19 17:26:03",
          "2024-09-19 17:27:03",
          "2024-09-19 17:28:03",
          "2024-09-19 17:29:03",
          "2024-09-19 17:30:03",
          "2024-09-19 17:31:03",
          "2024-09-19 17:32:03",
          "2024-09-19 17:33:03",
          "2024-09-19 17:34:03",
          "2024-09-19 17:35:03",
          "2024-09-19 17:36:03",
          "2024-09-19 17:37:03",
          "2024-09-19 17:38:03",
          "2024-09-19 17:39:03",
          "2024-09-19 17:40:03",
          "2024-09-19 17:41:03",
          "2024-09-19 17:42:03",
          "2024-09-19 17:43:03",
          "2024-09-19 17:44:03",
          "2024-09-19 17:45:03",
          "2024-09-19 17:46:03",
          "2024-09-19 17:47:03",
          "2024-09-19 17:48:03",
          "2024-09-19 17:49:03",
          "2024-09-19 17:50:03",
          "2024-09-19 17:51:03",
          "2024-09-19 17:52:03",
          "2024-09-19 17:53:03",
          "2024-09-19 17:54:03",
          "2024-09-19 17:55:03",
          "2024-09-19 17:56:03",
          "2024-09-19 17:57:03",
          "2024-09-19 17:58:03",
          "2024-09-19 17:59:03",
          "2024-09-19 18:00:03",
          "2024-09-19 18:01:03",
          "2024-09-19 18:02:03",
          "2024-09-19 18:03:03",
          "2024-09-19 18:04:03",
          "2024-09-19 18:05:03",
          "2024-09-19 18:06:03",
          "2024-09-19 18:07:03",
          "2024-09-19 18:08:03",
          "2024-09-19 18:09:03",
          "2024-09-19 18:10:04",
          "2024-09-19 18:11:03",
          "2024-09-19 18:12:03",
          "2024-09-19 18:13:03",
          "2024-09-19 18:14:04",
          "2024-09-19 18:15:04",
          "2024-09-19 18:16:04",
          "2024-09-19 18:17:04",
          "2024-09-19 18:18:04",
          "2024-09-19 18:19:04",
          "2024-09-19 18:20:04",
          "2024-09-19 18:21:04",
          "2024-09-19 18:22:04",
          "2024-09-19 18:23:04",
          "2024-09-19 18:24:04",
          "2024-09-19 18:25:04",
          "2024-09-19 18:26:04",
          "2024-09-19 18:27:04",
          "2024-09-19 18:28:04",
          "2024-09-19 18:29:04",
          "2024-09-19 18:30:04",
          "2024-09-19 18:31:04",
          "2024-09-19 18:32:04",
          "2024-09-19 18:33:04",
          "2024-09-19 18:34:04",
          "2024-09-19 18:35:04",
          "2024-09-19 18:36:04",
          "2024-09-19 18:37:04",
          "2024-09-19 18:38:04",
          "2024-09-19 18:39:04",
          "2024-09-19 18:40:04",
          "2024-09-19 18:41:04",
          "2024-09-19 18:42:04",
          "2024-09-19 18:43:04",
          "2024-09-19 18:44:04",
          "2024-09-19 18:45:04",
          "2024-09-19 18:46:04",
          "2024-09-19 18:47:04",
          "2024-09-19 18:48:04",
          "2024-09-19 18:49:04",
          "2024-09-19 18:50:04",
          "2024-09-19 18:51:04",
          "2024-09-19 18:52:04",
          "2024-09-19 18:53:04",
          "2024-09-19 18:54:04",
          "2024-09-19 18:55:04",
          "2024-09-19 18:56:04",
          "2024-09-19 18:57:04",
          "2024-09-19 18:58:04",
          "2024-09-19 18:59:04",
          "2024-09-19 19:00:04",
          "2024-09-19 19:01:04",
          "2024-09-19 19:02:04",
          "2024-09-19 19:03:04",
          "2024-09-19 19:04:04",
          "2024-09-19 19:05:04",
          "2024-09-19 19:06:04",
          "2024-09-19 19:07:04",
          "2024-09-19 19:08:04",
          "2024-09-19 19:09:04",
          "2024-09-19 19:10:04",
          "2024-09-19 19:11:04",
          "2024-09-19 19:12:04",
          "2024-09-19 19:13:04",
          "2024-09-19 19:14:04",
          "2024-09-19 19:15:04",
          "2024-09-19 19:16:04",
          "2024-09-19 19:17:04",
          "2024-09-19 19:18:04",
          "2024-09-19 19:19:04",
          "2024-09-19 19:20:04",
          "2024-09-19 19:21:04",
          "2024-09-19 19:22:04",
          "2024-09-19 19:23:04",
          "2024-09-19 19:24:04",
          "2024-09-19 19:25:04",
          "2024-09-19 19:26:04",
          "2024-09-19 19:27:04",
          "2024-09-19 19:28:04",
          "2024-09-19 19:29:04",
          "2024-09-19 19:30:04",
          "2024-09-19 19:31:04",
          "2024-09-19 19:32:04",
          "2024-09-19 19:33:04",
          "2024-09-19 19:34:04",
          "2024-09-19 19:35:04",
          "2024-09-19 19:36:04",
          "2024-09-19 19:37:04",
          "2024-09-19 19:38:04",
          "2024-09-19 19:39:04",
          "2024-09-19 19:40:04",
          "2024-09-19 19:41:04",
          "2024-09-19 19:42:04",
          "2024-09-19 19:43:04",
          "2024-09-19 19:44:04",
          "2024-09-19 19:45:04",
          "2024-09-19 19:46:04",
          "2024-09-19 19:47:04",
          "2024-09-19 19:48:04",
          "2024-09-19 19:49:04",
          "2024-09-19 19:50:04",
          "2024-09-19 19:51:04",
          "2024-09-19 19:52:04",
          "2024-09-19 19:53:04",
          "2024-09-19 19:54:04",
          "2024-09-19 19:55:04",
          "2024-09-19 19:56:04",
          "2024-09-19 19:57:04",
          "2024-09-19 19:58:04",
          "2024-09-19 19:59:04",
          "2024-09-19 20:00:04",
          "2024-09-19 20:01:04",
          "2024-09-19 20:02:04",
          "2024-09-19 20:03:04",
          "2024-09-19 20:04:04",
          "2024-09-19 20:05:04",
          "2024-09-19 20:06:04",
          "2024-09-19 20:07:04",
          "2024-09-19 20:08:04",
          "2024-09-19 20:09:04",
          "2024-09-19 20:10:04",
          "2024-09-19 20:11:04",
          "2024-09-19 20:12:04",
          "2024-09-19 20:13:04",
          "2024-09-19 20:14:04",
          "2024-09-19 20:15:04",
          "2024-09-19 20:16:04",
          "2024-09-19 20:17:04",
          "2024-09-19 20:18:04",
          "2024-09-19 20:19:04",
          "2024-09-19 20:20:04",
          "2024-09-19 20:21:04",
          "2024-09-19 20:22:04",
          "2024-09-19 20:23:04",
          "2024-09-19 20:24:04",
          "2024-09-19 20:25:04",
          "2024-09-19 20:26:04",
          "2024-09-19 20:27:04",
          "2024-09-19 20:28:04",
          "2024-09-19 20:29:04",
          "2024-09-19 20:30:04",
          "2024-09-19 20:31:04",
          "2024-09-19 20:32:04",
          "2024-09-19 20:33:04",
          "2024-09-19 20:34:04",
          "2024-09-19 20:35:04",
          "2024-09-19 20:36:04",
          "2024-09-19 20:37:04",
          "2024-09-19 20:38:04",
          "2024-09-19 20:39:04",
          "2024-09-19 20:40:04",
          "2024-09-19 20:41:04",
          "2024-09-19 20:42:04",
          "2024-09-19 20:43:04",
          "2024-09-19 20:44:04",
          "2024-09-19 20:45:04",
          "2024-09-19 20:46:04",
          "2024-09-19 20:47:04",
          "2024-09-19 20:48:04",
          "2024-09-19 20:49:04",
          "2024-09-19 20:50:04",
          "2024-09-19 20:51:04",
          "2024-09-19 20:52:04",
          "2024-09-19 20:53:04",
          "2024-09-19 20:54:04",
          "2024-09-19 20:55:04",
          "2024-09-19 20:56:04",
          "2024-09-19 20:57:04",
          "2024-09-19 20:58:04",
          "2024-09-19 20:59:04",
          "2024-09-19 21:00:04",
          "2024-09-19 21:01:04",
          "2024-09-19 21:02:04",
          "2024-09-19 21:03:04",
          "2024-09-19 21:04:04",
          "2024-09-19 21:05:04",
          "2024-09-19 21:06:04",
          "2024-09-19 21:07:04",
          "2024-09-19 21:08:04",
          "2024-09-19 21:09:04",
          "2024-09-19 21:10:04",
          "2024-09-19 21:11:04",
          "2024-09-19 21:12:04",
          "2024-09-19 21:13:04",
          "2024-09-19 21:14:04",
          "2024-09-19 21:15:04",
          "2024-09-19 21:16:04",
          "2024-09-19 21:17:04",
          "2024-09-19 21:18:04",
          "2024-09-19 21:19:04",
          "2024-09-19 21:20:04",
          "2024-09-19 21:21:04",
          "2024-09-19 21:22:04",
          "2024-09-19 21:23:04",
          "2024-09-19 21:24:04",
          "2024-09-19 21:25:04",
          "2024-09-19 21:26:04",
          "2024-09-19 21:27:04",
          "2024-09-19 21:28:04",
          "2024-09-19 21:29:04",
          "2024-09-19 21:30:04",
          "2024-09-19 21:31:04",
          "2024-09-19 21:32:04",
          "2024-09-19 21:33:04"
        ],
        "series": [
          {
            "title": "TN",
            "tag": "CT_TNIT501_DIS",
            "unit": "mg/L",
            "limit": 10,
            "value": [
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.58,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.62,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              5.68,
              7.06,
              7.06,
              7.06,
              7.06,
              7.06,
              7.06,
              7.06,
              7.06,
              7.06,
              7.06,
              7.06,
              7.06,
              7.06,
              7.06,
              7.06,
              7.06,
              7.06,
              7.06,
              7.06,
              7.06,
              7.06,
              7.06,
              7.06,
              7.06,
              7.06,
              7.06,
              7.06,
              7.06,
              7.06,
              7.06,
              7.06,
              7.06,
              7.06,
              7.06,
              7.06,
              7.06,
              7.06,
              7.06,
              7.06,
              7.06,
              7.06,
              7.06,
              7.06,
              7.06,
              7.06,
              7.06,
              7.06,
              7.06,
              7.06,
              7.06,
              7.06,
              7.06,
              7.06,
              7.06,
              7.06,
              7.06,
              7.06,
              7.06,
              7.06,
              7.06
            ]
          }
        ],
        "parting": "2024-09-19 20:33:04"
      },
      postLinkcoddata: {
        "xAxis": [
          "2024-09-19 14:35:02",
          "2024-09-19 14:36:02",
          "2024-09-19 14:37:02",
          "2024-09-19 14:38:02",
          "2024-09-19 14:39:02",
          "2024-09-19 14:40:02",
          "2024-09-19 14:41:02",
          "2024-09-19 14:42:02",
          "2024-09-19 14:43:02",
          "2024-09-19 14:44:02",
          "2024-09-19 14:45:02",
          "2024-09-19 14:46:02",
          "2024-09-19 14:47:02",
          "2024-09-19 14:48:02",
          "2024-09-19 14:49:02",
          "2024-09-19 14:50:02",
          "2024-09-19 14:51:02",
          "2024-09-19 14:52:02",
          "2024-09-19 14:53:02",
          "2024-09-19 14:54:02",
          "2024-09-19 14:55:02",
          "2024-09-19 14:56:02",
          "2024-09-19 14:57:02",
          "2024-09-19 14:58:02",
          "2024-09-19 14:59:02",
          "2024-09-19 15:00:02",
          "2024-09-19 15:01:02",
          "2024-09-19 15:02:02",
          "2024-09-19 15:03:02",
          "2024-09-19 15:04:02",
          "2024-09-19 15:05:02",
          "2024-09-19 15:06:02",
          "2024-09-19 15:07:03",
          "2024-09-19 15:08:02",
          "2024-09-19 15:09:03",
          "2024-09-19 15:10:02",
          "2024-09-19 15:11:03",
          "2024-09-19 15:12:02",
          "2024-09-19 15:13:02",
          "2024-09-19 15:14:02",
          "2024-09-19 15:15:02",
          "2024-09-19 15:16:03",
          "2024-09-19 15:17:03",
          "2024-09-19 15:18:02",
          "2024-09-19 15:19:03",
          "2024-09-19 15:20:03",
          "2024-09-19 15:21:03",
          "2024-09-19 15:22:03",
          "2024-09-19 15:23:03",
          "2024-09-19 15:24:03",
          "2024-09-19 15:25:03",
          "2024-09-19 15:26:03",
          "2024-09-19 15:27:03",
          "2024-09-19 15:28:03",
          "2024-09-19 15:29:03",
          "2024-09-19 15:30:03",
          "2024-09-19 15:31:03",
          "2024-09-19 15:32:03",
          "2024-09-19 15:33:03",
          "2024-09-19 15:34:03",
          "2024-09-19 15:35:03",
          "2024-09-19 15:36:03",
          "2024-09-19 15:37:03",
          "2024-09-19 15:38:03",
          "2024-09-19 15:39:03",
          "2024-09-19 15:40:03",
          "2024-09-19 15:41:03",
          "2024-09-19 15:42:03",
          "2024-09-19 15:43:03",
          "2024-09-19 15:44:03",
          "2024-09-19 15:45:03",
          "2024-09-19 15:46:03",
          "2024-09-19 15:47:03",
          "2024-09-19 15:48:03",
          "2024-09-19 15:49:03",
          "2024-09-19 15:50:03",
          "2024-09-19 15:51:03",
          "2024-09-19 15:52:03",
          "2024-09-19 15:53:03",
          "2024-09-19 15:54:03",
          "2024-09-19 15:55:03",
          "2024-09-19 15:56:03",
          "2024-09-19 15:57:03",
          "2024-09-19 15:58:03",
          "2024-09-19 15:59:03",
          "2024-09-19 16:00:03",
          "2024-09-19 16:01:03",
          "2024-09-19 16:02:03",
          "2024-09-19 16:03:03",
          "2024-09-19 16:04:03",
          "2024-09-19 16:05:03",
          "2024-09-19 16:06:03",
          "2024-09-19 16:07:03",
          "2024-09-19 16:08:03",
          "2024-09-19 16:09:03",
          "2024-09-19 16:10:03",
          "2024-09-19 16:11:03",
          "2024-09-19 16:12:03",
          "2024-09-19 16:13:03",
          "2024-09-19 16:14:03",
          "2024-09-19 16:15:03",
          "2024-09-19 16:16:03",
          "2024-09-19 16:17:03",
          "2024-09-19 16:18:03",
          "2024-09-19 16:19:03",
          "2024-09-19 16:20:03",
          "2024-09-19 16:21:03",
          "2024-09-19 16:22:03",
          "2024-09-19 16:23:03",
          "2024-09-19 16:24:03",
          "2024-09-19 16:25:03",
          "2024-09-19 16:26:03",
          "2024-09-19 16:27:03",
          "2024-09-19 16:28:03",
          "2024-09-19 16:29:03",
          "2024-09-19 16:30:03",
          "2024-09-19 16:31:03",
          "2024-09-19 16:32:03",
          "2024-09-19 16:33:03",
          "2024-09-19 16:34:03",
          "2024-09-19 16:35:03",
          "2024-09-19 16:36:03",
          "2024-09-19 16:37:03",
          "2024-09-19 16:38:03",
          "2024-09-19 16:39:03",
          "2024-09-19 16:40:03",
          "2024-09-19 16:41:03",
          "2024-09-19 16:42:03",
          "2024-09-19 16:43:03",
          "2024-09-19 16:44:03",
          "2024-09-19 16:45:03",
          "2024-09-19 16:46:03",
          "2024-09-19 16:47:03",
          "2024-09-19 16:48:03",
          "2024-09-19 16:49:03",
          "2024-09-19 16:50:03",
          "2024-09-19 16:51:03",
          "2024-09-19 16:52:03",
          "2024-09-19 16:53:03",
          "2024-09-19 16:54:03",
          "2024-09-19 16:55:03",
          "2024-09-19 16:56:03",
          "2024-09-19 16:57:03",
          "2024-09-19 16:58:03",
          "2024-09-19 16:59:03",
          "2024-09-19 17:00:03",
          "2024-09-19 17:01:03",
          "2024-09-19 17:02:03",
          "2024-09-19 17:03:03",
          "2024-09-19 17:04:03",
          "2024-09-19 17:05:03",
          "2024-09-19 17:06:03",
          "2024-09-19 17:07:03",
          "2024-09-19 17:08:03",
          "2024-09-19 17:09:03",
          "2024-09-19 17:10:03",
          "2024-09-19 17:11:03",
          "2024-09-19 17:12:03",
          "2024-09-19 17:13:03",
          "2024-09-19 17:14:03",
          "2024-09-19 17:15:03",
          "2024-09-19 17:16:03",
          "2024-09-19 17:17:03",
          "2024-09-19 17:18:03",
          "2024-09-19 17:19:03",
          "2024-09-19 17:20:03",
          "2024-09-19 17:21:03",
          "2024-09-19 17:22:03",
          "2024-09-19 17:23:03",
          "2024-09-19 17:24:03",
          "2024-09-19 17:25:03",
          "2024-09-19 17:26:03",
          "2024-09-19 17:27:03",
          "2024-09-19 17:28:03",
          "2024-09-19 17:29:03",
          "2024-09-19 17:30:03",
          "2024-09-19 17:31:03",
          "2024-09-19 17:32:03",
          "2024-09-19 17:33:03",
          "2024-09-19 17:34:03",
          "2024-09-19 17:35:03",
          "2024-09-19 17:36:03",
          "2024-09-19 17:37:03",
          "2024-09-19 17:38:03",
          "2024-09-19 17:39:03",
          "2024-09-19 17:40:03",
          "2024-09-19 17:41:03",
          "2024-09-19 17:42:03",
          "2024-09-19 17:43:03",
          "2024-09-19 17:44:03",
          "2024-09-19 17:45:03",
          "2024-09-19 17:46:03",
          "2024-09-19 17:47:03",
          "2024-09-19 17:48:03",
          "2024-09-19 17:49:03",
          "2024-09-19 17:50:03",
          "2024-09-19 17:51:03",
          "2024-09-19 17:52:03",
          "2024-09-19 17:53:03",
          "2024-09-19 17:54:03",
          "2024-09-19 17:55:03",
          "2024-09-19 17:56:03",
          "2024-09-19 17:57:03",
          "2024-09-19 17:58:03",
          "2024-09-19 17:59:03",
          "2024-09-19 18:00:03",
          "2024-09-19 18:01:03",
          "2024-09-19 18:02:03",
          "2024-09-19 18:03:03",
          "2024-09-19 18:04:03",
          "2024-09-19 18:05:03",
          "2024-09-19 18:06:03",
          "2024-09-19 18:07:03",
          "2024-09-19 18:08:03",
          "2024-09-19 18:09:03",
          "2024-09-19 18:10:04",
          "2024-09-19 18:11:03",
          "2024-09-19 18:12:03",
          "2024-09-19 18:13:03",
          "2024-09-19 18:14:04",
          "2024-09-19 18:15:04",
          "2024-09-19 18:16:04",
          "2024-09-19 18:17:04",
          "2024-09-19 18:18:04",
          "2024-09-19 18:19:04",
          "2024-09-19 18:20:04",
          "2024-09-19 18:21:04",
          "2024-09-19 18:22:04",
          "2024-09-19 18:23:04",
          "2024-09-19 18:24:04",
          "2024-09-19 18:25:04",
          "2024-09-19 18:26:04",
          "2024-09-19 18:27:04",
          "2024-09-19 18:28:04",
          "2024-09-19 18:29:04",
          "2024-09-19 18:30:04",
          "2024-09-19 18:31:04",
          "2024-09-19 18:32:04",
          "2024-09-19 18:33:04",
          "2024-09-19 18:34:04",
          "2024-09-19 18:35:04",
          "2024-09-19 18:36:04",
          "2024-09-19 18:37:04",
          "2024-09-19 18:38:04",
          "2024-09-19 18:39:04",
          "2024-09-19 18:40:04",
          "2024-09-19 18:41:04",
          "2024-09-19 18:42:04",
          "2024-09-19 18:43:04",
          "2024-09-19 18:44:04",
          "2024-09-19 18:45:04",
          "2024-09-19 18:46:04",
          "2024-09-19 18:47:04",
          "2024-09-19 18:48:04",
          "2024-09-19 18:49:04",
          "2024-09-19 18:50:04",
          "2024-09-19 18:51:04",
          "2024-09-19 18:52:04",
          "2024-09-19 18:53:04",
          "2024-09-19 18:54:04",
          "2024-09-19 18:55:04",
          "2024-09-19 18:56:04",
          "2024-09-19 18:57:04",
          "2024-09-19 18:58:04",
          "2024-09-19 18:59:04",
          "2024-09-19 19:00:04",
          "2024-09-19 19:01:04",
          "2024-09-19 19:02:04",
          "2024-09-19 19:03:04",
          "2024-09-19 19:04:04",
          "2024-09-19 19:05:04",
          "2024-09-19 19:06:04",
          "2024-09-19 19:07:04",
          "2024-09-19 19:08:04",
          "2024-09-19 19:09:04",
          "2024-09-19 19:10:04",
          "2024-09-19 19:11:04",
          "2024-09-19 19:12:04",
          "2024-09-19 19:13:04",
          "2024-09-19 19:14:04",
          "2024-09-19 19:15:04",
          "2024-09-19 19:16:04",
          "2024-09-19 19:17:04",
          "2024-09-19 19:18:04",
          "2024-09-19 19:19:04",
          "2024-09-19 19:20:04",
          "2024-09-19 19:21:04",
          "2024-09-19 19:22:04",
          "2024-09-19 19:23:04",
          "2024-09-19 19:24:04",
          "2024-09-19 19:25:04",
          "2024-09-19 19:26:04",
          "2024-09-19 19:27:04",
          "2024-09-19 19:28:04",
          "2024-09-19 19:29:04",
          "2024-09-19 19:30:04",
          "2024-09-19 19:31:04",
          "2024-09-19 19:32:04",
          "2024-09-19 19:33:04",
          "2024-09-19 19:34:04",
          "2024-09-19 19:35:04",
          "2024-09-19 19:36:04",
          "2024-09-19 19:37:04",
          "2024-09-19 19:38:04",
          "2024-09-19 19:39:04",
          "2024-09-19 19:40:04",
          "2024-09-19 19:41:04",
          "2024-09-19 19:42:04",
          "2024-09-19 19:43:04",
          "2024-09-19 19:44:04",
          "2024-09-19 19:45:04",
          "2024-09-19 19:46:04",
          "2024-09-19 19:47:04",
          "2024-09-19 19:48:04",
          "2024-09-19 19:49:04",
          "2024-09-19 19:50:04",
          "2024-09-19 19:51:04",
          "2024-09-19 19:52:04",
          "2024-09-19 19:53:04",
          "2024-09-19 19:54:04",
          "2024-09-19 19:55:04",
          "2024-09-19 19:56:04",
          "2024-09-19 19:57:04",
          "2024-09-19 19:58:04",
          "2024-09-19 19:59:04",
          "2024-09-19 20:00:04",
          "2024-09-19 20:01:04",
          "2024-09-19 20:02:04",
          "2024-09-19 20:03:04",
          "2024-09-19 20:04:04",
          "2024-09-19 20:05:04",
          "2024-09-19 20:06:04",
          "2024-09-19 20:07:04",
          "2024-09-19 20:08:04",
          "2024-09-19 20:09:04",
          "2024-09-19 20:10:04",
          "2024-09-19 20:11:04",
          "2024-09-19 20:12:04",
          "2024-09-19 20:13:04",
          "2024-09-19 20:14:04",
          "2024-09-19 20:15:04",
          "2024-09-19 20:16:04",
          "2024-09-19 20:17:04",
          "2024-09-19 20:18:04",
          "2024-09-19 20:19:04",
          "2024-09-19 20:20:04",
          "2024-09-19 20:21:04",
          "2024-09-19 20:22:04",
          "2024-09-19 20:23:04",
          "2024-09-19 20:24:04",
          "2024-09-19 20:25:04",
          "2024-09-19 20:26:04",
          "2024-09-19 20:27:04",
          "2024-09-19 20:28:04",
          "2024-09-19 20:29:04",
          "2024-09-19 20:30:04",
          "2024-09-19 20:31:04",
          "2024-09-19 20:32:04",
          "2024-09-19 20:33:04",
          "2024-09-19 20:34:04",
          "2024-09-19 20:35:04",
          "2024-09-19 20:36:04",
          "2024-09-19 20:37:04",
          "2024-09-19 20:38:04",
          "2024-09-19 20:39:04",
          "2024-09-19 20:40:04",
          "2024-09-19 20:41:04",
          "2024-09-19 20:42:04",
          "2024-09-19 20:43:04",
          "2024-09-19 20:44:04",
          "2024-09-19 20:45:04",
          "2024-09-19 20:46:04",
          "2024-09-19 20:47:04",
          "2024-09-19 20:48:04",
          "2024-09-19 20:49:04",
          "2024-09-19 20:50:04",
          "2024-09-19 20:51:04",
          "2024-09-19 20:52:04",
          "2024-09-19 20:53:04",
          "2024-09-19 20:54:04",
          "2024-09-19 20:55:04",
          "2024-09-19 20:56:04",
          "2024-09-19 20:57:04",
          "2024-09-19 20:58:04",
          "2024-09-19 20:59:04",
          "2024-09-19 21:00:04",
          "2024-09-19 21:01:04",
          "2024-09-19 21:02:04",
          "2024-09-19 21:03:04",
          "2024-09-19 21:04:04",
          "2024-09-19 21:05:04",
          "2024-09-19 21:06:04",
          "2024-09-19 21:07:04",
          "2024-09-19 21:08:04",
          "2024-09-19 21:09:04",
          "2024-09-19 21:10:04",
          "2024-09-19 21:11:04",
          "2024-09-19 21:12:04",
          "2024-09-19 21:13:04",
          "2024-09-19 21:14:04",
          "2024-09-19 21:15:04",
          "2024-09-19 21:16:04",
          "2024-09-19 21:17:04",
          "2024-09-19 21:18:04",
          "2024-09-19 21:19:04",
          "2024-09-19 21:20:04",
          "2024-09-19 21:21:04",
          "2024-09-19 21:22:04",
          "2024-09-19 21:23:04",
          "2024-09-19 21:24:04",
          "2024-09-19 21:25:04",
          "2024-09-19 21:26:04",
          "2024-09-19 21:27:04",
          "2024-09-19 21:28:04",
          "2024-09-19 21:29:04",
          "2024-09-19 21:30:04",
          "2024-09-19 21:31:04",
          "2024-09-19 21:32:04",
          "2024-09-19 21:33:04",
          "2024-09-19 21:34:04"
        ],
        "series": [
          {
            "title": "COD",
            "tag": "CT_CODIT501_DIS",
            "unit": "mg/L",
            "limit": 24,
            "value": [
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              12.86,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              13.05,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              12.92,
              13.93,
              13.93,
              13.93,
              13.93,
              13.93,
              13.93,
              13.93,
              13.93,
              13.93,
              13.93,
              13.93,
              13.93,
              13.93,
              13.93,
              13.93,
              13.93,
              13.93,
              13.93,
              13.93,
              13.93,
              13.93,
              13.93,
              13.93,
              13.93,
              13.93,
              13.93,
              13.93,
              13.93,
              13.93,
              13.93,
              13.93,
              13.93,
              13.93,
              13.93,
              13.93,
              13.93,
              13.93,
              13.93,
              13.93,
              13.93,
              13.93,
              13.93,
              13.93,
              13.93,
              13.93,
              13.93,
              13.93,
              13.93,
              13.93,
              13.93,
              13.93,
              13.93,
              14.57,
              14.57,
              14.57,
              14.57,
              14.57,
              14.57,
              14.57,
              14.57,
              14.57,
              14.57,
              14.57,
              14.57,
              14.57,
              14.57,
              14.57,
              14.57,
              14.57,
              14.57,
              14.57,
              14.57,
              14.57,
              14.57,
              14.57,
              14.57,
              14.57,
              14.57,
              14.57,
              14.57,
              14.57,
              14.57,
              14.57,
              14.57,
              14.57,
              14.57,
              14.57,
              14.57,
              14.57,
              14.57,
              14.57,
              14.57,
              14.57,
              14.57,
              14.57,
              14.57,
              14.57,
              14.57,
              14.57,
              14.57,
              14.57,
              14.57,
              14.57,
              14.57,
              14.57,
              14.57,
              14.57,
              14.57,
              14.57,
              14.57,
              14.57,
              14.57
            ]
          }
        ],
        "parting": "2024-09-19 20:34:04"
      }
    };
  },
  mounted() {
    this.getIncomingWaterData();
    this.getPostLinkageData();
    this.getPostLinkagePieData();
  },
  methods: {
    getIncomingWaterData() {
      this.incomingWaterLoading = true;
      const params = {
        p_code: this.code,
      };
      // const xAxis = null;

      if (this.code == 'tn') {
        let series = []
        this.incomingWatertndata.series.map((item) => {
          series.push({
            name: item.title,
            data: item.value,
            symbol: "none",
            smooth: false,
            type: "line",
            lineStyle: {
              color: 'rgba(0, 128, 128, 1)',
              width: 1.2,
            },

            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    { offset: 0, color: "rgba(0, 128, 128, 1)" },
                    { offset: 1, color: "rgba(0, 128, 128, 0.1)" },
                  ],
                  false
                ),
              },
            },
          });
        });

        const options = { ...this.initChartOptions, series };
        options.yAxis.name = this.incomingWatertndata.series[0].unit;
        options.xAxis.data = this.incomingWatertndata.xAxis;
        this.incomingWaterOptions = options;
        this.incomingWaterLoading = false;

      } else {
        let series = []
        this.incomingWatercoddata.series.map((item) => {
          series.push({
            name: item.title,
            data: item.value,
            symbol: "none",
            smooth: false,
            type: "line",
            lineStyle: {
              color: 'rgba(0, 128, 128, 1)',
              width: 1.2,
            },
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    { offset: 0, color: "rgba(0, 128, 128, 1)" },
                    { offset: 1, color: "rgba(0, 128, 128, 0.1)" },
                  ],
                  false
                ),
              },
            },
          });
        });
        const options = { ...this.initChartOptions, series };
        options.yAxis.name = this.incomingWatercoddata.series[0].unit;
        options.xAxis.data = this.incomingWatercoddata.xAxis;
        this.incomingWaterOptions = options;
        this.incomingWaterLoading = false;
      }
    },
    getPostLinkageData() {
      this.postLinkageLoading = true;

      const params = {
        p_code: this.code,
      };
      if (this.code == 'tn') {
        const series = [];
        this.postLinktndata.series.map((item) => {
          const obj = {
            name: item.title,
            data: item.value,
            symbol: "none",
            smooth: false,
            type: "line",
            lineStyle: {
              color: 'rgba(0, 128, 128, 1)',
              width: 1.2,
            },
            markArea: {
              silent: true,
              data: [
                [
                  {
                    name: "History",
                    xAxis: this.postLinktndata.xAxis[0],
                    itemStyle: {
                      color: "rgba(255, 141, 26, 0.0)",
                    },
                    label: {
                      show: true,
                      color: 'rgba(168, 168, 168, 1)',
                    },
                  },
                  {
                    xAxis: this.postLinktndata.parting,
                  },
                ],
                [
                  {
                    name: "Forecast",
                    xAxis: this.postLinktndata.parting,
                    itemStyle: {
                      color: "rgba(255, 99, 71, 0.3)",
                    },
                    label: {
                      show: true,
                      color: 'rgba(168, 168, 168, 1)',
                    },
                  },
                  {
                    xAxis: this.postLinktndata.xAxis[this.postLinktndata.xAxis.length - 1],
                  },
                ],
              ],
            },
            areaStyle: {

              normal: {

                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    { offset: 0, color: "rgba(0, 128, 128, 1)" },
                    { offset: 1, color: "rgba(0, 128, 128, 0.1)" },
                  ],
                  false
                ),
              },
            },
          };
          if (this.code === "tn") {
            obj.markLine = {
              symbol: "none",
              silent: true,
              lineStyle: { normal: { type: "dashed" } },
              label: { position: "middle" },
              data: [
                {
                  name: "Discharge Limits",
                  yAxis: item.limit,
                  lineStyle: { width: 1.5, color: "#ff6367" },
                  label: {
                    formatter: "{b}: {c}",
                    color: 'rgba(168, 168, 168, 1)',

                  },
                },
              ],
            };
          }
          series.push(obj);
        });

        const options = {
          ...this.initChartOptions,
          series,
          chartTitle: `Discharge  ${this.postLinktndata.series[0].title}`,
        };
        options.yAxis.name = this.postLinktndata.series[0].unit;
        options.yAxis.max = this.postLinktndata.series[0].limit + 1;
        options.xAxis.data = this.postLinktndata.xAxis;
        this.postLinkageLineOptions = options;
        this.postLinkageLoading = false;
      } else {
        const series = [];
        this.postLinkcoddata.series.map((item) => {
          const obj = {
            name: item.title,
            data: item.value,
            symbol: "none",
            smooth: false,
            type: "line",
            lineStyle: {
              color: 'rgba(0, 128, 128, 1)',
              width: 1.2,
            },
            markArea: {
              silent: true,
              data: [
                [
                  {
                    name: "History",
                    xAxis: this.postLinkcoddata.xAxis[0],
                    itemStyle: {
                      color: "rgba(255, 141, 26, 0.0)",
                    },
                    label: {
                      show: true,
                      color: 'rgba(168, 168, 168, 1)',
                    },
                  },
                  {
                    xAxis: this.postLinkcoddata.parting,
                  },
                ],
                [
                  {
                    name: "Forecast",
                    xAxis: this.postLinkcoddata.parting,
                    itemStyle: {
                      color: "rgba(255, 99, 71, 0.3)",
                    },
                    label: {
                      show: true,
                      color: 'rgba(168, 168, 168, 1)',
                    },
                  },
                  {
                    xAxis: this.postLinkcoddata.xAxis[this.postLinkcoddata.xAxis.length - 1],
                  },
                ],
              ],
            },
            areaStyle: {

              normal: {

                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    { offset: 0, color: "rgba(0, 128, 128, 1)" },
                    { offset: 1, color: "rgba(0, 128, 128, 0.1)" },
                  ],
                  false
                ),
              },
            },
          };
          series.push(obj);
        });

        const options = {
          ...this.initChartOptions,
          series,
          chartTitle: `Discharge  ${this.postLinkcoddata.series[0].title}`,
        };
        options.yAxis.name = this.postLinkcoddata.series[0].unit;
        options.yAxis.max = this.postLinkcoddata.series[0].limit + 1;
        options.xAxis.data = this.postLinkcoddata.xAxis;
        this.postLinkageLineOptions = options;
        this.postLinkageLoading = false;
      }
    },
    getPostLinkagePieData() {
      this.postLinkagePieLoading = true;
      const params = {
        p_code: this.code,
      };
      console.log(this.code);
      if (this.code == 'tn') {
        const data =
          [{ name: 'Smart Denitrification Control System', value: 100 }];
        this.postLinkagePieOptions.series[0].data = data;
        this.postLinkagePieOptions.title[0].text = `TN Removal \n Amount (%)`;
        this.postLinkagePieLoading = false;
      } else {
        const data =
          [{ name: "Smart Ozonation Control System", value: 0.02 }, { name: "Smart Aeration System", value: 0.98 }];
        this.postLinkagePieOptions.series[0].data = data;
        this.postLinkagePieOptions.title[0].text = `COD Removal  \n Amount (%)`;
        this.postLinkagePieLoading = false;
      }
    },
    onChartDataZoom(type) {
      timer && clearTimeout(timer);
      timer = setTimeout(() => {
        commonApi.saveUserAction({
          module: "Load Sensing",
          path: `index_${this.tabName}_${type}`,
          action: "chartZoom",
        });
      }, 1500);
    },
  },
};
</script>

<style lang="scss" scoped>
.total-effluent-container {
  display: flex;
  width: 100%;
  height: 100%;
}

.incoming-water-box {
  width: 24%;
  height: 100%;
}

.linkage-module-box {
  margin: 0 32px;
  width: calc(44% - 64px);
}

.post-linkage-box {
  display: flex;
  width: 32%;
  justify-content: space-between;

  .line-chart-box {
    width: 55%;
  }

  .pie-chart-box {
    width: 45%;
  }
}
</style>
