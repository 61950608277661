<template>
  <div class="container" @click="viewDetails()">
    <el-card style="border-radius: 12px">
      <img class="dtsearch" src="../../assets/dtsearch.png" />
      <span class="dttext" style="color: rgba(242, 242, 242, 1);">Data Query</span>
    </el-card>
    <el-dialog title="Data Query" :visible.sync="dialogVisible" width="70%" :before-close="handleClose">
      <el-select ref='select' style="width: 30%; margin-bottom: 30px" v-model="value" filterable multiple
        placeholder="search" @change="selectChanged" v-clickOutside="clickOutside">
        <el-option v-for="item in choselist" :key="item.value" :label="item.label" :value="item">
        </el-option>
      </el-select>
      <chartCpn ref="child" :codMain="'myChart'" :ChartData="childform" :datas="tableOpen" v-show="childform != null"
        style="width: 100%; height: 400px">
      </chartCpn>
      <div style="height: 200px; overflow: scroll" class="drawerTable">
        <el-table :data="tableOpen" style="width: 100%; margin-bottom: 180px" :row-class-name="tableRowClassName"
          :header-cell-style="{
    'text-align': 'center',
    backgroundColor: '#fafafa',
  }" :cell-style="{ 'text-align': 'center' }">
          <template slot="empty">
            <span>
              No Data Yet
            </span>
          </template>
          <el-table-column label="index" type="index" width="100">
          </el-table-column>
          <el-table-column prop="name" label="DataSource"> </el-table-column>
          <el-table-column label="StartDate" prop="starttime">
            <template slot-scope="scope">
              <div class="block">
                <el-date-picker v-model="scope.row.starttime" value-format="yyyy-MM-dd" type="date"
                  placeholder="SelectDate" :picker-options="pickerOptions">
                </el-date-picker>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="starttime" label="Deadline">
            <template slot-scope="scope">
              <div class="block">
                <el-date-picker v-model="scope.row.endtime" type="date" value-format="yyyy-MM-dd"
                  placeholder="SelectDate" :picker-options="pickerOptions">
                </el-date-picker>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="extre" label="Extremum">
            <template slot-scope="scope">
              <el-checkbox-group v-model="scope.row.extre">
                <el-checkbox v-for="item in detailsList" :label="item.label" :key="item.label">{{ item.name
                  }}</el-checkbox>
              </el-checkbox-group>
            </template>
          </el-table-column>
          <el-table-column label="yAxisDisplay" prop="eaxis">
            <template slot-scope="scope">
              <el-checkbox-group v-model="scope.row.eaxis">
                <el-checkbox v-for="item in eaxisLabel" :label="item.label" :key="item.label">{{ item.name
                  }}</el-checkbox>
              </el-checkbox-group>
            </template>
          </el-table-column>
          <el-table-column label="DataComparison" prop="compare">
            <template slot-scope="scope">
              <el-checkbox-group v-model="scope.row.compare">
                <el-checkbox v-for="item in compareLabel" :label="item.label" :key="item.label">{{ item.name
                  }}</el-checkbox>
              </el-checkbox-group>
            </template>
          </el-table-column>
        </el-table>

      </div>

      <div class="datalist">
        <div class="allChoice">
          <div class="avgList">
            <span class="avgspan">Mean：</span>
            <el-checkbox v-for="item in avg" :key="item.label" @change="checkbox()"></el-checkbox>
          </div>
          <div class="searchList">
            <span class="searchspan">QueryFrequency：</span>
            <el-radio v-model="frq.radio" :label="item.radio" v-for="(item, index) in frq" :key="index">{{ item.name
              }}</el-radio>
          </div>
        </div>
        <div class="searchBtn">
          <el-button round type="primary" @click="searching()">Search</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import chartCpn from "../component/chartDrawer";
export default {
  components: {
    chartCpn,
  },
  data() {
    return {
      value: "",
      dialogVisible: false,
      soureOpen: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      disabled: false,
      direction: "btt",
      direction1: "rtl",
      drawer1: false,
      dataSheet: [],
      value1: "",
      value2: "",
      drawerInput: "",
      addSheet: [],
      chartData: [],
      multipleSelection: [],
      roleData: [],
      detailsList: [
        { name: "max", label: "max" },
        { name: "min", label: "min" },
        { name: "average", label: "avg" },
      ],
      avg: [{ name: "mean", label: "avg", datatype: "" }],
      compareLabel: [
        { name: "YearOnYear", label: "yyb" },
        { name: "chain", label: "mmr" },
      ],
      eaxisLabel: [{ name: "extremum", label: "mum" }],
      eaxis: [],
      frq: [
        { name: "5Min", radio: 300 },
        { name: "1H", radio: 3600 },
        { name: "2H", radio: 7200 },
        { name: "1D", radio: 86400 },
      ],

      allTableData: [],
      tableData: [],
      itemData1: [],
      arr: [],
      arr1: [],
      childform: this.itemData,
      rowIndex: 0,
      datatype: null,
      dis: false,
      choselist: [],
      pointlabel: "",
      pointtag: "",
      tableOpen: [],
      tableitem: [],
    };
  },
  mounted() {
    this.getchoselist();
  },
  directives: {
    clickOutside: {
      bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) { // here I check that click was outside the el and his childrens
          if (!(el === event.target || el.contains(event.target))) { // and if it did, call method provided in attribute value
            vnode.context[binding.expression](event)
          }
        }
        document.body.addEventListener('click', el.clickOutsideEvent)
      },
      unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
      }
    }
  },
  methods: {
    handleClose(done) {
      this.$confirm("confirm？", {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      })
        .then((_) => {
          console.log('close');
          this.value = null
          this.tableOpen = []
          this.itemData = []
          done();
        })
        .catch((_) => { });
    },
    viewDetails() {
      this.dialogVisible = true;
    },
    checkbox() {
      this.dis = event.target.checked;
      console.log(this.dis);
    },
    selectChanged(item) {
      console.log(item);
      // this.pointlabel = item.label;
      // this.pointtag = item.value;
      this.tableitem = item;
      this.tableopen();
    },

    async getchoselist() {
      const { data: res } = await this.$http.get("datacenter/sources");
      this.choselist = res.source;
      console.log(this.choselist);
    },
    tableRowClassName({ row, rowIndex }) {
      console.log(row);
      this.rowIndex = rowIndex;
    },
    tableopen() {
      const soureTable2 = [];
      const statusNow = new Date();
      const nowStartDate = {
        year: statusNow.getFullYear(),
        month:
          statusNow.getMonth() + 1 < 10
            ? "0" + (statusNow.getMonth() + 1)
            : statusNow.getMonth() + 1,
        date:
          statusNow.getDate() < 10
            ? "0" + statusNow.getDate()
            : statusNow.getDate(),
      };
      console.log(nowStartDate.month);
      const nowStartDate1 =
        nowStartDate.year + "-" + nowStartDate.month + "-" + nowStartDate.date;
      console.log(nowStartDate1);

      let time = new Date().getTime() - 24 * 60 * 60 * 1000;
      let yy = new Date(time).getFullYear();
      let mm =
        new Date(time).getMonth() + 1 < 10
          ? "0" + (new Date(time).getMonth() + 1)
          : new Date(time).getMonth() + 1;

      let dd =
        new Date(time).getDate() < 10
          ? "0" + new Date(time).getDate()
          : new Date(time).getDate();

      let hh = new Date(time).getHours();

      let mf =
        new Date(time).getMinutes() < 10
          ? "0" + new Date(time).getMinutes()
          : new Date(time).getMinutes();

      let ss =
        new Date(time).getSeconds() < 10
          ? "0" + new Date(time).getSeconds()
          : new Date(time).getSeconds();
      let beforeDay = yy + "-" + mm + "-" + dd;
      console.log(yy + "-" + mm + "-" + dd);
      console.log(beforeDay);
      for (let i = 0; i < this.tableitem.length; i++) {
        soureTable2[i] = {
          name: this.tableitem[i].label,
          tag: this.tableitem[i].value,
          starttime: beforeDay,
          endtime: nowStartDate1,
          extre: [],
          eaxis: [],
          compare: [],
          frq: "",
        };
      }
      console.log(soureTable2);
      this.tableOpen = soureTable2;
      console.log(this.tableOpen);
    },
    async searching() {

      console.log(this.avg);
      if (this.dis == false) {
        this.avg.datatype = 0;
      }
      if (this.dis == true) {
        this.avg.datatype = 1;
      }
      console.log(this.avg.datatype);
      for (let i = 0; i < this.tableOpen.length; i++) {
        this.tableOpen[i].frq = this.frq.radio;
      }
      console.log(this.tableOpen);

      let paramsData = this.tableOpen.map((item) => {
        console.log(this.tableOpen);
        return Object.assign(
          {},
          {
            tag: item.tag,
            starttime: item.starttime,
            endtime: item.endtime,
            frq: item.frq,
            extre: item.extre,
            eaxis: item.eaxis,
            compare: item.compare,
            datatype: this.avg.datatype,
          }
        );
      });
      console.log(paramsData);
      if (paramsData[0].frq != undefined) {
        const { data: res } = await this.$http.post("datacenter/search", {
          data: paramsData,
        });
        this.itemData1 = res.data.result;
        console.log(this.itemData1);
        this.childform = this.itemData1;
      } else {
        return this.$message.error("Please select the query frequency！");
      }
    },
    clickOutside() {
      this.$refs.select.blur()
    }
  },
};
</script>

<style lang="scss" scoped>
.container {
  border-radius: 8px;

  .dtsearch {
    width: 27px;
    vertical-align: middle;
    padding-right: 20px;
  }

  ::v-deep .el-dialog {
    border-radius: 32px;

    .el-dialog__header {
      padding: 20px 50px 10px !important;
    }

    .el-dialog__body {
      padding: 30px 50px !important;
    }
  }

  .datalist {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 70px 30px 70px;
    vertical-align: baseline;
    font-size: 16px;

    .allChoice {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    ::v-deep .el-checkbox__inner {
      width: 18px;
      height: 18px;
    }

    ::v-deep .el-checkbox__inner::after {
      // width: 5px;
      height: 11px;
      left: 6px;
    }

    .avgList {
      margin-right: 80px;

      .el-checkbox__inner {
        width: 20px !important;
        height: 20px !important;
      }

      .avgspan {
        color: rgba(64, 224, 208, 1);
        font-size: 16px;
      }
    }

    .searchList {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .searchspan {
        font-size: 16px;
        margin-right: 30px;
        vertical-align: baseline;
        color: rgba(64, 224, 208, 1);
      }

      .searchBtn {
        margin-left: 20px;
        margin-right: 20px;

        .el-button {
          margin-left: 20px;
        }
      }
    }
  }

  ::v-deep .el-dialog__wrapper {

    .el-dialog__header {

      .el-dialog__title {
        color: rgba(242, 242, 242, 1) !important;
      }
    }

  }

  ::v-deep .el-radio__input.is-checked+.el-radio__label {
    color: rgba(64, 224, 208, 1);
    border-color: rgba(64, 224, 208, 1);
    // background-color: rgba(64, 224, 208, 1);
    ;
  }

  ::v-deep.el-radio__input.is-checked .el-radio__inner {
    border-color: rgba(64, 224, 208, 1);
    background: rgba(64, 224, 208, 1);
  }

  ::v-deep .el-radio__input.is-checked .el-radio__inner {

    color: rgba(64, 224, 208, 1);
  }

  ::v-deep .el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
    color: rgba(64, 224, 208, 1);
    background-color: #1c1c1c;
  }
}
</style>
