import axios from "axios";

export default {
  saveUserAction(params) {
    axios.post("/api/ba", params).then(res => {
      if (res.data && res.data.code === 200) {
        console.log(res.data.msg);
      } else {
        console.log(res.data.msg);
      }
    })
  }
}