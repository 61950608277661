<template>
  <div>
    <el-card style="border-radius: 16px">
      <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
        <el-tab-pane v-for="(item, index) in chartData" :key="item.type" :label="item.type" :name="item.type" :lazy=true
          style="display: flex;">
          <div class="trendbox" v-if="item.type == activeName">

            <div class="trend" v-for="(items, index) in item.data" :key="items.index" @click="changeChart(items)">
              <div class="black">{{ items.title + '&nbsp&nbsp' + items.legend[0].unit }}</div>

              <iochart class="iochart" :codMain="item.type + items.title" :ChartData="items"></iochart>
            </div>
          </div>
          <div class="bigchart" style="width: 65%; display: inline-block" v-if="item.type == activeName">
            <maincharts :codMain="'iobigChart' + bigchartdata.title" :ChartData="bigchartdata" class="maincharts">
            </maincharts>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import iochart from "../../components/component/iochart";
import maincharts from "../../components/component/maincharts";
export default {
  components: {
    iochart,
    maincharts,
  },
  data() {
    return {
      chartData: null,
      bigchartdata: null,
      activeName: null,
      timer: null
    };
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  mounted() {
    this.getChartData();
    this.timer = window.setInterval(() => {
      setTimeout(this.getChartData(), 0);
    }, 300000);
  },
  methods: {
    async getChartData() {
      const { data: res } = await this.$http.get("/modules/extra", {
        params: { type: "chart" },
      });
      this.chartData = res.data.result;
      console.log(this.chartData);
      this.activeName = this.chartData[0].type
      this.changeChart(this.chartData[0].data[0])
    },
    changeChart(item) {
      console.log(item);
      this.bigchartdata = item;
      console.log(item);
    },
    handleClick(tab, event) {

      this.changeChart(this.chartData[tab.index].data[0])

    },
  },
};
</script>

<style lang="scss" scoped>
.black {
  padding: 10px;
  font-size: 12px;
  font-weight: bold;
}

.bigchart {
  text-align: center;

  .maincharts {
    width: 100%;
    height: 300px;
    margin: 0 auto;
    margin-bottom: 20px;
    margin-left: 30px;
  }
}

.title {
  display: inline-block;
  font-weight: 500;
  font-size: 18px;
  margin: 15px;
  margin-right: 10px;
}

.trendbox {
  border-radius: 16px;
  height: 300px;
  overflow: scroll;
  display: inline-block;
  width: 33%;
  overflow-x: hidden // display: flex;
    // justify-content: center;
    // align-items: center;
    // flex-flow: row wrap
}

.trendbox::-webkit-scrollbar {
  width: 5px !important;
}

.trendbox::-webkit-scrollbar-track {
  background: rgba(47, 50, 59, 1);
  border-radius: 2px;
}

.trendbox::-webkit-scrollbar-thumb {
  background: rgba(87, 87, 87, 1);
  border-radius: 10px;
}

.trendbox::-webkit-scrollbar-thumb:hover {
  background: #333;
}

// .trendbox::-webkit-scrollbar-corner {
//   background: #179a16;
// }

.trend {
  width: 98%;
  background-color: red;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  margin-left: 5px;

  .iochart {
    width: 100%;
    height: 105px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
}

::v-deep.el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
  // border-bottom-color: #DCDFE6;
  // border-top-color: #DCDFE6;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);

}

::v-deep .el-tabs__header {
  padding: 3px 3px;
}

::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item {
  border-radius: 30px;
}

::v-deep .el-tabs--border-card>.el-tabs__header {
  background-color: transparent;
  border-bottom: none;
}

::v-deep .el-tabs__nav is-top {
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.1);
}

::v-deep .el-tabs--border-card {
  -webkit-box-shadow: none;
  border: none;
}

::v-deep .el-tabs__nav-scroll {
  padding: 2px;
}
</style>
