<template>
  <div>
    <span class="openctspan">Historical Data </span>
    <span class="openctspan1">12h</span>
    <el-card class="history_card">
      <div class="history_div">
        <div class="trend" v-for="(item, index) in chartData" :key="item.index" @click="changeChart(item, index)">
          <div class="black">{{ item.title }}</div>
          <div>
            <!-- 1222222222222 -->
            <iochart :codMain="'myChart' + '-' + index" :ChartData="item" class="iochart" v-if="show"></iochart>
          </div>
        </div>
      </div>
      <div class="bigchart">
        <maincharts :key="chartData[0].index" :codMain="'bigchart' + '-' + index" :ChartData="bigchartdata"
          :firstindex="firstindex" class="mainchart"></maincharts>
      </div>
    </el-card>
  </div>
</template>

<script>
import iochart from "../../components/component/iochart";
import maincharts from "../../components/component/maincharts";

export default {
  components: {
    iochart,
    maincharts,
  },
  data() {
    return {
      index: 0,
      chartData: null,
      bigchartdata: null,
      firstindex: null,
      timer: null,
      show: false
    };
  },
  destroyed() {

    clearInterval(this.timer);
    this.timer = null;
  },
  created() {
    // this.timer = window.setInterval(async () => {
    //   setTimeout(await this.getChartData(this.openIndex), 0);
    // }, 30000);
  },
  mounted() {

    this.openIndex = this.$route.query.index;
    this.getChartData(this.openIndex)
    this.timer = window.setInterval(async () => {
      setTimeout(await this.getChartData(this.openIndex), 0);
    }, 300000);
    // this.getChartData(this.openIndex);
    // this.$nextTick(() => {
    //   this.initSwiper();
    // });
  },
  watch: {
    "$route.query.index"(newVal, oldVal) {
      console.log(newVal);
      clearInterval(this.timer);
      this.timer = null;
      this.openIndex = newVal;
      this.getChartData(this.openIndex)
      this.timer = window.setInterval(async () => {
        setTimeout(await this.getChartData(this.openIndex), 0);
      }, 300000);
      // this.timer = window.setInterval(async () => {
      //   setTimeout(await this.getChartData(this.openIndex), 0);
      // }, 30000);
      // this.getChartData(this.openIndex);
      // this.$nextTick(() => {
      //   this.initSwiper();
      // });
    },

  },
  methods: {
    async getChartData(index) {
      console.log(index);
      this.show = false
      const { data: res } = await this.$http.get("/modules/chart", {
        params: { index: index },
      });
      this.chartData = res.data.result;
      console.log(this.chartData);
      this.changeChart(this.chartData[0], 0);
    },
    changeChart(item, index) {
      // console.log(index);
      this.firstindex = index
      this.bigchartdata = item;
      this.show = true
      // console.log(item);
    },
  },
};
</script>

<style lang="scss" scoped>
.trend {
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  width: 94%;
  margin-left: 24px;
  // background-color: #fff;

  .iochart {
    width: 100%;
    height: 200px;
    margin: 0 auto;
    // background-color: #fff !important;
    margin-bottom: 20px;
  }

  // display: inline-block;
}

.black {
  padding: 10px;
  font-size: 12px;
  font-weight: bold;
}

.bigchart {
  width: 65%;
  display: inline-block;
  vertical-align: top;
  margin-top: 50px;

  .mainchart {
    width: 100%;
    height: 400px;
    margin: 0 auto;
    margin-bottom: 20px;
    margin-left: 30px;
  }
}

.openctspan {
  margin-top: 20px;
  margin-left: 50px;
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  color: rgba(242, 242, 242, 1);
}

.openctspan1 {
  margin-top: 20px;
  margin-left: 8px;
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  color: #a6a6a6;
}

.history_card {
  border-radius: 16px;
  margin: 20px 0 50px 50px;
  margin-bottom: 50px;

  .history_div {
    border-radius: 16px;
    margin: 20px 20px 0px 20px;
    height: 500px;
    overflow: scroll;
    display: inline-block;
    width: 32%;
    text-align: center;
    overflow-x: hidden;
  }

  .history_div::-webkit-scrollbar {
    width: 5px !important;
  }

  .history_div::-webkit-scrollbar-track {
    background: rgba(47, 50, 59, 1);
    border-radius: 2px;
  }

  .history_div::-webkit-scrollbar-thumb {
    background: rgba(87, 87, 87, 1);
    border-radius: 10px;
  }

  .history_div::-webkit-scrollbar-thumb:hover {
    background: #333;
  }
}

::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item {
  border-radius: 30px;
}

::v-deep .el-tabs--border-card>.el-tabs__header {
  background-color: transparent;
  border-bottom: none;
}

::v-deep .el-tabs__nav is-top {
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.1);
}

::v-deep .el-tabs--border-card {
  -webkit-box-shadow: none;
  border: none;
}

::v-deep .el-tabs__nav-scroll {
  padding-left: 0;
}

::v-deep .el-card__body,
.el-main {
  padding: 0;
}
</style>
