<template>
  <div class="chartOne">
    <div :id="codMain" class="codMain"></div>
  </div>
</template>

<script>
import * as XLSX from "xlsx";
import XLSXS from "xlsx-style-cptable";
import FileSaver from "file-saver";
import { mapState } from "vuex";
export default {
  props: {
    codMain: String,
    ChartData: Object,
  },
  data() {
    return {
      // one: this.ChartData,
      one: this.ChartData,
      timer: null,
      codChart: null,
      chartData: {},
      seriesData: [],
      openIndex: null,
      chartTitle: "",
      chartUnit: "",
      initOption: {
        backgroundColor: '',

        animation: true,
        animationDuration: 1500,
        dataZoom: [
          {
            type: "inside",
          },
        ],

        grid: {
          top: "10%",
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },

        xAxis: {
          type: "category",
          boundaryGap: false,
          axisLine: {
            show: true,
            lineStyle: {
              color: "#fffff",
            },
          },
          textStyle: { color: "#fffff" },
          axisTick: { show: false },
          axisLabel: {
            show: true,
            textStyle: {
              color: "rgba(163, 163, 163, 1)",
            }
          },

        },
        yAxis: {
          type: "value",
          splitLine: {
            show: false
          }
        },
        tooltip: {
          confine: true,
          trigger: "axis",
          axisPointer: {
            type: "line",
            z: 0,
            lineStyle: {
              color: "#2D3443",
            },
          },
          textStyle: {
            align: "left",
          },
          extraCssText: "z-index: 99999",
        },
        series: [
          {
            name: "",
            data: "",
            symbol: "none",
            smooth: true,
            type: "line",
            label: {
              show: false,
              position: "right",
            },

            itemStyle: {
              normal: {
                color: '#498AF4',
                lineStyle: {
                  color: '#498AF4',

                }
              }
            },
            zlevel: 999,
          },
        ],
      },
    };
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  mounted() {
    this.initChart();
    this.timer = window.setInterval(() => {
      setTimeout(this.initChart(), 0);
    }, 300000);
    window.addEventListener("resize", () => {
      this.codChart.resize();
    });
  },
  computed: {
    ...mapState(["theme"]),
  },
  watch: {
    "$store.state.open"(newVal, oldVal) {
      this.$nextTick(() => {
        setTimeout(() => {
          console.log(this.codChart);
          this.codChart.resize();
        }, 10);
      });
    },
    ChartData(newval) {
      if (newval) {
        this.initChart();
      }
    },
    theme() {
      this.codChart.dispose();
      this.initChart();
    },
  },
  methods: {
    initChart() {
      this.chartTitle = this.ChartData.title;
      this.chartUnit = this.ChartData.legend.unit;
      if (this.chartUnit == '-') {
        this.chartUnit = ''
      }
      this.initOption.xAxis.data = this.ChartData.xAxis;
      this.initOption.title = {
        left: "center",
        text: this.chartTitle,
        show: false,
      };
      this.initOption.yAxis = [
        {
          type: "value",
          name: this.$props.chartYName,
          splitLine: {
            show: false,
          },
          axisLine: { show: false },
          axisLabel: {
            textStyle: {
              color: "#828282",
            },
          },
          axisTick: { show: false },
        },
      ];
      this.initOption.series = {
        name: this.ChartData.legend.name,
        data: this.ChartData.legend.yAxis,
        symbol: "none",
        type: "line",
        // yAxisIndex: index,
        areaStyle: {
          normal: {
            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1,
              [{
                offset: 0,
                color: 'rgba(255, 99, 71, 1) '
              }, {
                offset: 1, color: 'rgba(255, 99, 71, 0.1)'
              }]),
            // #5E78C9
            // #91CC75
          },
        },
        label: {
          show: false,
          position: "right",
        },
        lineStyle: { width: 1.6, },
        itemStyle: {
          normal: {
            color: '#498AF4',
            lineStyle: {
              width: 1,
              color: 'rgba(255, 99, 71, 1)',
            }
          }
        },


      };

      let newPromise = new Promise((resolve) => {
        resolve();
      });
      newPromise.then(() => {
        var myChart = this.$echarts.init(
          document.getElementById(this.codMain),
          this.theme
        );
        myChart.setOption(this.initOption);
        this.codChart = this.$echarts.init(
          document.getElementById(this.codMain)
        );
        if (this.codChart == null) {
          this.codChart = this.$echarts.init(
            document.getElementById(this.codMain)
          );
        }
        this.codChart.clear();
        this.codChart.setOption(this.initOption);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.chartOne {
  width: 100%;
  // height: 100%;
  margin: 0 auto;
  // box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    border-radius: 6px;
    width: 5px !important;
    background-color: #ccc;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: #989898;
    height: 30px;
  }

  .codMain {
    width: 93%;
    height: 100%;
    margin: 0 auto;
    z-index: 99;
    // background-color: #fff;
  }

  h6 {
    padding-left: 36px;
    text-align: left;
    font-size: 12px;
    color: rgb(76, 76, 76);
    font-weight: 600;
  }
}
</style>
