<template>
  <div class="allbgc" v-if="myshow">
    <control style="margin: 0"></control>
    <modular-linkage-control />
    <div style="display: flex">
      <iowater style="width: 150%; margin-bottom: 80px; margin-left: 40px"></iowater>
      <div style="width: 100%; margin-left: 40px; margin-right: 40px">
        <dataone></dataone>
        <datasearch style="margin-top: 20px"></datasearch>
      </div>
    </div>
  </div>
</template>

<script>
import control from "../views/controlModules/Control";
import ModularLinkageControl from "./AIIntelligentControl/components/ModularLinkageControl.vue";
import iowater from "../components/component/iowater";
import dataone from "../components/component/dataone";
import datasearch from "../components/component/datasearch";
export default {
  components: {
    control,
    ModularLinkageControl,
    iowater,
    dataone,
    datasearch,
  },
  data() {
    return {
      myshow: false,
      loginForm: {
        username: "admin",
        password: "L0veadmin.",
      },
      access_token: ''
    }
  },
  created() {
    // this.$store.commit("changename", this.loginForm.username);
    // this.access_token = window.sessionStorage.getItem("havedata");

    // let access_token = window.sessionStorage.getItem("havedata")
    // if (access_token){

    // }else{

    this.goToLogin()
    // }
    // new DevicePixelRatio().init();
  },
  methods: {
    async goToLogin() {
      this.$store.commit("changename", this.loginForm.username);
      this.$store.commit("changepassword", this.loginForm.password);
      const res = await this.$http.post("user/login", this.loginForm);
      if (res.data.code !== 200) return this.$message.error(res.data.message);
      window.sessionStorage.setItem(
        "username",
        res.data.message.substring(5)
      );
      window.sessionStorage.setItem(
        "Loclusername",
        res.data.message.substring(5)
      );
      window.sessionStorage.setItem("access_token", res.data.access_token);
      window.sessionStorage.setItem("refresh_token", res.data.refresh_token);
      this.myshow = true
      // this.$router.push("/ai/control");

    },
  }
};

</script>

<style lang="scss" scoped>
.allbgc {
  overflow: hidden;
}
</style>
