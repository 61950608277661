<template>
  <div id="app">
    <router-view />

  </div>
</template>

<script>
import DevicePixelRatio from './some';


import "./assets/css/global.scss";
export default {
  data() {
    return {
      name: "App",
      type: "light",
      loginForm: {
        username: "admin",
        password: "L0veadmin.",
      },
    };
  },
  created() {
    if (this.baseURL.indexOf("192.168") !== -1) {
      document.title = "Xsim AI Copilot Demo";
    } else {
      document.title = "Xsim AI Copilot Demo";
    }
    // this.goToLogin()
    new DevicePixelRatio().init();
  },
  methods: {
    async goToLogin() {
      this.$store.commit("changename", this.loginForm.username);
      this.$store.commit("changepassword", this.loginForm.password);
      const res = await this.$http.post("user/login", this.loginForm);
      if (res.data.code !== 200) return this.$message.error(res.data.message);
      window.sessionStorage.setItem(
        "username",
        res.data.message.substring(5)
      );
      window.sessionStorage.setItem(
        "Loclusername",
        res.data.message.substring(5)
      );
      window.sessionStorage.setItem("access_token", res.data.access_token);
      window.sessionStorage.setItem("refresh_token", res.data.refresh_token);

      this.$router.push("/ai/control");

    },
  }

};
</script>

<style lang="scss" scoped></style>
