<template>
  <div class="chartOne">
    <div :id="codMain" class="codMain"></div>
  </div>
</template>

<script>
import * as XLSX from "xlsx";
import XLSXS from "xlsx-style-cptable";
import FileSaver from "file-saver";
import { mapState } from "vuex";
export default {
  props: {
    codMain: String,
    ChartData: Object,
  },
  data() {
    return {
      // one: this.ChartData,
      one: this.ChartData,
      myChart: null,
      timer: null,
      codChart: null,
      chartData: {},
      seriesData: [],
      openIndex: null,
      chartTitle: "",
      chartUnit: "",
      initOption: {
        animation: true,
        animationDuration: 1500,
        backgroundColor: '',
        grid: {
          top: "10%",
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        // toolbox: {
        //   y: 16,
        //   feature: {
        //     dataView: {},
        //     saveAsImage: { show: true },
        //   },
        // },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisLine: {
            show: true,
            lineStyle: {
              color: "#fffff",
            },
          },
          textStyle: { color: "#fffff" },
          axisTick: { show: false },
          axisLabel: {
            show: true,
            textStyle: {
              color: "rgba(163, 163, 163, 1)",
            }
          },

        },
        yAxis: {
          type: "value",
        },

        series: [
          {
            name: "",
            data: "",
            symbol: "none",
            smooth: true,
            lineStyle: { width: 1.6 },
            type: "line",
            label: {
              show: false,
              position: "right",
            },
            itemStyle: {
              lineStyle: {
                color: '"#1890ff"',
                width: 1.2,
              },
            },
            // zlevel: 999,
          },
        ],
      },
    };
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  mounted() {
    console.log(this.ChartData);
    this.initChart();

    this.timer = window.setInterval(() => {
      setTimeout(this.initChart(), 0);
    }, 300000);
    window.addEventListener("resize", () => {
      this.codChart.resize();
    });
  },
  computed: {
    ...mapState(["theme"]),
  },
  watch: {
    "$store.state.open"(newVal, oldVal) {
      this.$nextTick(() => {
        // this.codChart.resize();
        setTimeout(() => {
          console.log(this.codChart);
          this.codChart.resize();
        }, 10);
      });
    },
    ChartData(newval) {
      console.log(this.codChart);
      this.codChart.clear();
      if (newval) {
        console.log(newval);
        this.initChart();
      }
    },
    theme() {
      // console.log("nidaye");
      this.codChart.dispose();
      this.initChart();
    },
  },
  methods: {
    initChart() {
      console.log(11111111111111111111111);
      console.log(this.ChartData);
      // this.chartTitle = this.ChartData.title;
      this.chartUnit = this.ChartData.legend[0].unit;
      if (this.chartUnit == '-') {
        this.chartUnit = ''
      }
      this.initOption.xAxis.data = this.ChartData.xAxis;
      // const legendData = this.ChartData.legend.map((item) => {
      //   return item.name;
      // });
      // this.initOption.legend.data = legendData;
      this.ChartData.legend.map((item, index, array) => {
        this.initOption.title = {
          left: "center",
          text: this.chartTitle,
          show: false,
        };
        this.initOption.yAxis = [
          {
            type: "value",
            name: this.$props.chartYName,
            splitLine: {
              show: false,
            },
            axisLine: { show: false },
            axisLabel: {
              textStyle: {
                color: "#828282",
              },
            },
            axisTick: { show: false },
          },
        ];
        if (index === 0) {
          this.initOption.series[index] = {
            name: item.name,
            data: item.yAxis,
            symbol: "none",
            smooth: true,
            lineStyle: { width: 1.6 },
            type: "line",
            // yAxisIndex: index,
            label: {
              show: false,
              position: "right",
            },
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1,
                  [{
                    offset: 0,
                    color: 'rgba(0, 128, 128, 1)'
                  }, {
                    offset: 1, color: 'rgba(0, 128, 128, 0.1)'
                  }]),
              },
            },
            itemStyle: {
              normal: {
                color: 'rgba(64, 224, 208, 1)',
                lineStyle: {
                  width: 1,
                  color: 'rgba(0, 128, 128, 1)',
                }
              }
            },
          };
        } else {
          // console.log(item);
          this.initOption.series[index] = {
            name: item.name,
            data: item.yAxis,
            symbol: "none",
            smooth: true,
            lineStyle: { width: 1.6 },
            type: "line",
            // yAxisIndex: index,
            label: {
              show: false,
              position: "right",
            },
            itemStyle: {
              lineStyle: {
                color: 'rgba(0, 128, 128, 1)',
                width: 2,
              },
            },
          };
        };
      })
      console.log(this.initOption);
      let newPromise = new Promise((resolve) => {
        resolve();
      });
      newPromise.then(() => {
        // console.log(this.theme);
        this.myChart = this.$echarts.init(
          document.getElementById(this.codMain),
          this.theme
        );
        // console.log(myChart);
        this.myChart.setOption(this.initOption);
        this.codChart = this.$echarts.init(
          document.getElementById(this.codMain)
        );
        console.log(this.codChart);
        if (this.codChart == null) {
          this.codChart = this.$echarts.init(
            document.getElementById(this.codMain)
          );
        }
        this.codChart.clear();
        this.codChart.setOption(this.initOption, true);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.chartOne {
  width: 100%;
  height: 100%;
  margin: 0 auto;

  overflow-y: scroll;

  ::-webkit-scrollbar {
    border-radius: 6px;
    width: 5px !important;
    background-color: #ccc;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: #989898;
    height: 30px;
  }

  .codMain {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    // background-color: aqua;
    // z-index: 99;
    // background-color: #fff;
    // background-color: #fff;
  }

  h6 {
    padding-left: 36px;
    text-align: left;
    font-size: 12px;
    color: rgb(76, 76, 76);
    font-weight: 600;
  }
}
</style>
