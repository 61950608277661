<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="12" v-for="(item, index) in o" :key="index">
        <div class="maincard" :style="{ background: colorList[index] }">
          <div class="maindev">

            <P class="mainname">{{ item.name }}</P>

            <el-carousel indicator-position="none" arrow="never" direction="vertical" class="el_car">
              <el-carousel-item v-for="(i) in item.value" :key="i.value">

                <div class="divtwo" v-if="index == 1">
                  <p class="divvalue">
                    {{ i }}
                  </p>
                </div>
                <div class="divtwo" v-if="index == 0">
                  <span>{{ i.unit }}</span>
                  <p class="divvalue">
                    {{ i.value }}
                  </p>
                </div>
                <div class="divtwo" v-else>
                  <span>{{
      i.visible }} {{ i.unit }}</span>
                  <p class="divvalue">
                    {{ i.current }}
                  </p>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  components: {},
  data() {
    return {
      time: null,
      colorList: ["#ECF9FF", "#FFF6ED", "#FFEFEF", "#E6FAFF"],
      o: [
        { name: "Treated Volume", value: [{ unit: "t/d", value: 10086 }] },

      ],
    };
  },
  created() {

    let theme = localStorage.getItem("theme")
      ? JSON.parse(localStorage.getItem("theme"))
      : "light";
    // console.log(theme);
    if (theme === 'dark') {
      this.colorList = ["rgba(34, 38, 49, 1)", "rgba(34, 38, 49, 1)", "rgba(34, 38, 49, 1)", "rgba(34, 38, 49, 1)"]

    }
    this.getmodellist();
    this.timer = window.setInterval(async () => {
      setTimeout(await this.getmodellist(), 0);
    }, 300000);
    ;

  },
  computed: {
    ...mapState(["theme"]),
  },
  beforeDestroy() {

    clearInterval(this.timer);
  },
  watch: {
    theme(newVal, oldVal) {
      console.log(newVal, oldVal);
      if (newVal == 'chalk') {
        this.colorList = ["rgba(34, 38, 49, 1)", "rgba(34, 38, 49, 1)", "rgba(34, 38, 49, 1)", "rgba(34, 38, 49, 1)"]

      } else {
        this.colorList = ["#ECF9FF", "#FFF6ED", "#FFEFEF", "#E6FAFF"]

      }
    },
  },
  methods: {
    async getmodellist() {
      this.o = [
        { name: "Treated Volume", value: [{ unit: "t/d", value: '15000' }] },

      ];
      const { data: res } = await this.$http.get("/modules/initall");
      console.log(res.data.result);
      let arr = []
      for (let i = 0; i < res.data.result.length; i++) {
        if (res.data.result[i].result.runbtn == true) {
          arr.push(res.data.result[i].result.name)
        }
      }
      this.o.push({
        name: "System In-Use",
        value: arr
      })
      this.getmodeltype()
    },
    async getmodeltype() {
      const { data: res } = await this.$http.get("/modules/extra", {
        params: { type: "curr" },
      });
      console.log(res.data.result);
      let arr = []
      for (let i = 0; i < res.data.result.length; i++) {
        // if (res.data.result[i].result.runbtn == true) {
        //   arr.push(res.data.result[i].result.name)
        // }
        this.o.push({
          name: res.data.result[i].type,
          value: res.data.result[i].data
        })
      }
      console.log(this.o);
      // this.o.toString().replaceAll("-", "");
      // this.o[2] = res.data.result[0]
      // this.o[3] = res.data.result[1]
    },

  }
};
</script>

<style lang="scss" scoped>
.maincard {
  display: inline-block;
  border-radius: 8px;
  padding: 22px 0;
  margin-bottom: 35px;
  width: 100%;

  .maindev {
    margin: 0 30px;
  }

  .el_car {
    height: 50px;
  }

  .mainname {
    font-size: 18px;
    color: #4d4a4a;
    font-weight: 400;
    margin: 0;
    margin-bottom: 20px;
  }

  .divtwo {
    // display: flex;
    justify-content: space-between;
    align-items: center;
    color: rgba(242, 242, 242, 1);

    .divspan {
      font-size: 18px;
      color: #4d4a4a;
    }

    .divvalue {
      display: inline-block;
      float: right;
      font-size: 18px;
      color: rgba(64, 224, 208, 1);
      font-weight: 600;
      margin: 0;
    }
  }

  ::v-deep .el-card {
    border-radius: 12px !important;
  }
}
</style>
