<template>
  <div class="container">
    <div class="title">Load Sensing & Equalisation Control</div>


    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="Discharge TN" name="tn" lazy>
        <TotalEffluent v-if="activeName === 'tn'" tabName="Discharge TN" code="tn" :moduleList="tabsData['tn']" />
      </el-tab-pane>
      <el-tab-pane label="Discharge COD" name="cod" lazy>
        <TotalEffluent v-if="activeName === 'cod'" tabName="Discharge COD" code="cod" :moduleList="tabsData['cod']" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { Message } from 'element-ui';
import TotalEffluent from "./TotalEffluent.vue";
import commonApi from "@/api/common.js";

export default {
  components: {
    TotalEffluent,
  },
  data() {
    return {
      activeName: "cod",
      tabsData: {
        'cod': [
          "AO_DO",
          "AO_DN",
          "OE_O3"
        ],
        'tn': [
          "AO_DN"
        ]
      },
      popoverVisible: false,
      feedbackValue: undefined,
    };
  },
  mounted() {
    // this.getTabsData();
  },
  methods: {
    handleClick() {
      this.popoverVisible = false;
    },
    getTabsData() {
      this.$http
        .get("/api/code", {
          params: {},
        })
        .then((res) => {
          if (res.data && res.data.code === 200) {
            this.tabsData = res.data.data;
          }
          console.log(this.tabsData);
        });
    },

  },
};
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  margin: 0 40px 40px;
  padding: 20px 30px;
  height: 480px;
  opacity: 1;
  font-size: 14px;
  border-radius: 16px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 29px 1px rgba(0, 0, 0, 0.05);

  .title {
    text-align: center;
    font-size: 14px;
  }

  .remark-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #6e6e6e;
    cursor: pointer;

    .iconfont {
      margin-right: 6px;
    }
  }

  ::v-deep .el-tabs {
    .el-tab-pane {
      padding-top: 28px;
      height: 386px;
    }

    .el-tabs__item {
      font-size: 14px;
    }
  }
}
</style>

<style lang="scss">
.feedback-popover {
  .feedback-box {
    display: flex;
  }

  .el-input {
    ::v-deep .el-input__inner {
      border-radius: 40px;
    }
  }

  .el-button {
    margin-left: 20px;
    height: 40px;
    border-radius: 38px;
    background: rgba(36, 102, 168, 1);
  }
}
</style>
