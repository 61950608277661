<template>
  <div class="warnContainer" id="warnContainer">
    <span class="warnspan">Alert</span>
    <el-table id="tableData" :data="warningData.warnings" :show-header="false" @row-click="handle"
      @cell-mouse-enter="Stop" @cell-mouse-leave="Up" :row-style="{ height: '67px' }" class="warn_table"
      :max-height="height">
      <el-table-column width="130" class="first" align="center">【alarms】</el-table-column>
      <el-table-column prop="warningdes" width="250"></el-table-column>
      <el-table-column label="type" align="center" width="150">
        <template slot-scope="scope">
          <i v-if="scope.row.state == 'unprocessed'" class="dotClass"
            style="background-color: rgba(255, 99, 71, 1)"></i>
          <i v-if="scope.row.state == 'Processed'" class="dotClass" style="background-color: rgba(64, 224, 208, 1)"></i>
          {{ scope.row.state }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      warningData: {
        warnings: [
          {
            warningdes: 'Please pay attention to Deep Process COD greater than 35mg/L.',
            type: 'asdad',
            state: 'unprocessed'
          }, {
            warningdes: 'Please pay attention to Clarification Tank COD greater than 30mg/L.',
            type: 'asdad',
            state: 'unprocessed'
          },
          {
            warningdes: 'Please pay attention to incoming heavy load - Influent COD greater than 350 mg/L. ',
            type: 'asdad',
            state: 'unprocessed'
          }, {
            warningdes: 'Please be aware of the high pressure of aeration blower 7#',
            type: 'asdad',
            state: 'unprocessed'
          },
          {
            warningdes: 'Please be aware of the high pressure of aeration blower 6#',
            type: 'asdad',
            state: 'Processed'
          },
          {
            warningdes: 'Please be aware of the high pressure of aeration blower 5#',
            type: 'asdad',
            state: 'Processed'
          }, {
            warningdes: 'Please be aware of the high pressure of aeration blower 4#',
            type: 'asdad',
            state: 'Processed'
          },

        ]
      }
      ,
      openIndex: "",
      warningAllData: null,
      timer: "",
      animate: false,
      queryInfo: {
        warningdes: "",
        states: "",
        modules: "",
        pagenum: 1,
        pagesize: 6,
      },

      // height: "520px"
    };
  },
  created() {
    this.openIndex = this.$route.query.index;
    // this.getWarning(this.openIndex);
    this.timer = setInterval(this.scroll, 3000);
    // this.getAlertAllData();
  },

  mounted() {
    this.getAutoHeight();
    const self = this;
    window.onresize = function () {
      self.getAutoHeight();
    };
  },
  watch: {
    "$route.query.index"(newVal, oldVal) {
      console.log(newVal);
      this.openIndex = newVal;
      this.timer = setInterval(this.scroll, 3000);
      // this.getAlertAllData();
    },
  },
  sockets: {
    warningList(res) {
      // this.getAlertAllData();
      console.log(111);
    },
  },
  methods: {
    scroll() {
      this.animate = true;
      if (this.warningData.length > 6) {
        // this.isRoll = true
        setTimeout(() => {
          this.warningData.push(this.warningData[0]);
          this.warningData.shift();
          this.animate = false;
          // console.log('111');
        }, 500);
      }
    },
    //       
    Stop() {
      clearInterval(this.timer);
    },
    Up() {
      this.timer = setInterval(this.scroll, 3000);
    },
    async getAlertAllData() {
      this.queryInfo.modules = this.openIndex;
      console.log(this.queryInfo.modules);
      const { data: res } = await this.$http.get("/warning/history", {
        params: this.queryInfo,
      });
      if (res.meta.status !== 200) {
        return this.$message.error("error");
      }
      this.warningData = res.data;
      console.log(res.data);
    },
    getAutoHeight() {
      let el = document.querySelector("#tableData"),
        elParent = el.parentNode,
        pt = this.getStyle(elParent, "paddingTop"),
        pb = this.getStyle(elParent, "paddingBottom");
      this.$nextTick(() => {
        this.height = el.clientHeight - (pt + pb) + "px";
        console.log(elParent.clientHeight, pt, pb, this.height);
      });
    },
    getStyle(obj, attr) {
      let result = obj.currentStyle
        ? obj.currentStyle[attr].replace("px", "")
        : document.defaultView
          .getComputedStyle(obj, null)[attr].replace("px", "");
      return Number(result);
    }


  },
};
</script>

<style lang="scss" scoped>
.warnContainer {
  width: 100%;
  height: 100%;
  cursor: pointer;

  .warnspan {
    margin-left: 8px;
    margin-right: 10px;
    text-align: left;
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    color: rgba(242, 242, 242, 1);
  }

  .warn_table {
    width: 100%;
    height: 520px;
    overflow: scroll;

    .first {}
  }

  ::v-deep .el-table {
    width: 100%;
    margin-bottom: 50px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

    .el-table__header-wrapper table,
    .el-table__body-wrapper table {
      width: 100% !important;
    }

    .el-table__header-wrapper table,
    .el-table__body-wrapper table {
      width: 100% !important;
    }

    .el-table__body,
    .el-table__footer,
    .el-table__header {
      table-layout: auto !important;
    }

    .el-table .cell {
      padding-left: 0px !important;
    }

    .el-table__empty-block {
      width: 100% !important;
    }
  }

  ::v-deep .el-table {
    width: 100% !important;
    margin-top: 20px;
    border-radius: 20px;
    // overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
  }

  ::v-deep .el-table .cell {
    font-size: 14px;
  }

  .dotClass {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 8px;
  }
}
</style>
