<template>
  <div class="linkage-module-TN">
    <div class="header">
      <span class="text1">Smart Denitrification Control System</span>
      <div class="switch-box">
        <el-popover placement="bottom" trigger="click" popper-class="linkage-module-popover" @show="onPopoverShow">
          <div class="popover-content">
            <div class="value-item" v-for="(item, index) in targetList" :key="index">
              {{ item.label }}：{{ item.value }}
            </div>
          </div>
          <span slot="reference">
            <i class="iconfont icon-xiangxiajiantou" />
            NOx-N Target Recommendation
          </span>
        </el-popover>
      </div>
    </div>
    <LineChart :chartOptions="chartOptions" height="100%" @onChartDataZoom="onChartDataZoom(chartOptions.chartTitle)" />
  </div>
</template>

<script>
import LineChart from "@/components/SimModule/components/LineChart.vue";
import commonApi from "@/api/common.js";

let timer = null, chartTimer = null;
export default {
  components: {
    LineChart,
  },
  props: {
    tabName: String,
    moduleList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      switchValue: false,
      initChartOptions: {
        animation: true,
        animationDuration: 1500,
        color: ["#2366A8", "#73C0DE", "#ff6367"],
        dataZoom: [
          {
            type: "inside",
          },
        ],
        legend: {
          data: "",
          itemHeight: 11,
          itemWidth: 22,
          textStyle: {
            rich: {
              a: {
                verticalAlign: "middle",
              },
            },
            padding: [0, 0, -2, 0],
          },
        },
        grid: {
          top: "16%",
          left: "3%",
          right: "4%",
          bottom: "5%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: false,
          },
        },
        tooltip: {
          confine: true,
          trigger: "axis",
          axisPointer: {
            type: "line",
            z: 0,
            lineStyle: {
              color: "#2D3443",
            },
          },
          textStyle: {
            align: "left",
          },
          extraCssText: "z-index: 99999",
        },
        series: [
          {
            name: "",
            data: "",
            symbol: "none",
            smooth: true,
            lineStyle: { width: 1.6 },
            type: "line",
            label: {
              show: false,
              position: "right",
            },
            itemStyle: {
              lineStyle: {
                color: '"#1890ff"',
                width: 1.2,
              },
            },
            zlevel: 999,
          },
        ],
      },
      chartOptions: {},
      targetList: [],
      tnlinkdata: {
        "xAxis": [
          "2024-09-19 14:49:02",
          "2024-09-19 14:50:02",
          "2024-09-19 14:51:02",
          "2024-09-19 14:52:02",
          "2024-09-19 14:53:02",
          "2024-09-19 14:54:02",
          "2024-09-19 14:55:02",
          "2024-09-19 14:56:02",
          "2024-09-19 14:57:02",
          "2024-09-19 14:58:02",
          "2024-09-19 14:59:02",
          "2024-09-19 15:00:02",
          "2024-09-19 15:01:02",
          "2024-09-19 15:02:02",
          "2024-09-19 15:03:02",
          "2024-09-19 15:04:02",
          "2024-09-19 15:05:02",
          "2024-09-19 15:06:02",
          "2024-09-19 15:07:03",
          "2024-09-19 15:08:02",
          "2024-09-19 15:09:03",
          "2024-09-19 15:10:02",
          "2024-09-19 15:11:03",
          "2024-09-19 15:12:02",
          "2024-09-19 15:13:02",
          "2024-09-19 15:14:02",
          "2024-09-19 15:15:02",
          "2024-09-19 15:16:03",
          "2024-09-19 15:17:03",
          "2024-09-19 15:18:02",
          "2024-09-19 15:19:03",
          "2024-09-19 15:20:03",
          "2024-09-19 15:21:03",
          "2024-09-19 15:22:03",
          "2024-09-19 15:23:03",
          "2024-09-19 15:24:03",
          "2024-09-19 15:25:03",
          "2024-09-19 15:26:03",
          "2024-09-19 15:27:03",
          "2024-09-19 15:28:03",
          "2024-09-19 15:29:03",
          "2024-09-19 15:30:03",
          "2024-09-19 15:31:03",
          "2024-09-19 15:32:03",
          "2024-09-19 15:33:03",
          "2024-09-19 15:34:03",
          "2024-09-19 15:35:03",
          "2024-09-19 15:36:03",
          "2024-09-19 15:37:03",
          "2024-09-19 15:38:03",
          "2024-09-19 15:39:03",
          "2024-09-19 15:40:03",
          "2024-09-19 15:41:03",
          "2024-09-19 15:42:03",
          "2024-09-19 15:43:03",
          "2024-09-19 15:44:03",
          "2024-09-19 15:45:03",
          "2024-09-19 15:46:03",
          "2024-09-19 15:47:03",
          "2024-09-19 15:48:03",
          "2024-09-19 15:49:03",
          "2024-09-19 15:50:03",
          "2024-09-19 15:51:03",
          "2024-09-19 15:52:03",
          "2024-09-19 15:53:03",
          "2024-09-19 15:54:03",
          "2024-09-19 15:55:03",
          "2024-09-19 15:56:03",
          "2024-09-19 15:57:03",
          "2024-09-19 15:58:03",
          "2024-09-19 15:59:03",
          "2024-09-19 16:00:03",
          "2024-09-19 16:01:03",
          "2024-09-19 16:02:03",
          "2024-09-19 16:03:03",
          "2024-09-19 16:04:03",
          "2024-09-19 16:05:03",
          "2024-09-19 16:06:03",
          "2024-09-19 16:07:03",
          "2024-09-19 16:08:03",
          "2024-09-19 16:09:03",
          "2024-09-19 16:10:03",
          "2024-09-19 16:11:03",
          "2024-09-19 16:12:03",
          "2024-09-19 16:13:03",
          "2024-09-19 16:14:03",
          "2024-09-19 16:15:03",
          "2024-09-19 16:16:03",
          "2024-09-19 16:17:03",
          "2024-09-19 16:18:03",
          "2024-09-19 16:19:03",
          "2024-09-19 16:20:03",
          "2024-09-19 16:21:03",
          "2024-09-19 16:22:03",
          "2024-09-19 16:23:03",
          "2024-09-19 16:24:03",
          "2024-09-19 16:25:03",
          "2024-09-19 16:26:03",
          "2024-09-19 16:27:03",
          "2024-09-19 16:28:03",
          "2024-09-19 16:29:03",
          "2024-09-19 16:30:03",
          "2024-09-19 16:31:03",
          "2024-09-19 16:32:03",
          "2024-09-19 16:33:03",
          "2024-09-19 16:34:03",
          "2024-09-19 16:35:03",
          "2024-09-19 16:36:03",
          "2024-09-19 16:37:03",
          "2024-09-19 16:38:03",
          "2024-09-19 16:39:03",
          "2024-09-19 16:40:03",
          "2024-09-19 16:41:03",
          "2024-09-19 16:42:03",
          "2024-09-19 16:43:03",
          "2024-09-19 16:44:03",
          "2024-09-19 16:45:03",
          "2024-09-19 16:46:03",
          "2024-09-19 16:47:03",
          "2024-09-19 16:48:03",
          "2024-09-19 16:49:03",
          "2024-09-19 16:50:03",
          "2024-09-19 16:51:03",
          "2024-09-19 16:52:03",
          "2024-09-19 16:53:03",
          "2024-09-19 16:54:03",
          "2024-09-19 16:55:03",
          "2024-09-19 16:56:03",
          "2024-09-19 16:57:03",
          "2024-09-19 16:58:03",
          "2024-09-19 16:59:03",
          "2024-09-19 17:00:03",
          "2024-09-19 17:01:03",
          "2024-09-19 17:02:03",
          "2024-09-19 17:03:03",
          "2024-09-19 17:04:03",
          "2024-09-19 17:05:03",
          "2024-09-19 17:06:03",
          "2024-09-19 17:07:03",
          "2024-09-19 17:08:03",
          "2024-09-19 17:09:03",
          "2024-09-19 17:10:03",
          "2024-09-19 17:11:03",
          "2024-09-19 17:12:03",
          "2024-09-19 17:13:03",
          "2024-09-19 17:14:03",
          "2024-09-19 17:15:03",
          "2024-09-19 17:16:03",
          "2024-09-19 17:17:03",
          "2024-09-19 17:18:03",
          "2024-09-19 17:19:03",
          "2024-09-19 17:20:03",
          "2024-09-19 17:21:03",
          "2024-09-19 17:22:03",
          "2024-09-19 17:23:03",
          "2024-09-19 17:24:03",
          "2024-09-19 17:25:03",
          "2024-09-19 17:26:03",
          "2024-09-19 17:27:03",
          "2024-09-19 17:28:03",
          "2024-09-19 17:29:03",
          "2024-09-19 17:30:03",
          "2024-09-19 17:31:03",
          "2024-09-19 17:32:03",
          "2024-09-19 17:33:03",
          "2024-09-19 17:34:03",
          "2024-09-19 17:35:03",
          "2024-09-19 17:36:03",
          "2024-09-19 17:37:03",
          "2024-09-19 17:38:03",
          "2024-09-19 17:39:03",
          "2024-09-19 17:40:03",
          "2024-09-19 17:41:03",
          "2024-09-19 17:42:03",
          "2024-09-19 17:43:03",
          "2024-09-19 17:44:03",
          "2024-09-19 17:45:03",
          "2024-09-19 17:46:03",
          "2024-09-19 17:47:03",
          "2024-09-19 17:48:03",
          "2024-09-19 17:49:03",
          "2024-09-19 17:50:03",
          "2024-09-19 17:51:03",
          "2024-09-19 17:52:03",
          "2024-09-19 17:53:03",
          "2024-09-19 17:54:03",
          "2024-09-19 17:55:03",
          "2024-09-19 17:56:03",
          "2024-09-19 17:57:03",
          "2024-09-19 17:58:03",
          "2024-09-19 17:59:03",
          "2024-09-19 18:00:03",
          "2024-09-19 18:01:03",
          "2024-09-19 18:02:03",
          "2024-09-19 18:03:03",
          "2024-09-19 18:04:03",
          "2024-09-19 18:05:03",
          "2024-09-19 18:06:03",
          "2024-09-19 18:07:03",
          "2024-09-19 18:08:03",
          "2024-09-19 18:09:03",
          "2024-09-19 18:10:04",
          "2024-09-19 18:11:03",
          "2024-09-19 18:12:03",
          "2024-09-19 18:13:03",
          "2024-09-19 18:14:04",
          "2024-09-19 18:15:04",
          "2024-09-19 18:16:04",
          "2024-09-19 18:17:04",
          "2024-09-19 18:18:04",
          "2024-09-19 18:19:04",
          "2024-09-19 18:20:04",
          "2024-09-19 18:21:04",
          "2024-09-19 18:22:04",
          "2024-09-19 18:23:04",
          "2024-09-19 18:24:04",
          "2024-09-19 18:25:04",
          "2024-09-19 18:26:04",
          "2024-09-19 18:27:04",
          "2024-09-19 18:28:04",
          "2024-09-19 18:29:04",
          "2024-09-19 18:30:04",
          "2024-09-19 18:31:04",
          "2024-09-19 18:32:04",
          "2024-09-19 18:33:04",
          "2024-09-19 18:34:04",
          "2024-09-19 18:35:04",
          "2024-09-19 18:36:04",
          "2024-09-19 18:37:04",
          "2024-09-19 18:38:04",
          "2024-09-19 18:39:04",
          "2024-09-19 18:40:04",
          "2024-09-19 18:41:04",
          "2024-09-19 18:42:04",
          "2024-09-19 18:43:04",
          "2024-09-19 18:44:04",
          "2024-09-19 18:45:04",
          "2024-09-19 18:46:04",
          "2024-09-19 18:47:04",
          "2024-09-19 18:48:04",
          "2024-09-19 18:49:04",
          "2024-09-19 18:50:04",
          "2024-09-19 18:51:04",
          "2024-09-19 18:52:04",
          "2024-09-19 18:53:04",
          "2024-09-19 18:54:04",
          "2024-09-19 18:55:04",
          "2024-09-19 18:56:04",
          "2024-09-19 18:57:04",
          "2024-09-19 18:58:04",
          "2024-09-19 18:59:04",
          "2024-09-19 19:00:04",
          "2024-09-19 19:01:04",
          "2024-09-19 19:02:04",
          "2024-09-19 19:03:04",
          "2024-09-19 19:04:04",
          "2024-09-19 19:05:04",
          "2024-09-19 19:06:04",
          "2024-09-19 19:07:04",
          "2024-09-19 19:08:04",
          "2024-09-19 19:09:04",
          "2024-09-19 19:10:04",
          "2024-09-19 19:11:04",
          "2024-09-19 19:12:04",
          "2024-09-19 19:13:04",
          "2024-09-19 19:14:04",
          "2024-09-19 19:15:04",
          "2024-09-19 19:16:04",
          "2024-09-19 19:17:04",
          "2024-09-19 19:18:04",
          "2024-09-19 19:19:04",
          "2024-09-19 19:20:04",
          "2024-09-19 19:21:04",
          "2024-09-19 19:22:04",
          "2024-09-19 19:23:04",
          "2024-09-19 19:24:04",
          "2024-09-19 19:25:04",
          "2024-09-19 19:26:04",
          "2024-09-19 19:27:04",
          "2024-09-19 19:28:04",
          "2024-09-19 19:29:04",
          "2024-09-19 19:30:04",
          "2024-09-19 19:31:04",
          "2024-09-19 19:32:04",
          "2024-09-19 19:33:04",
          "2024-09-19 19:34:04",
          "2024-09-19 19:35:04",
          "2024-09-19 19:36:04",
          "2024-09-19 19:37:04",
          "2024-09-19 19:38:04",
          "2024-09-19 19:39:04",
          "2024-09-19 19:40:04",
          "2024-09-19 19:41:04",
          "2024-09-19 19:42:04",
          "2024-09-19 19:43:04",
          "2024-09-19 19:44:04",
          "2024-09-19 19:45:04",
          "2024-09-19 19:46:04",
          "2024-09-19 19:47:04",
          "2024-09-19 19:48:04",
          "2024-09-19 19:49:04",
          "2024-09-19 19:50:04",
          "2024-09-19 19:51:04",
          "2024-09-19 19:52:04",
          "2024-09-19 19:53:04",
          "2024-09-19 19:54:04",
          "2024-09-19 19:55:04",
          "2024-09-19 19:56:04",
          "2024-09-19 19:57:04",
          "2024-09-19 19:58:04",
          "2024-09-19 19:59:04",
          "2024-09-19 20:00:04",
          "2024-09-19 20:01:04",
          "2024-09-19 20:02:04",
          "2024-09-19 20:03:04",
          "2024-09-19 20:04:04",
          "2024-09-19 20:05:04",
          "2024-09-19 20:06:04",
          "2024-09-19 20:07:04",
          "2024-09-19 20:08:04",
          "2024-09-19 20:09:04",
          "2024-09-19 20:10:04",
          "2024-09-19 20:11:04",
          "2024-09-19 20:12:04",
          "2024-09-19 20:13:04",
          "2024-09-19 20:14:04",
          "2024-09-19 20:15:04",
          "2024-09-19 20:16:04",
          "2024-09-19 20:17:04",
          "2024-09-19 20:18:04",
          "2024-09-19 20:19:04",
          "2024-09-19 20:20:04",
          "2024-09-19 20:21:04",
          "2024-09-19 20:22:04",
          "2024-09-19 20:23:04",
          "2024-09-19 20:24:04",
          "2024-09-19 20:25:04",
          "2024-09-19 20:26:04",
          "2024-09-19 20:27:04",
          "2024-09-19 20:28:04",
          "2024-09-19 20:29:04",
          "2024-09-19 20:30:04",
          "2024-09-19 20:31:04",
          "2024-09-19 20:32:04",
          "2024-09-19 20:33:04",
          "2024-09-19 20:34:04",
          "2024-09-19 20:35:04",
          "2024-09-19 20:36:04",
          "2024-09-19 20:37:04",
          "2024-09-19 20:38:04",
          "2024-09-19 20:39:04",
          "2024-09-19 20:40:04",
          "2024-09-19 20:41:04",
          "2024-09-19 20:42:04",
          "2024-09-19 20:43:04",
          "2024-09-19 20:44:04",
          "2024-09-19 20:45:04",
          "2024-09-19 20:46:04",
          "2024-09-19 20:47:04",
          "2024-09-19 20:48:04"
        ],
        "series": [
          {
            "name": "        ",
            "unit": "mg/L",
            "target": [
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8
            ],
            "predict_target": [
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8,
              7.8
            ],
            "value": [
              6.29,
              6.33,
              6.32,
              6.18,
              6.19,
              6.08,
              6.14,
              6.16,
              6.12,
              6.14,
              7.1,
              7.04,
              7.06,
              7.19,
              7.14,
              6.37,
              6.29,
              6.3,
              6.39,
              6.31,
              6.15,
              6.13,
              6.18,
              6.16,
              6.24,
              6.53,
              6.59,
              6.52,
              6.36,
              6.37,
              5.95,
              5.93,
              6.01,
              5.84,
              5.88,
              6.13,
              6.08,
              6.11,
              6.08,
              6.06,
              5.87,
              5.88,
              5.84,
              5.88,
              5.94,
              6.26,
              6.22,
              6.23,
              6.27,
              6.3,
              6.42,
              6.38,
              6.34,
              6.4,
              6.35,
              5.92,
              5.95,
              5.97,
              6.01,
              5.9,
              6.3,
              6.27,
              6.26,
              6.26,
              6.26,
              6.25,
              6.26,
              6.2,
              6.29,
              6.25,
              6.14,
              6.22,
              6.15,
              6.1,
              6.22,
              6.29,
              6.32,
              6.22,
              6.35,
              6.41,
              6.32,
              6.31,
              6.37,
              6.32,
              6.39,
              6.38,
              6.27,
              6.38,
              6.46,
              6.41,
              6.33,
              6.33,
              6.29,
              6.35,
              6.35,
              6.41,
              6.43,
              6.43,
              6.35,
              6.36,
              6.41,
              6.52,
              6.45,
              6.43,
              6.49,
              6.19,
              6.17,
              6.19,
              6.15,
              6.15,
              6.35,
              6.35,
              6.33,
              6.54,
              6.52,
              6.31,
              6.4,
              6.36,
              6.49,
              6.42,
              6.53,
              6.49,
              6.69,
              6.51,
              6.54,
              6.53,
              6.46,
              6.49,
              6.43,
              6.43,
              6.51,
              6.56,
              6.5,
              6.46,
              6.37,
              6.27,
              6.31,
              6.31,
              6.38,
              6.36,
              6.49,
              6.45,
              6.44,
              6.54,
              6.52,
              6.65,
              6.62,
              6.71,
              6.77,
              6.83,
              6.63,
              6.59,
              6.63,
              6.62,
              6.54,
              6.73,
              6.73,
              6.78,
              6.66,
              6.57,
              6.74,
              6.72,
              6.72,
              6.66,
              6.8,
              6.23,
              6.27,
              6.23,
              6.17,
              6.11,
              6.93,
              6.89,
              6.97,
              7.06,
              7.07,
              6.37,
              6.4,
              6.37,
              6.3,
              6.46,
              6.1,
              6.11,
              6.1,
              6.34,
              6.35,
              6.37,
              6.37,
              6.39,
              6.21,
              6.29,
              6.0,
              6.05,
              6.02,
              6.1,
              6.13,
              6.5,
              6.56,
              6.51,
              6.31,
              6.24,
              6.04,
              6.04,
              6.03,
              6.27,
              6.27,
              6.53,
              6.58,
              6.48,
              6.54,
              6.53,
              6.47,
              6.53,
              6.56,
              6.6,
              6.57,
              6.52,
              6.56,
              6.62,
              6.47,
              6.53,
              6.35,
              6.39,
              6.32,
              6.28,
              6.25,
              6.04,
              6.08,
              6.08,
              6.19,
              6.18,
              6.28,
              6.19,
              6.31,
              6.31,
              6.36,
              6.51,
              6.56,
              6.52,
              6.65,
              6.67,
              6.56,
              6.57,
              6.56,
              6.57,
              6.62,
              6.89,
              6.89,
              6.95,
              6.96,
              6.96,
              6.93,
              6.74,
              6.73,
              6.75,
              6.73,
              6.78,
              6.8,
              6.67,
              6.79,
              6.73,
              6.79,
              6.82,
              6.82,
              6.74,
              6.77,
              6.36,
              6.33,
              6.41,
              6.24,
              6.28,
              6.5,
              6.48,
              6.49,
              6.35,
              6.37,
              6.39,
              6.36,
              6.38,
              6.33,
              6.27,
              6.48,
              6.54,
              6.51,
              6.47,
              6.52,
              6.46,
              6.48,
              6.44,
              6.6,
              6.6,
              6.9,
              6.9,
              6.89,
              6.88,
              6.9,
              6.41,
              6.39,
              6.43,
              6.49,
              6.45,
              7.96,
              7.9,
              7.56,
              7.79,
              7.74,
              7.7,
              7.75,
              7.74,
              7.65,
              7.74,
              7.84,
              7.66,
              7.79,
              7.78,
              7.93,
              7.91,
              7.9,
              7.63,
              7.81,
              7.69,
              7.81,
              7.81,
              7.71,
              7.99,
              7.87,
              7.95,
              7.65,
              7.61,
              7.88,
              7.65,
              7.68,
              7.65,
              7.69,
              7.81,
              7.6,
              7.78,
              7.86,
              7.92,
              7.68,
              7.89,
              7.84,
              7.72,
              7.96,
              7.87,
              7.7,
              7.78,
              7.82,
              7.79,
              7.75,
              7.8,
              7.75,
              7.88,
              7.84,
              7.87,
              7.64,
              7.71,
              7.56,
              7.96,
              7.84,
              7.71
            ]
          }],
        'parting': "2024-09-19 19:54:04"
      }
    };
  },
  mounted() {
    this.getChartData();
    this.getTargetData();

    timer && clearInterval(timer);
    timer = setInterval(() => {
      this.getChartData();
    }, 60000);
  },
  methods: {
    getChartData() {
      const params = {
        module: this.moduleList[0],
      };
      const series = [];

      this.tnlinkdata.series.map((item, index) => {
        let markArea = {};
        let visualMap = {}
        if (index === 0) {
          markArea = {
            silent: true,
            data: [
              [
                {
                  name: "History",
                  xAxis: this.tnlinkdata.xAxis[0],
                  itemStyle: {
                    color: "rgba(255, 141, 26, 0.0)",
                  }, label: {
                    show: true,
                    color: 'rgba(168, 168, 168, 1)',
                  },
                },
                {
                  xAxis: this.tnlinkdata.parting,
                },
              ],
              [
                {
                  name: "Forecast",
                  xAxis: this.tnlinkdata.parting,
                  itemStyle: {
                    color: "rgba(255, 99, 71, 0.1)",
                  }, label: {
                    show: true,
                    color: 'rgba(168, 168, 168, 1)',
                  },
                },
                {
                  xAxis: this.tnlinkdata.xAxis[this.tnlinkdata.xAxis.length - 1],
                },
              ],
            ],
          };

        }
        series.push(
          {
            name: "Current Value",
            data: item.value,
            symbol: "none",
            smooth: false,
            type: "line",
            markArea: {
              ...markArea,
            },
            lineStyle: {
              color: 'rgba(0, 128, 128, 1)',
              width: 1.2,
            },
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    { offset: 0, color: "rgba(0, 128, 128, 1)" },
                    { offset: 1, color: "rgba(0, 128, 128, 0.1)" },
                  ],
                  false
                ),
              },
            },
          },
          {
            name: "Historical Target",
            data: item.target,
            symbol: "none",
            smooth: false,
            type: "line",
            lineStyle: {
              normal: {
                type: "dashed",
              },
            },
          },
          {
            name: "AI Recommended Target",
            data: item.predict_target,
            symbol: "none",
            smooth: false,
            type: "line",
            lineStyle: {
              normal: {
                type: "dashed",
              },
            },
          },
        );
      });
      const options = { ...this.initChartOptions, series, chartTitle: this.tnlinkdata.series[0].name };
      options.xAxis.data = this.tnlinkdata.xAxis;
      options.yAxis.name = this.tnlinkdata.series[0].unit;
      options.legend.show = false;
      this.chartOptions = options;
    },
    getTargetData() {
      const params = {
        module: this.moduleList[0],
      };
      this.targetList = [
        {
          "value": 7.8,
          "label": "Aeration Tank1#NO᙮-N"
        },
        {
          "value": 7.8,
          "label": "Aeration Tank3#NO᙮-N"
        },
        {
          "value": 7.8,
          "label": "Aeration Tank4#NO᙮-N"
        }
      ]
    },
    onPopoverShow() {
      commonApi.saveUserAction({
        module: "Load Sensing",
        path: `index_${this.tabName}_NOx-N Target Recommendation`,
        action: "targetShow",
      });
    },
    onChartDataZoom(type) {
      chartTimer && clearTimeout(chartTimer);
      chartTimer = setTimeout(() => {
        commonApi.saveUserAction({
          module: "Load Sensing",
          path: `index_${this.tabName}_${type}`,
          action: "chartZoom",
        });
      }, 1500);
    },
  },
};
</script>

<style lang="scss" scoped>
.linkage-module-TN {
  padding: 24px;
  width: 100%;
  height: 380px;
  border-radius: 12px;
  background: rgba(250, 250, 252, 1);
  box-shadow: 0px 2px 17px -8px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;

  .header {
    display: flex;
    justify-content: space-between;

    .text {
      color: #a0a0a0;
    }

    .text1 {
      color: rgba(242, 242, 242, 1) !important;
    }

    .switch-box {
      color: rgba(64, 224, 208, 1);
      cursor: pointer;

      .el-switch {
        margin-left: 16px;
      }
    }
  }
}
</style>

<style lang="scss">
.linkage-module-popover {
  .popover-content {
    font-size: 14px;
    line-height: 28px;

    .value-item {
      &:not(:last-child) {
        margin-bottom: 6px;
      }
    }
  }
}
</style>
