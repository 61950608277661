import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    open: "",
    type: "",
    theme: "westeros",
    name: "",
    password: "",
    runbt: "",
    datetype: "",
    fromPage: undefined,
  },
  getters: {},
  mutations: {
    changesdatetype(state, status) {
      state.datetype = status;
    },

    changeopen(state, status) {
      state.open = status;
    },
    changetype(state, status) {
      if (status === "dark") {
        state.theme = "chalk";
      } else {
        state.theme = "westeros";
        console.log(state.theme);
      }
    },
    changeTheme(state) {
      if (state.theme === "westeros") {
        state.theme = "chalk";
      } else {
        state.theme = "westeros";
      }
    },
    changename(state, status) {
      state.name = status;
    },
    changepassword(state, status) {
      state.password = status;
    },
    changerunbt(state, status) {
      console.log(status);
      state.runbt = status;
    },
    setFromPage(state, status) {
      state.fromPage = status;
    },
  },
  actions: {},
  modules: {},
});
