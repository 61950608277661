<template>
  <div class="chart-wrap">
    <div ref="chartRef" class="pie-chart" :style="`width: 100%; height: ${typeof height === 'number' ? height + 'px' : height
      }`"></div>
  </div>
</template>

<script>
let lineChart = null;
export default {
  props: {
    height: {
      type: [Number, String],
      default: "300px",
    },
    chartTitle: String,
    chartYName: String,
    yAxisOptions: {
      type: Array,
    },
    chartOptions: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    chartOptions: {
      handler() {
        this.setChartOptions();
        lineChart.resize();
      },
      deep: true,
    }
  },
  data() {
    return {};
  },
  mounted() {
    const t = this;
    this.setChartOptions();
    lineChart.on("dataZoom", function (value) {
      t.$emit("onChartDataZoom");
    })

    window.addEventListener("resize", () => {
      const currentChart = this.$echarts.init(this.$refs.chartRef);
      currentChart.resize();
    });
  },
  methods: {
    setChartOptions() {
      lineChart = this.$echarts.init(this.$refs.chartRef)
      lineChart && lineChart.setOption({
        title: {
          text: this.$props.chartTitle,
          textStyle: {
            fontSize: 14,
            color: "#ffff",
          },

        },
        legend: {
          textStyle: {
            // fontSize: 14,
            color: "#ffff",
          },
        },
        tooltip: {
          confine: true,
          trigger: "axis",
          axisPointer: {
            type: "line",
            z: 0,
            lineStyle: {
              color: "#2D3443",
            },
          },
          textStyle: {
            align: "left",
          },
          extraCssText: "z-index: 99999",
        },
        grid: {
          top: "20%",
          left: "1%",
          right: "1%",
          bottom: "8%",
          containLabel: true,
        },
        dataZoom: [
          {
            type: "inside",
          },
        ],
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            axisLine: {
              show: true,
              lineStyle: {
                color: "#fffff",
              },
            },
            textStyle: { color: "#fffff" },
            axisTick: { show: false },
            data: [],
          },
        ],
        yAxis:
          this.$props.yAxisOptions && this.$props.yAxisOptions.length
            ? this.$props.yAxisOptions
            : [
              {
                type: "value",
                name: this.$props.chartYName,
                splitLine: {
                  show: false,
                },
                axisLine: { show: false },
                axisLabel: {
                  textStyle: {
                    color: "#828282",
                  },
                },
                axisTick: { show: false },
              },
            ],
        series: [
          {
            name: "",
            type: "line",
            smooth: true,
            symbolSize: 0,
            lineStyle: {
              normal: {
                color: "#2466A8",
              },
            },
            areaStyle: {

              normal: {

                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    { offset: 0, color: "rgba(64, 224, 208, 1)" },
                    { offset: 0.7, color: "rgba(36, 102, 168, 0)" },
                  ],
                  false
                ),
              },
            },
            data: [],
          },
        ],
        ...this.$props.chartOptions,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.chart-wrap {
  width: 100%;
  height: 100%;
}
</style>
