<template>
  <div class="ozoneContainer" id="swiper-top">
    <h4 class="black">{{ equipTitle }}</h4>
    <div v-if="eqlength < 1" style="line-height: 60px;
    width: 100%;
    text-align: center;
    color: #909399;">
      No Module Device Information
    </div>
    <div class="theSwiper" ref="swiper" v-else>
      <swiper :options="swiperOption" v-if="goto">
        <swiper-slide v-for="(item, index) in facilitiesData" :key="item.index">
          <el-card class="swiper_card">
            <div @click="equipdiolog(item)">

              <div :class="item.state === 0 ? 'newfansid1' : 'newfansid'">
                {{ item.id }}
              </div>

              <div class="imgbox">

                <img class="img1" v-if="item.type
      === 'PAC'" :src="item.state === 0 ? pamIcon1 : pamIcon" />
                <img class="img1" v-else :src="item.state === 0 ? img1[openIndex] : img[openIndex]"
                  :class="item.state === 0 ? '' : justdo" />
              </div>
              <div class="equipText">
                <p :class="index == 0 ? 'p2' : 'p1'">
                  {{ item.property[0].name }}：&nbsp &nbsp &nbsp{{
      item.property[0].value
    }}{{ " " }}{{ item.property[0].unit }}
                </p>
              </div>
            </div>
          </el-card>
          <el-card class="el_card">
            <span style="" class="black" s>{{ item.chart.title }}</span>
            <div class="minichartbox">
              <minichart :codMain="item.index + 'minichart'" :ChartData="item.chart" style="width: 100%;height: 100%;">
              </minichart>
            </div>
          </el-card>
        </swiper-slide>
        <!-- <div class="swiper-navigation" slot="navigation"></div> -->
        <div slot="button-prev" :class="eqlength <= 4 ? 'swiperStyle' : 'swiper-button-prev'
      "></div>
        <div slot="button-next" :class="eqlength <= 4 ? 'swiperStyle' : 'swiper-button-next'
      "></div>
      </swiper>
    </div>
    <el-dialog style="border-radius: 16px" :visible.sync="equipdiologopen" width="26%">
      <div class="dialog_box">
        <div class="imgbox">
          <!-- {{ openitem.type }} -->
          <img class="img1" v-if="openitem.type === 'PAC'" :src="openitem.state === 0 ? pamIcon1 : pamIcon" />
          <img class="img1" v-else :src="openitem.state === 0 ? img1[openIndex] : img[openIndex]"
            :class="openitem.state === 0 ? '' : justdo" />
        </div>
        <div style="display: inline-block; vertical-align: middle;color: rgba(242, 242, 242, 1);">
          <p>{{ openitem.id }}</p>
          <span>{{ equipTitle }}</span>
        </div>
        <div>
          <el-card style="border-radius: 8px; margin-bottom: 10px">Duration：NotYet</el-card>
          <!-- {{ openitem.state }} -->
          <el-card style="border-radius: 8px; margin-bottom: 10px" v-if="openitem.state === 1">Current
            Status：Stable operation</el-card>
          <el-card style="border-radius: 8px; margin-bottom: 10px" v-else>Current Status：Not running yet</el-card>
        </div>
      </div>

      <div style="display: flex; justify-content: space-between; margin: 10px">
        <el-card style="border-radius: 8px; margin: 10px; flex: 1; text-align: center"
          v-for="(items, index) in openitem.property" :key="items.name" :class="index == 0 ? 'p2' : 'p1'">
          {{ items.name }}<br />
          {{ items.value }}{{ " " }}{{ items.unit }}
        </el-card>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import minichart from "../../components/component/minichart";
import { log } from "util";

export default {
  components: {
    swiper,
    swiperSlide,
    minichart,
  },

  data() {
    return {
      goto: false,
      equipdiologopen: false,
      eqlength: null,
      openitem: {
        id: "",
        index: 0,
        state: "",
        name: "",
        property: [{ name: "", value: "", tag: "", unit: "" }],
      },
      picChange: "",
      justdo: "",
      img: [
        require("../../assets/fansopen.png"),
        require("../../assets/pumpopen.png"),
        // pump
        require("../../assets/fansopen.png"),
        require("../../assets/PAMopen.png"),
        require("../../assets/o3open.png"),
        require("../../assets/fansopen.png"),
        require("../../assets/pumpopen.png"),
        require("../../assets/pumpopen.png"),
        require("../../assets/pumpopen.png"),
        require("../../assets/pumpopen.png"),
        require("../../assets/pumpopen.png"),
        require("../../assets/pumpopen.png"),
        require("../../assets/pumpopen.png"),
        require("../../assets/pumpopen.png"),
        require("../../assets/pumpopen.png"),
        require("../../assets/pumpopen.png"),
        require("../../assets/pumpopen.png"),
        require("../../assets/pumpopen.png"),
      ],
      img1: [
        require("../../assets/newfansclose.png"),
        require("../../assets/pumpclose.png"),
        require("../../assets/newfansclose.png"),
        require("../../assets/PAMclose.png"),
        require("../../assets/o3close.png"),
        require("../../assets/newfansclose.png"),
        require("../../assets/pumpclose.png"),
        require("../../assets/pumpclose.png"),
        require("../../assets/pumpclose.png"),
        require("../../assets/pumpclose.png"),
        require("../../assets/pumpclose.png"),
        require("../../assets/pumpclose.png"),
        require("../../assets/pumpclose.png"),
        require("../../assets/pumpclose.png"),
        require("../../assets/pumpclose.png"),
        require("../../assets/pumpclose.png"),
        require("../../assets/pumpclose.png"),
        require("../../assets/pumpclose.png"),
      ],
      pamIcon: require("../../assets/PACopen.png"),
      pamIcon1: require("../../assets/PACclose.png"),
      styleIcon: [
        { width: "81px", height: "81px" },
        { width: "72px", height: "88px" },
        { width: "81px", height: "81px" },
        { width: "76px", height: "88px" },
        { width: "100px", height: "58px" },
        { width: "81px", height: "81px" },
        { width: "72px", height: "88px" },
      ],
      styleIconChange: [
        { width: "81px", height: "81px" },
        { width: "72px", height: "88px" },
        { width: "81px", height: "81px" },
        { width: "76px", height: "88px" },
        { width: "100px", height: "58px" },
        { width: "81px", height: "81px" },
        { width: "72px", height: "88px" },
      ],
      equipTitle: "",
      facilitiesData: null,
      openIndex: "",
      // index: null,
      currentAllData: null,
      facilityCurrent: null,
      stateCurrent: null,
      slidesPerView: "auto",
      swiperOption: {

        slidesPerView: 4,
        observer: true,
        observeParents: true,
        freeMode: false,
        spaceBetween: 30,
        grabCursor: false,
        slidesOffsetAfter: 20,
        // touchStartPreventDefault: false,

        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          type: "fraction",
        },
        prevButton: "#swiper-top .swiper-button-prev",
        nextButton: "#swiper-top .swiper-button-next",

        navigation: {
          nextEl: "#swiper-top .swiper-button-next",
          prevEl: "#swiper-top .swiper-button-prev",
        },
      },
      timer: null,
    };
  },
  created() {
    // if (this.$route.query.length < 5) {
    //   this.swiperOption.slidesPerView = this.$route.query.length;
    // }
    // // this.$nextTick(() => {
    // //   this.initSwiper();
    // // });
    this.openIndex = parseInt(this.$route.query.index);
    // console.log(this.openIndex);
    if (this.openIndex == 0 || this.openIndex == 2 || this.openIndex == 5) {
      this.justdo = "fc";
    } else {
      this.justdo = "";
    }
    setTimeout(() => {
      this.getEquipData(this.openIndex);
    }, 150);
    this.timer = window.setInterval(async () => {
      setTimeout(await this.getEquipData(this.openIndex), 0);
    }, 300000);
    let theme = localStorage.getItem("theme")
      ? JSON.parse(localStorage.getItem("theme"))
      : "light";
    this.picChange = theme == "light" ? "westeros" : "chalk";
  },
  mounted() {
    // console.log(this.$refs.swiper);
    // this.initSwiper();
  },
  watch: {
    "$store.state.theme"(newVal, oldVal) {
      this.picChange = newVal;
    },
    "$store.state.open"(newVal, oldVal) {
      this.initSwiper();
    },
    "$route.query.index"(newVal, oldVal) {
      console.log(newVal);
      clearInterval(this.timer);
      this.timer = null;
      this.openIndex = newVal;
      this.getEquipData(this.openIndex);
      this.timer = window.setInterval(async () => {
        setTimeout(await this.getEquipData(this.openIndex), 0);
      }, 300000);
      if (this.openIndex == 0 || this.openIndex == 2 || this.openIndex == 5) {
        this.justdo = "fc";
      } else {
        this.justdo = "";
      }
      // this.$nextTick(() => {
      //   this.initSwiper();
      // });
    },
  },
  computed: {
    propertyText() { },
  },
  sockets: {

    facilityData(res) {
      this.currentAllData = JSON.parse(res);
      console.log(this.currentAllData[this.openIndex]);
      // this.facilityCurrent = this.currentAllData.facilities;
      // console.log(this.facilitiesData[0].property[0].value);
      // console.log(this.facilityCurrent[this.openIndex][0][0]);
      for (let i = 0; i < this.facilitiesData.length; i++) {
        for (let j = 0; j < this.facilitiesData[i].property.length; j++) {
          this.facilitiesData[i].property[j].value =
            this.currentAllData[this.openIndex].facilities[i][j];
        }
      }
      // this.stateCurrent = this.currentAllData.states;
      // // console.log(this.stateCurrent);
      for (let i = 0; i < this.facilitiesData.length; i++) {
        this.facilitiesData[i].state = this.currentAllData[this.openIndex].states[i];
      }
    },
  },
  // this.getEquipData(this.openIndex);
  // console.log(this.facilitiesData);

  methods: {

    equipdiolog(item) {
      console.log(item);
      this.equipdiologopen = true;
      this.openitem = item;
    },
    //      
    async getEquipData(index) {
      this.goto = false
      const { data: res } = await this.$http.get("/modules/facilities", {
        params: { index: index },
      });
      console.log(res.data);
      this.goto = true

      this.equipTitle = res.data.result.name;
      this.facilitiesData = res.data.result.facilities;
      this.eqlength = res.data.result.facilities.length
      // console.log(this.facilitiesData);
      this.index = Math.floor(24 / this.facilitiesData.length);
      if (this.facilitiesData.length > 5) {
        this.index = Math.ceil(24 / 5);
      }
      if (this.eqlength < 5) {
        this.swiperOption.slidesPerView = this.eqlength;
      }
      this.$nextTick(() => {
        this.initSwiper()
      })

    },
    //      
    jiangeStyle(i) {
      if (this.facilitiesData.length - 1 === i) {
        return {
          display: "none",
        };
      } else if (this.facilitiesData.length !== "") {
        return {
          marginTop: "10%",
          marginLeft: "113%",
          width: "1px",
          backgroundColor: "rgb(186, 186, 186)",
          height: "110px",
        };
      }
    },
    //     
    initSwiper() {
      // this.goto = true
      console.log(this.swiperOption.slidesPerView);
      this.swiperOption = {

        slidesPerView: 4,
        observer: true,
        observeParents: true,
        freeMode: false,
        spaceBetween: 30,
        grabCursor: false,

        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          type: "fraction",
        },

        navigation: {
          nextEl: "#swiper-top .swiper-button-next",
          prevEl: "#swiper-top .swiper-button-prev",
        },
      };
      console.log(this.swiperOption);
    },


  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  border-radius: 16px;
}

.ozoneContainer {
  width: 100%;
  height: 100%;

  .el-row {
    margin: 0 !important;
  }

  .el-col {
    display: flex;
    flex: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 6px;
    text-align: center;
    padding: 0 !important;
    // margin: 0 10px 0 0;
  }

  .equipMsg {
    display: inline-block;
    // margin-left: 20px;
    width: 100%;
  }

  .equipText {
    display: block;
    text-align: center;
    margin-top: 20px;
    font-weight: 600;

    .p1 {
      text-align: center;
      font-size: 14px;
      white-space: nowrap;
      margin-top: 8px;
      margin-bottom: 0px;
      color: rgb(76, 76, 76);
    }

    .p2 {
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      // white-space: nowrap;
      margin-top: 8px;
      margin-bottom: 0px;
      color: rgb(76, 76, 76);
    }
  }

  .imgbox {
    height: 86px;
    // line-height: 88px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      height: 80%;
    }
  }

  .img1 {
    display: inline-block;
    // width: 76px;
    // height: 88px;
    // margin-bottom: 24px;
  }

  .img2 {
    position: absolute;
  }

  h4 {
    margin-top: 20px;
    margin-left: 8px;
    text-align: left;
    font-size: 16px;
    font-weight: 600;
    color: rgb(77, 74, 74);
  }

  .status {
    // display: block;
    width: 58px;
    height: 6px;
    border-radius: 30px;
    display: inline-block;
    /* margin-right: 8px; */
    margin-bottom: 16px;
    /* background-color: rgba(67, 207, 124, 1); */
  }

  .equipNum {
    display: inline-block;
    width: 60%;
    text-align: center;
    color: black;
    // background-color: #fff;
  }

  .theSwiper {
    width: 100%;
    margin-bottom: 30px;
    overflow: hidden;

    .swiper_card {
      cursor: pointer;
      border-radius: 12px;
      margin-bottom: 15px
    }

    .el_card {
      height: 330px;
      border-radius: 12px;
      text-align: left;

      .black {
        font-size: 18px;
        margin-bottom: 30px
      }

      .minichartbox {
        height: 260px;
      }
    }

    // dis
    .swiper-container {
      position: relative;
      width: 100%;
      height: 100%;

      .swiper-slide {
        // display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // margin: 0 !important;
        color: rgb(255, 255, 255);
        // background-color: rgba(30, 103, 212, 0.116);
        font-size: 16px;
        text-align: center;

        .newfansid {
          width: 50px;
          background-color: rgba(0, 128, 128, 1);
          border-radius: 18px;
          color: #fff;
          padding: 2px;
          position: absolute;
          right: -15px;
        }

        .newfansid1 {
          width: 50px;
          background-color: #cbd4db;
          border-radius: 18px;
          color: #fff;
          padding: 2px;
          position: absolute;
          right: -15px;
        }

        .algo_btn {
          width: 50px;
          // background-color: #cbd4db;
          // border-radius: 18px;
          // color: #fff;
          padding: 2px;
          position: absolute;
          left: 15px;
          z-index: 3;
        }

        .algo_text {
          font-size: 12px;
          color: #cbd4db;
        }
      }
    }
  }

  .dialog_box {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-left: 30px;

    ::v-deep .el-card__body {
      padding: 10px;
      // background-color: powderblue;
    }

    .imgbox {
      height: 60px;
      // line-height: 88px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img {
        height: 100%;
      }
    }

  }

  .fc {
    animation: wm 0.8s infinite linear;
  }

  @keyframes wm {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  .ty {
    animation: wm1 0.4s infinite linear;
  }

  @keyframes wm1 {
    from {
      transform: scale(0.9);
    }

    to {
      transform: scale(1);
    }
  }
}

::v-deep .swiper-button-prev,
.swiper-button-next {
  background-size: 20px 20px;
}

// ::v-deep.el-card__body,
.el-card ::v-deep .el-card__body {
  padding: 20px;
  // background-color: powderblue;
}

.swiperStyle {
  display: none !important;
}
</style>
