<template>
  <div class="containerMain">
    <div class='search'>
      <el-card :class="searchclose ? 'search1_card' : 'search_card'">
        <div style="text-align: center;">
          <el-input placeholder="Search" prefix-icon="el-icon-search" v-model="input2" class="search_input"
            @input="searchsome()">
          </el-input>
        </div>
        <div class="ul_div">
          <ul>
            <li v-for="(item, index) in tables" class="openli" @click="gotoinfo(item)"
              :class="allWarningA[index].length > 0 ? 'mainctr' : ''">
              <span class="openli_name">
                <span class="name_text">
                  {{ item.result.name }}
                </span>
              </span>
              <span class="openspan" v-if="item.result.runbtn">

                ON
                <i class="el-icon-arrow-right"></i>
              </span>
              <span v-else class="closespan">
                <!-- {{ item.result.name }} -->
                <span v-if="item.result.name !== 'Effluent Characteristics'">
                  OFF
                </span>
              </span>
            </li>
          </ul>
        </div>
      </el-card>
    </div>
    <div id="swiper-top" class="swiperbox">
      <div class="theSwiper" ref="swiper">
        <swiper ref="mySwiper" :options="swiperOption" :not-next-tick="notNextTick" class="swiper-container">
          <swiper-slide v-for="(item, index) in inAerationData" :key="item.result.index">
            <div class="maindiv">
              <el-card>
                <div :class="allWarningA[index].length > 0 ? 'mainctr' : 'mainctr1'" @click="gotoDetail(item)">
                  <div class="title_swich">
                    <div>
                      <span style="color: rgba(242, 242, 242, 1)">{{ item.result.name }}
                      </span>
                    </div>
                    <div class="statusdes" v-if="item.result.warning.index >= 0">
                      {{ item.result.operate_status.des }}
                      <span v-if="item.result.operate_status.status < 6">
                        <span class="status_color" style="background-color:#D43030;"
                          v-if="item.result.operate_status.status == 2"></span>
                        <span class="status_color" style="background-color:#FA9600;"
                          v-if="item.result.operate_status.status == 3"></span>
                        <span class="status_color" style="background-color:#498AF4;"
                          v-if="item.result.operate_status.status == 4"></span>
                        <span class="status_color" style="background-color:rgba(64, 224, 208, 1);"
                          v-if="item.result.operate_status.status == 5"></span>
                      </span>
                    </div>
                    <el-switch v-model="item.result.runbtn" v-show="item.result.index !== 7"
                      active-color="rgba(64, 224, 208, 1)" inactive-color="#b8b8b8" :active-value="true"
                      :inactive-value="false" active-text="ON" inactive-text="OFF"
                      @change="runBtn(index, item.result.index)" @click.native.stop></el-switch>
                  </div>
                  <div class="warningc" v-if="item.result.warning.index >= 0">
                    <i class="el-icon-warning" style="color: #D43030;"> </i>
                    {{ item.result.warning.warningdes }}
                  </div>
                  <div class="statusdes" v-else>
                    {{ item.result.operate_status.des }}
                    <!-- {{ item.result.operate_status.status }} -->
                    <span v-if="item.result.operate_status.status < 6">
                      <span class="status_color" style="background-color:rgba(168, 168, 168, 1);"
                        v-if="item.result.operate_status.status == 1"></span>
                      <span class="status_color" style="background-color:#D43030;"
                        v-if="item.result.operate_status.status == 2"></span>
                      <span class="status_color" style="background-color:#FA9600;"
                        v-if="item.result.operate_status.status == 3"></span>
                      <span class="status_color" style="background-color:#498AF4;"
                        v-if="item.result.operate_status.status == 4"></span>
                      <span class="status_color" style="background-color:rgba(64, 224, 208, 1);"
                        v-if="item.result.operate_status.status == 5"></span>
                    </span>
                  </div>
                </div>
              </el-card>
              <el-card class="el_card">

                <div class="maintable" v-for="items in item.result.table" @click="gotoDetail(item)">
                  <div style="flex:5;">

                    <p style="margin: 0; margin-bottom: 2px; color: #4d4a4a">
                      {{ items.visible }}
                    </p>
                    <div>

                      <span style="font-szie: 14px; color: #878787">{{ items.unit }}</span>
                    </div>
                  </div>
                  <div style="flex: 2;">
                    <p style="margin: 0; margin-bottom: 2px; color: #4d4a4a">
                      Current Value
                    </p>
                    <span class="table_size">{{ items.current
                      }}</span>
                  </div>
                  <div style="flex: 1;" v-show="item.result.name == 'Effluent Parameters'">
                  </div>
                </div>
                <div class="warningtext1" v-show="item.result.table.length == 0">
                  <img style="margin-top: 50px" src="../../assets/noData.gif" alt="" />
                </div>
              </el-card>
            </div>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
          <div slot="button-prev" :class="inAerationData.length <= 4 ? 'swiperStyle' : 'swiper-button-prev'
        ">
          </div>
          <div slot="button-next" :class="inAerationData.length <= 4 ? 'swiperStyle' : 'swiper-button-next'
        ">
          </div>
        </swiper>

      </div>
    </div>

  </div>
</template>

<script>
import countTo from "vue-count-to";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
// import SimModule from "@/components/SimModule/index.vue";

export default {
  components: {
    countTo,

    swiper,
    swiperSlide,
    // SimModule,
  },
  data() {
    return {
      textarea: null,

      reason: null,
      checkList: [],
      dialogVisible: false,
      tables: null,
      input2: null,
      addSheet: null,
      allWarningA: [],
      direction: "btt",
      inAerationData: {},
      allCurrent: null,
      refreshData: null,
      addonData: null,
      allWarningData: null,
      currentUser: "",
      drawer: false,
      choselist: [],
      itemData: [],
      soureOpen: [],
      slidesPerView: "auto",
      notNextTick: true,
      swiperOption: {

        slidesPerView: 3,
        observer: true,
        observeParents: true,
        freeMode: false,
        spaceBetween: 0,
        // grabCursor: true, //true：
        mousewheel: false,
        // scrollbar: '.swiper-scrollbar',
        // effect: "coverflow",
        // touchStartPreventDefault: false,

        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        prevButton: "#swiper-top .swiper-button-prev",
        nextButton: "#swiper-top .swiper-button-next",

        navigation: {
          nextEl: "#swiper-top .swiper-button-next",
          prevEl: "#swiper-top .swiper-button-prev",
        },
      },
      searchclose: false,
      myswiper: null,
      warningaindex: 0,
      changeindex: null
    };
  },
  created() {
    this.getIntelligentAerationData();
    this.$router.path = "/AI/control";
    this.currentUser = window.sessionStorage.getItem("username");
    console.log(this.currentUser);
    if (this.inAerationData.length < 5) {
      this.swiperOption.slidesPerView = this.inAerationData.length;
    }
  },
  sockets: {
    connect() {
      console.log("socket connected");
    },
    data(res) {
      this.allCurrent = JSON.parse(res);
      // console.log(this.allCurrent);
      this.refreshData = this.allCurrent.refresh;
      this.addonData = this.allCurrent.addon;
      console.log(this.inAerationData);
      console.log(this.refreshData);
      for (let k = 0; k < this.inAerationData.length; k++) {
        for (let j = 0; j < this.inAerationData[k].result.table.length; j++) {
          this.inAerationData[k].result.table[j].current = this.refreshData[this.inAerationData[k].result.index][j]
        }
      }
      console.log(this.inAerationData);
    },
    warningAB(res) {
      this.allWarningA = []
      this.warningABCurrent = JSON.parse(res);
      console.log(this.warningABCurrent);
      // if (this.warningABCurrent.B != []) {
      //   this.warningB = this.warningABCurrent.B;
      // }
      if (this.warningABCurrent.A != []) {
        // console.log(12312321321);
        this.warningABCurrent.A = []
        // this.warningA = this.warningABCurrent.A;
        for (let a = 0; a < this.inAerationData.length; a++) {
          let arr = []
          // console.log(this.warningABCurrent.A);

          for (let b = 0; b < this.warningABCurrent.A.length; b++) {
            // console.log(this.warningABCurrent.A);
            // const element = array[b];
            if (this.inAerationData[a].result.index === this.warningABCurrent.A[b].index) {
              arr.push(this.warningABCurrent.A[b])

            }
          }
          // console.log(arr);
          this.allWarningA.push(arr)
        }
      }

      console.log(this.allWarningA);
    },

    warningData(res) {
      this.allWarningData = JSON.parse(res);
      console.log(this.inAerationData);
      console.log(this.allWarningData);
      for (let key in this.allWarningData) {
        // console.log(this.allWarningData[key].length)
        if (this.allWarningData[key].length > 0) {
          for (let a = 0; a < this.inAerationData.length; a++) {
            // console.log(key, this.inAerationData[a].result.index);
            if (key == this.inAerationData[a].result.index) {
              this.tables[a].result.warning = this.allWarningData[key][0]

              this.inAerationData[a].result.warning = this.allWarningData[key][0]
            }
          }
        }
      }
      console.log(this.inAerationData);
    },
  },
  computed: {
    swiper() {
      // console.log(this.$refs.mySwiper.swiper);
      return this.$refs.mySwiper.swiper
    },

  },

  mounted() {

    this.getchoselist();
    this.initSwiper();
    this.swiper.slideTo(3, 1000, false)
    // this.myswiper = new swiper('.swiper-container', {})
    // console.log(this.myswiper);
    // socket.on("data", (res) => {
    //   console.log(res);
    // });
  },
  // watch: {
  // },
  methods: {

    handleClose() {
      this.dialogVisible = false
    },
    async getmodeltype() {
      const { data: res } = await this.$http.get("/modules/init", {
        params: { index: index },
      });
    },
    initSwiper() {
      this.swiperOption = {
        slidesPerView: 4,
        observer: true,
        observeParents: true,
        freeMode: false,
        spaceBetween: 30,
        mousewheel: true,
        grabCursor: false,

        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          type: "fraction",
        },
        prevButton: "#swiper-top .swiper-button-prev",
        nextButton: "#swiper-top .swiper-button-next",
        navigation: {
          nextEl: "#swiper-top .swiper-button-next",
          prevEl: "#swiper-top .swiper-button-prev",
        },
      };
    },
    enter() {
      this.mySwiper.autoplay.stop();
    },
    leave() {
      this.mySwiper.autoplay.start();
    },
    async getIntelligentAerationData() {
      const { data: res } = await this.$http.get("modules/initall");
      if (res.meta.status !== 200) {
        return this.$message.error("error");
      }
      this.tables = res.data.result;
      this.inAerationData = res.data.result;

      for (let a = 0; a < this.inAerationData.length; a++) {
        let arr = [];
        this.allWarningA.push(arr);
      }
    },
    async runBtn(index, index2) {
      // even.preventDefault();
      console.log(index);
      console.log(index2);
      this.changeindex = index2
      console.log(this.inAerationData);
      if (this.inAerationData[index].result.runbtn == true) {
        this.$confirm(
          "Enabled",
          {
            distinguishCancelAndClose: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            type: 'warning',
            customClass: "msgbox",
          }
        ).then(async (action) => {
          const { data: res1 } = await this.$http.post("modules/control", {
            index: index2,
            flag: true,
          });
          if (res1.meta.status == 403) {
            this.getIntelligentAerationData();
            return this.$message.error(res1.meta.msg);
          }
          if (res1.meta.status == 200) {
            this.getIntelligentAerationData();
            return this.$message.success(res1.meta.msg);
          } else {
            this.getIntelligentAerationData();
            return this.$message.error(res1.meta.msg);
          }
        })
          .catch(async (action) => {
            this.getIntelligentAerationData();
            return this.$message.error("Cancel The Operation");
          });
      } else if (this.inAerationData[index].result.runbtn == false) {
        // this.dialogVisible = true

        this.$confirm(
          "Shut Down",
          {
            distinguishCancelAndClose: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            type: 'warning',
            customClass: "msgbox",
          }
        ).then(async (action) => {
          const { data: res2 } = await this.$http.post("modules/control", {
            index: index2,
            flag: false,
          });
          if (res2.meta.status == 403) {
            this.getIntelligentAerationData();

            return this.$message.error(res2.meta.msg);
          }
          if (res2.meta.status == 200) {
            this.getIntelligentAerationData();

            return this.$message.success(res2.meta.msg);
          } else {
            this.getIntelligentAerationData();
            return this.$message.error(res2.meta.msg);
          }
        })
          .catch(async (action) => {
            this.getIntelligentAerationData();
            return this.$message.error("Cancel The Operation");
          });

      }
      this.getIntelligentAerationData();
      // this.inAerationData = res.data.result;
      // console.log(this.inAerationData[0].result);
    },
    runStyle(index) {
      if (this.inAerationData[index].result.run == true) {
        return { color: "#60C6AA" };
      } else {
        return { color: "#C65146" };
      }
    },
    async autoStatus(index) {
      console.log(this.inAerationData[index].result.deeply);
      if (this.inAerationData[index].result.deeply == true) {
        const { data: res1 } = await this.$http.post("modules/auto", {
          index: index,
          flag: false,
        });
        if (res1.meta.status == 403) {
          this.getIntelligentAerationData();
          return this.$message.error(res1.meta.msg);
        }
        if (res1.meta.status == 200) {
          this.$message.success("success");
        }
        console.log(res1);
      } else if (this.inAerationData[index].result.deeply == false) {
        const { data: res2 } = await this.$http.post("modules/auto", {
          index: index,
          flag: true,
        });
        console.log(res2);
        if (res2.meta.status == 403) {
          this.getIntelligentAerationData();
          return this.$message.error(res2.meta.msg);
        }
        if (res2.meta.status == 200) {
          this.$message.success("success");
        }
      }
      const { data: res } = await this.$http.get("modules/initall");
      if (res.meta.status !== 200) {
        return this.$message.error("error");
      }
      this.inAerationData = res.data.result;
    },
    autoStyle(index) {
      if (this.inAerationData[index].result.deeply == true) {
        return { backgroundColor: "#1890ff", color: "#fff" };
      } else {
        return { backgroundColor: "#b8b8b8", color: "#fff" };
      }
    },
    cbrBtnStyle(i, index, id) {
      if (this.inAerationData[i].result.pool[index].flag == true) {
        return { backgroundColor: "#1890ff", color: "fff" };
      } else {
        return { backgroundColor: "#b8b8b8", color: "fff" };
      }
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
    },

    enterBlur(event) {
      event.target.blur();
    },
    async cbrBtn(i, index, id) {
      id = this.inAerationData[i].result.pool[index].id;
      // console.log(i);
      // console.log(id);
      if (this.inAerationData[i].result.pool[index].flag == true) {
        const { data: res1 } = await this.$http.post("modules/pool", {
          index: i,
          id: id,
          flag: false,
        });
        // console.log(res1);
      } else if (this.inAerationData[i].result.pool[index].flag == false) {
        const { data: res2 } = await this.$http.post("modules/pool", {
          index: i,
          id: id,
          flag: true,
        });
        // console.log(res2);
      }
      const { data: res } = await this.$http.get("modules/initall");
      if (res.meta.status !== 200) {
        return this.$message.error("error");
      }
      this.inAerationData = res.data.result;
    },

    gotoAlert(id, index, activePath) {
      // console.log(index);
      this.$router.push({
        path: "/alertDetail",
        query: { id: id, index: index },
      });
    },
    async gotoDetail(item) {
      if (item.result.index !== 7) {
        console.log(item);
        const { data: res } = await this.$http.get("/modules/facilities", {
          params: { index: item.result.index },
        });
        const { data: res1 } = await this.$http.get("/modules/chart", {
          params: { index: item.result.index },
        });
        const length = res.data.result.facilities.length;
        // const openCharts = res1.data.result.length;
        // console.log(length);
        const openCharts = res1.data.result.length;
        // console.log(openCharts);
        this.$router.push({
          path: "/openMore",
          query: { index: item.result.index, length: length },
        });
      }
      // console.log(index);
    },
    viewallDetails() {
      this.drawer = true;
      this.soureOpen = [];
    },
    viewDetails(row) {
      console.log(row);
      console.log(row.visible);
      console.log(row.tag[0]);
      this.drawer = true;
      const soureTable2 = [];
      const statusNow = new Date();
      const nowStartDate = {
        year: statusNow.getFullYear(),
        month:
          statusNow.getMonth() + 1 < 10
            ? "0" + (statusNow.getMonth() + 1)
            : statusNow.getMonth() + 1,
        date:
          statusNow.getDate() < 10
            ? "0" + statusNow.getDate()
            : statusNow.getDate(),
      };
      console.log(nowStartDate.month);
      const nowStartDate1 =
        nowStartDate.year + "-" + nowStartDate.month + "-" + nowStartDate.date;
      console.log(nowStartDate1);

      let time = new Date().getTime() - 24 * 60 * 60 * 1000;
      let yy = new Date(time).getFullYear();
      let mm =
        new Date(time).getMonth() + 1 < 10
          ? "0" + (new Date(time).getMonth() + 1)
          : new Date(time).getMonth() + 1;

      let dd =
        new Date(time).getDate() < 10
          ? "0" + new Date(time).getDate()
          : new Date(time).getDate();

      let hh = new Date(time).getHours();

      let mf =
        new Date(time).getMinutes() < 10
          ? "0" + new Date(time).getMinutes()
          : new Date(time).getMinutes();

      let ss =
        new Date(time).getSeconds() < 10
          ? "0" + new Date(time).getSeconds()
          : new Date(time).getSeconds();
      let beforeDay = yy + "-" + mm + "-" + dd;
      console.log(yy + "-" + mm + "-" + dd);
      console.log(beforeDay);
      soureTable2[0] = {
        name: row.visible,
        tag: row.tag[0],
        starttime: beforeDay,
        endtime: nowStartDate1,
        extre: [],
        eaxis: [],
        compare: [],
        frq: "",
      };
      console.log(soureTable2);
      this.soureOpen = soureTable2;
      console.log(this.soureOpen);
      // this.itemData = [];
    },
    changeDrawer(v) {
      console.log(v, "vvv");
      this.drawer = v;
    },
    async getchoselist() {
      const { data: res } = await this.$http.get("datacenter/sources");
      this.choselist = res.source;
      console.log(this.choselist);
    },
    searchsome() {
      const input = this.input2;
      // this.currentPage = 1;
      console.log(input);

      let newListData = [];
      if (input) {
        this.inAerationData.filter((item) => {
          if (item.result.name.indexOf(input) !== -1) {
            // console.log(item);
            newListData.push(item);
          }
        });
        this.tables = newListData;
      } else {
        this.tables = this.inAerationData;
      }
    },
    async gotoinfo(item) {
      if (item.result.index !== 7) {
        console.log(item);
        const { data: res } = await this.$http.get("/modules/facilities", {
          params: { index: item.result.index },
        });
        const { data: res1 } = await this.$http.get("/modules/chart", {
          params: { index: item.result.index },
        });
        const length = res.data.result.facilities.length;
        // const openCharts = res1.data.result.length;
        // console.log(length);
        const openCharts = res1.data.result.length;
        // console.log(openCharts);
        this.$router.push({
          path: "/openMore",
          query: { index: item.result.index, length: length },
        });
      }
    },
    // closesearch() {
    //   if (this.searchclose) {
    //     this.searchclose = false

    //     console.log(132131);

    //   } else {
    //     this.swiperOption.slidesPerView = 4
    //     this.$refs.mySwiper.swiper.update()
    //     console.log(this.$refs.mySwiper.swiper);
    //     this.searchclose = true
    //   }
    // }
    appearance(i, id) {
      // console.log('appearance22222222', '+', this.warningaindex);

      this.$confirm(
        this.warningABCurrent.AI[this.warningaindex].warningdes,
        "appearance_warning",
        {
          distinguishCancelAndClose: true,
          confirmButtonText: "YES",
          cancelButtonText: "NO",
          type: "error",
          customClass: "msgbox",
        }
      )
        .then(async (action) => {
          this.warningaindex = 0;
          if (action === "confirm") {
            const { data: res } = await this.$http.post("/aiwarning/deal", {
              id: this.warningABCurrent.AI[this.warningABCurrent.AI.length - 1].id,
              deal: 1,
            });
            console.log(res);
            if (res.meta.status !== 200) {
              return this.$message.error("error");
            }
            if (res.meta.status == 200) {
              return this.$message.success(res.meta.msg);
            }
          }
        })
        .catch(async (action) => {
          this.warningaindex += 1;
          console.log(13232434343);
          console.log(this.warningaindex);
          console.log(this.warningABCurrent.AI.length);
          // this.warningaindex = 0;
          console.log(this.warningaindex < this.warningABCurrent.AI.length);
          if (this.warningaindex < this.warningABCurrent.AI.length) {
            this.appearance();
          } else {
            console.log('0090900');
            this.warningaindex = 0;

          }
          if (action === "cancel") {
            const { data: res } = await this.$http.post("/aiwarning/deal", {
              id: this.warningABCurrent.AI[this.warningABCurrent.AI.length - 1].id,
              deal: 0,
            });
            console.log(res);
            if (res.meta.status !== 200) {
              return this.$message.error("error");
            }
            if (res.meta.status == 200) {
              return this.$message.success(res.meta.msg);
            }
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table {
  width: 100%;

  .el-table__header-wrapper table,
  .el-table__body-wrapper table {
    width: 100% !important;
  }

  .el-table__header-wrapper table,
  .el-table__body-wrapper table {
    width: 100% !important;
  }

  .el-table__body,
  .el-table__footer,
  .el-table__header {
    table-layout: auto !important;
  }

  .el-table__body-wrapper::-webkit-scrollbar {

    // width: 0px;
    background-color: #fff;
  }

  .el-table__body-wrapper::-webkit-scrollbar-thumb {
    border-radius: 1px;
    height: 0px;
    background: #fff;
  }

  .el-table__body-wrapper::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    border-radius: 3px;
    background: #fff;
  }
}

.containerMain {
  // height: 1200px;
  overflow-x: hidden;
  // overflow-y: auto;
  display: flex;
  // grid-template-columns: 1fr 1fr;
  // overflow: auto;
  position: relative;
  width: 100%;

  .search1 {
    width: 0;
  }

  .search {
    position: relative;
    // width: 350px;

    .el-icon-caret-left {
      position: absolute;
      right: 5%;
      top: 50%;
    }

    .el-icon-caret-right {
      position: absolute;
      right: 5%;
      top: 50%;
    }

    .search1_card {
      width: 0px;

    }

    .search_card {

      width: 550px;
      margin-top: 32px;
      height: 548px;
      // overflow: scroll;
      box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.05);

      .search_input {
        margin-top: 40px;
        margin-bottom: 20px;
        height: 40px;
        width: 450px;

        ::v-deep.el-input__inner {
          height: 40px;
          width: 450px;
          border-radius: 40px !important;

        }

        ::v-deep .el-input__icon {
          height: 100%;
          width: 25px;
          text-align: center;
          transition: all .3s;
          line-height: 42px;
          color: rgba(64, 224, 208, 1);
        }
      }
    }

    // overflow: scroll;
    ul {
      overflow-y: scroll;
      padding-right: 40px;
      padding-left: 40px;
      margin-top: 0px;
    }

    .ul_div {
      overflow: scroll;
      height: 450px;
      cursor: pointer;

    }

    .mainctr {
      background: linear-gradient(to right, #ee5a3d, #fff, #ee5a3d);
      transition: background 0.5s ease-in-out;
      background-size: 200% 200%;
      animation: gradientBG 5s ease infinite;

      @keyframes gradientBG {
        0% {
          background-position: 100%;
        }

        50% {
          background-position: 0%;
        }

        100% {
          background-position: -100%;
        }
      }

      span {
        font-size: 18px;
        font-weight: 600;
        color: #4d4a4a;
        margin-left: 10px;
      }
    }

    .openli {
      font-size: 15px;
      color: rgba(242, 242, 242, 1);
      list-style: none;
      height: 60px;
      // text-align: center;
      vertical-align: middle;
      // line-height: 60px;
      border-bottom: 1px solid rgba(56, 56, 56, 1);
      display: flex;

      flex-direction: row;
      align-items: center;

      .openli_name {
        display: inline-block;
        width: 500px;
        word-wrap: break-word;
        word-break: normal;

        .name_text {
          display: inline-block;
          width: 350px;
        }
      }

      .openspan {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 15px;
        float: right;
        margin-right: 50px;
        color: rgba(64, 224, 208, 1) !important
      }

      .closespan {
        float: right;
        margin-right: 60px;
        font-size: 15px;
        color: rgba(168, 168, 168, 1) !important;
        // color: rgba(73, 138, 244, 1);
      }
    }

    .sim-container {
      position: absolute;
      top: 30px;
      right: -30px;
      z-index: 99;
    }
  }


  .swiperbox {
    width: 100%;
    margin-right: 24px;
    overflow: hidden;

  }

  // background: #4d4a4a;
  .theSwiper {
    // flex: 3;
    width: 100%;
    // margin-top: 20px;
    margin-bottom: 24px;
    overflow: hidden;

    .swiper-container {
      position: relative;
      width: 100%;
      overflow: visible;
      // height: 100%;

      .swiper-slide {
        overflow: visible;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // margin: 0 !important;
        color: rgb(255, 255, 255);
        // background-color: rgba(30, 103, 212, 0.116);
        font-size: 16px;
        // text-align: center;
      }

      .swiper-button-prev {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l4.2%2C4.2L8.4%2C22l17.8%2C17.8L22%2C44L0%2C22z'%20fill%3D'%2340e0d0'%2F%3E%3C%2Fsvg%3E");

      }

      .swiper-button-next {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L5%2C44l-4.2-4.2L18.6%2C22L0.8%2C4.2L5%2C0z'%20fill%3D'%2340e0d0'%2F%3E%3C%2Fsvg%3E");

      }

      .swiper-button-prev,
      .swiper-button-next {
        margin-top: -12px;
        background-size: 20px 20px;
        color: #FFFF00 !important;

      }

      .swiperStyle {
        display: none !important;
      }

      .maindiv {
        width: 100%;
        overflow: visible;
        margin-top: 32px;

        .el_card {
          height: 430px;
          cursor: pointer;
        }

        // margin-bottom: 700px;
        .el-card {
          overflow: scroll;
          border-radius: 16px;
          margin-bottom: 16px;
          box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.05);

          .warningc {
            width: 500px;
            overflow: hidden;
            padding-bottom: 20px;
            margin-left: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            // padding: 10px 46px;
            // .status_color {
            //   display: inline-block;
            //   width: 10px;
            //   height: 10px;
            //   border-radius: 5px;
            //   background-color: red;
            //   // color: #ee5a3d;
            // }
          }

          .statusdes {
            padding-bottom: 20px;
            margin-left: 10px;

            // padding: 10px 46px;
            .status_color {
              display: inline-block;
              width: 10px;
              height: 10px;
              border-radius: 5px;
              background-color: red;
              // color: #ee5a3d;
            }
          }

          // fans
          // margin-top: 20px;
          .newfans {
            position: absolute;
            // width: 100px;
            // height: 100px;
            // background-color: red;
            margin-top: 260px;
            right: 50px;

            img {
              width: 100%;
            }
          }

          .title_swich {
            height: 60px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: rgba(242, 242, 242, 1) !important;

            .statusdes {
              padding-bottom: 0px;
              margin-left: 0px;

              // padding: 10px 46px;
              .status_color {
                display: inline-block;
                width: 10px;
                height: 10px;
                border-radius: 5px;
                background-color: red;
                // color: #ee5a3d;
              }
            }
          }

          .mainctr {
            padding: 0 36px;
            // height: 60px;
            // background: linear-gradient(#fff, #ee7752);
            background: linear-gradient(to right, #ee5a3d, #fff, #ee5a3d);
            transition: background 0.5s ease-in-out;

            // background: linear-gradient(-120deg, #5aebef, #d3faf4, #ddf8ff, #d9f5e8);
            // background: linear-gradient(to right, blue, pink);

            background-size: 200% 200%;

            animation: gradientBG 5s ease infinite;

            @keyframes gradientBG {
              0% {
                background-position: 100%;
              }

              50% {
                background-position: 0%;
              }

              100% {
                background-position: -100%;
              }
            }

            span {
              font-size: 18px;
              font-weight: 600;
              color: #4d4a4a;
              margin-left: 10px;
            }
          }

          .mainctr1 {
            // display: flex;
            // justify-content: space-between;
            // align-items: center;
            padding: 0 36px;
            // height: 60px;

            span {
              font-size: 18px;
              font-weight: 600;
              color: #4d4a4a;
              margin-left: 10px;
            }
          }


          .maintable {
            margin: 22px 30px;
            height: 60px;
            // background: #4d4a4a;
            box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0px 60px;

            .table_size {
              font-size: 18px;
              color: #498af4;
              margin-right: 6px
            }

            // padding-bottom: 2px;
            span {
              font-size: 14px;
              color: #4d4a4a;
            }

            .mainData {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 0px 60px;

              span {
                font-size: 14px;
                color: #4d4a4a;
              }
            }
          }
        }
      }
    }

    // .swiper-slide:hover {
    //   // box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
    //   transform: scale(1.01);
    //   transition-duration: 0.5s;
    //   overflow: visible;
    // }
  }

  ::v-deep .el-switch {
    .el-switch__label * {
      line-height: 1;
      font-size: 12px;
      display: inline-block;
    }

    .el-switch__label {
      position: absolute;
      display: none;
      color: #fff !important;
      font-size: 12px !important;
    }


    .el-switch__label--right {
      z-index: 1;
      right: 22px;
    }


    .el-switch__label--left {
      z-index: 1;
      left: 22px;
    }

    .el-switch__label.is-active {
      display: block;
    }

  }

  ::v-deep .el-switch .el-switch__core,
  .el-switch .el-switch__label {
    width: 54px !important;
  }

  .righticon {
    position: absolute;
    right: 0;
    bottom: 10%;
    font-size: 25px;
  }

  .el-card {
    border-radius: 20px;
    // text-align: center;
    border: none;
    margin-left: 40px;
    margin-bottom: 40px;
    margin-right: 16px;
    // width: 340px;
    // height: 430px;
    opacity: 1;
    // background: rgba(255, 255, 255, 1);
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.15);

    h3 {
      margin-top: 10px;
      color: #bbbbbb;
    }

    .title {
      margin: 26px 0px 26px 30px;
      height: 27px;
      font-size: 18px;
      font-weight: 700;
      letter-spacing: 1px;
      line-height: 27px;
      // color: rgb(56, 56, 56);
      text-align: left;
      display: flex;
      justify-content: space-between;

      img {
        // display: flex;
        // justify-content: space-between;
        // float: right;
        margin: 3px 20px 0px 0px;
        height: 18px;
        width: 18px;
        cursor: pointer;
      }
    }

    .switch {
      margin-bottom: 25px;

      .Pulse {
        margin-left: 62px;
      }
    }

    .cbrBtn {
      margin-bottom: 15px;
    }

    .warningtext {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 38px;
      background: rgba(229, 229, 229, 1);
      font-size: 13px;
      text-align: center;
      cursor: pointer;

      img {
        height: 18px;
        width: 18px;
        margin-right: 10px;
        color: aqua;
      }
    }

    .warningtext1 {
      // display: flex;
      // justify-content: center;
      // align-items: center;
      width: 100%;
      height: 38px;
      // background: rgba(229, 229, 229, 1);
      font-size: 13px;
      text-align: center;

      p {
        font-size: 17px;
        color: #a88247;
        margin: 0;
        margin-top: -10px;
      }

      // cursor: pointer;
      img {
        margin-top: -20px;
        height: 280px;
        width: 280px;
        // margin-right: 10px;
      }
    }

    .allTable {
      // display: inline-block;
      // margin: 0 auto;
      // width: 100%;
      text-align: center;
      overflow: hidden;

      // width: 350px;
      // height: 424px;
      .tableData {
        float: left;
        margin: 0 auto;
        // margin-bottom: 16px;
        margin-right: 10px;
        border-radius: 8px;
        width: 100%;
        // overflow: hidden;
      }

      // .tableData1 {
      //   float: left;
      //   width: 34%;
      //   margin: 0 auto;
      //   margin-left: 4px;
      //   // overflow: hidden;
      // }
      .tableData2 {
        margin: 0 auto;
        // display: inline-flex;
        // margin-top: 2px;
      }

      .el-table {
        margin: 0 auto;
        border-radius: 20px;
      }
    }
  }
}

::-webkit-scrollbar {
  display: none;
}

::v-deep .el-card__body {
  padding: 0;
}

::v-deep .el-table,
::v-deep .el-table__expanded-cell {
  background-color: transparent !important;
  color: #a88247;
  border-radius: 6px;
}

.el-table::before {
  height: 0;
}

h2 {
  margin-top: 10px;
  color: #bbbbbb;
}

::v-deep .el-input__inner {
  height: 20px;
  // background-color: #1f222b;
  color: #a88247 !important;
  // border: 0 !important;
  text-align: center;
}

::v-deep .el-input__inner:focus {
  border: 1px solid #a88247 !important;
}

.el-input.is-disabled ::v-deep .el-input__inner {
  // background-color: #1f222b;
  color: #626262;
}

.el-button {
  border: none !important;
  margin-left: 30px !important;
}

.noData {
  margin-top: 80px;

  p {
    color: #a88247;
  }
}

.reasonitem {
  margin-bottom: 10px;
}

// .swiper-scrollbar {
//   width: 800px;
// }
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal>.swiper-pagination-bullets {

  left: 90%;
  margin-top: 200px;
  bottom: -14px;
  transform: translate(-50%, -50%);
}

// .swiper-pagination-bullet {
//   background: #727070;
// }

// .swiper-pagination-bullet-active{}</style>
<style type="text/css">
.swiper-pagination-bullet-active {
  width: 24px;
  /* height: 6px; */
  background: #727070;
  opacity: 0.5;
  border-radius: 10px;
}

.swiper-pagination-bullet {
  background: #ffffff;
}

.swiper-pagination-bullet {
  /* background: #007aff; */
}
</style>