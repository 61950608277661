<template>
  <div class="chart-wrap">
    <div ref="chartRef" class="pie-chart" :style="`width: 100%; height: ${typeof height === 'number' ? height + 'px' : height
      }`"></div>
  </div>
</template>

<script>
let pieChart = null;
export default {
  props: {
    height: {
      type: [Number, String],
      default: "300px",
    },
    chartTitle: String,
    chartYName: String,
    yAxisOptions: {
      type: Array,
    },
    chartOptions: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    chartOptions: {
      handler() {
        this.setChartOptions();
        pieChart.resize();
      },
      deep: true,
    },
  },
  data() {
    return {};
  },
  mounted() {
    const t = this;
    this.setChartOptions();
    pieChart.on("dataZoom", function (value) {
      t.$emit("onChartDataZoom");
    });

    window.addEventListener("resize", () => {
      const currentChart = this.$echarts.init(this.$refs.chartRef);
      currentChart.resize();
    });
  },
  methods: {
    setChartOptions() {
      pieChart = this.$echarts.init(this.$refs.chartRef);
      pieChart &&
        pieChart.setOption({
          tooltip: {
            trigger: "item",
            formatter: "{b}: {c} ({d}%)",
          },
          // backgroundColor: "#ffffff",
          title: [
            {
              text: "",
              left: "49%",
              top: "46%",
              textAlign: "center",
              textBaseline: "middle",
              textStyle: {
                color: "red",
                fontWeight: "normal",
                fontSize: 20,
              },
            },
            {
              text: "",
              left: "49%",
              top: "51%",
              textAlign: "center",
              textBaseline: "middle",
              textStyle: {
                color: "red",
                fontWeight: "normal",
                fontSize: 40,
              },
            },

          ],
          color: ['rgba(64, 224, 208, 1)', 'rgba(255, 99, 71, 1)'],
          series: [
            {
              hoverAnimation: false,
              radius: [100, 150],
              name: "pie",
              type: "pie",
              selectedMode: "single",
              selectedOffset: 16,
              clockwise: true,
              startAngle: 90,
              // label: {
              //   // normal: {
              //   //   show: false,
              //   // },
              //   color: "#ffffff",
              // },
              labelLine: {
                normal: {
                  show: false,
                },
              },
              itemStyle: {
                normal: {
                  borderWidth: 3,
                  borderColor: "#ffffff",
                },
                emphasis: {
                  borderWidth: 0,
                  shadowBlur: 5,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.2)",
                },
              },
              data: [],
            },
          ],
          ...this.$props.chartOptions,
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.chart-wrap {
  // background-color: red;
  width: 100%;
  height: 100%;
}
</style>
