<template>
  <div class="container">
    <el-row :gutter="38">
      <el-col :span="6" v-if="show">
        <div class="left">
          <control-open style="width: 100%; height: 100%" :moduleCode="allControlData.module"
            @closedrawer="closedrawer"></control-open>
        </div>
      </el-col>
      <el-col :span="6" v-if="!show">
        <div class="left">
          <control-open style="width: 100%; height: 100%" :moduleCode="allControlData.module"
            @closedrawer="closedrawer"></control-open>
        </div>
      </el-col>
      <el-col :span="3" v-if="!show">
        <div class="SOMEleft">
          <!-- <Openinput style="width: 100%; height: 100%" @closedrawer="closedrawer"></Openinput> -->
        </div>
      </el-col>
      <el-col :span="13" v-if="show">
        <div class="center">
          <equipMent style="width: 100%; height: 100%" />
        </div>
      </el-col>
      <el-col :span="10" v-if="!show">
        <div class="center">
          <equipMent style="width: 100%; height: 100%" />
        </div>
      </el-col>
      <el-col :span="5">
        <div class="openright">
          <aistart />
        </div>
      </el-col>

    </el-row>
    <el-row :gutter="38">
      <el-col :span="19">
        <div>
          <openchart></openchart>
        </div>
      </el-col>

      <el-col :span="5">
        <div class="openright">
          <div>
            <warnIndex class="right" />
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import equipMent from "/src/components/equipmentStatus/equipMent";
import warnIndex from "/src/components/warnCpn/warnIndex";
import ControlOpen from "@/components/controlCpn/controlOpen.vue";
// import Openinput from "@/components/controlCpn/Openinput.vue";

// import oSimulation from "@/components/o3ChartCpn.vue/oSimulation.vue";

import aistart from "../../components/component/aistart";
import openchart from "../../components/component/openchart";
// import floatcpn from "../../components/component/floatcpn";
// import SimModule from "@/components/SimModule/index.vue";

export default {
  data() {
    return {
      allControlData: { name: "" },
      show: true
    };
  },
  components: {
    warnIndex,
    equipMent,
    ControlOpen,
    // oSimulation,

    aistart,
    openchart,
    // floatcpn,
    // Openinput,
    // SimModule,
  },
  watch: {
    "$route.query.index"(newVal, oldVal) {
      console.log(newVal);
      this.openIndex = newVal
      this.getinflowcod(this.openIndex)
    },
  },
  mounted() {
    this.openIndex = this.$route.query.index;
    this.getControlData(this.openIndex);
    this.getinflowcod(this.openIndex)
  },
  methods: {
    gotoControl() {
      this.$router.push("/AI/control");
    },
    closedrawer() {
      if (this.$route.query.index == 4) {
        // this.$refs.fangzhenIndex.getChartData(this.$route.query.index);
      }
    },
    async getControlData(index) {
      const { data: res } = await this.$http.get("/modules/init", {
        params: { index: index },
      });
      this.allControlData = res.data.result;
      this.$store.commit("changerunbt", res.data.result.runbtn);
    },
    async getinflowcod(index) {
      // console.log(index);
      const { data: res } = await this.$http.get("/modules/getinput", {
        params: { index: index },
      });
      console.log(res.data.result);
      // if (res.data.result.length < 1) {
      //   // this.show = false
      //   this.show = true
      // } else {
      //   this.show = false
      //   this.inflowcod = res.data.result;
      // }
      // this.inflow.target = res.data.result;
      // // console.log(this.inflow.target);
      // this.inflow.current = res.data.result;
      // console.log(this.inflow.current);
    },


  },
};
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  // background-color: #fafafc;
  background-color: rgb(0, 0, 0) !important;

  .bottom-box {
    display: flex;

    .right {
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  .sim-container {
    position: absolute;
    top: 30px;
    left: 520px;
    z-index: 99;
  }
}

.left {
  margin-left: 50px;
}

.openright {
  margin-right: 50px
}

.content-box {
  width: 100%;
}

.hover {
  i {

    width: 100px;
    // height: 60px;
    font-size: 25px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}

@import "/src/assets/css/index1.scss";

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  transition: all 1s;
}

.fade-enter-to {
  opacity: 1;
}

// .fade-enter-active {
//   animation: slidein 1s linear;
// }
// .fade-leave-active {
//   animation: slidein 1s linear reverse;
// }
// @keyframes slidein {
//   from {
//     transform: translateX(-100%);
//   }
//   to {
//     transform: translateX(0px);
//   }
// }
.centerfade-enter-active {
  animation: centerslidein 1s linear;
}

.centerfade-leave-active {
  animation: centerslidein 1s linear reverse;
}

@keyframes centerslidein {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0px);
  }
}

.rightfade-enter-active {
  animation: rightslidein 1s linear;
}

.rightfade-leave-active {
  animation: rightslidein 1s linear reverse;
}

@keyframes rightslidein {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0px);
  }
}

.fangzhenfade-enter-active {
  animation: fangzhenslidein 1s linear;
}

.fangzhenfade-leave-active {
  animation: fangzhenslidein 1s linear reverse;
}

@keyframes fangzhenslidein {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0px);
  }
}

.historyfade-enter-active {
  animation: historyslidein 1s linear;
}

.historyfade-leave-active {
  animation: historyslidein 1s linear reverse;
}

@keyframes historyslidein {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0px);
  }
}
</style>
