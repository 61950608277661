<template>
  <el-container class="home-container">
    <Update :show.sync="show" :percent="percent"></Update>
    <el-header class="header">
      <div class="title" v-if="openIndex && fromroute">
        <div class="goback" @click="goback()" :style="!style ? '' : 'color:#ffff'">
          <!-- {{ style }} -->
          <i class="el-icon-back"></i>
        </div>
        <span style="color: rgba(242, 242, 242, 1);">
          {{ title }}
        </span>
        <el-switch v-model="value" @change="runBtn()" active-color="rgba(64, 224, 208, 1)" inactive-color="#b8b8b8"
          :active-value="true" :inactive-value="false" active-text="ON" inactive-text="OFF"></el-switch>
        <div class="cbrBtn">
          <!-- {{ allControlData }} -->
          <!-- <el-button v-for="(item, index, id) in allControlData.result.pool" :key="item.id" type="primary" size="mini"
            @click="cbrBtn(index, id)" :style="cbrBtnStyle(index)">{{ item.id }}
          </el-button> -->
        </div>
        <div class="statusdes" :style="!style ? '' : 'color:#ffff'">
          {{ allControlData.result.operate_status.des }}
          <!-- {{ item.result.operate_status.des }} -->
          <span v-if="allControlData.result.operate_status.status < 6">
            <span class="status_color" style="background-color:rgba(168, 168, 168, 1);"
              v-if="allControlData.result.operate_status.status == 1"></span>
            <span class="status_color" style="background-color:#D43030;"
              v-if="allControlData.result.operate_status.status == 2"></span>
            <span class="status_color" style="background-color:#FA9600;"
              v-if="allControlData.result.operate_status.status == 3"></span>
            <span class="status_color" style="background-color:#498AF4;"
              v-if="allControlData.result.operate_status.status == 4"></span>
            <span class="status_color" style="background-color:rgba(64, 224, 208, 1);"
              v-if="allControlData.result.operate_status.status == 5"></span>
          </span>
        </div>
      </div>
      <div v-else style="display: flex;align-items: center;">
        <img class="loooogo" src="../assets/baiselogo.png" />
        <span style="color: black;" class="factoryname">Wastewater Treatment Plant -AI Copilot (Demo)</span>

      </div>

      <div>

      </div>
      <!-- <navbar class="navbar"></navbar> -->
      <div class="admin">
        <div class="quality" @click="openquality" style="color: rgba(242, 242, 242, 1);">
          Instrument Calibration
        </div>
        <el-dropdown @command="handleCommand">
          <!-- :hide-on-click="false" -->
          <div style="display: flex; justify-content: flex-end; cursor: pointer;">
            <div class="avatar">
              <div class="avatar_background">
                <img src="../assets/avatar_background.png" />
              </div>
              <div class="avatar_img">
                <img src="../assets/userimg.png" />
              </div>
            </div>
            <span class="admin" style="color: rgba(242, 242, 242, 1);">Admin</span>

          </div>
        </el-dropdown>
      </div>

    </el-header>
    <el-container class="content_container">
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
    <el-dialog :visible.sync="dialogVisible" :before-close="handleClose">
      <div class="dialogbigtitle">
        <div class="titlebg">
          <span class="titlespan" style="color: rgba(242, 242, 242, 1);">
            Instrument Calibration
          </span>
        </div>
      </div>
      <div class="dialogtitle">
        <div class="titlebg">
          <span class="titlespan" style="color: rgba(242, 242, 242, 1);">
            Appearance:
          </span>
          <img src="../assets/add@2x.png" alt="" @click="changeadd()" v-if="!addtype">
          <el-cascader ref="select" :options="options" :props="props" clearable v-if="addtype"
            placeholder="Please select" collapse-tags @change="onChange" v-clickOutside="clickOutside">
            <template slot="empty">
              <span>
                No Data Yet
              </span>
            </template></el-cascader>
        </div>
      </div>
      <div class="dialogtable">
        <div class="border">
          <div class="tagbox">
            <el-tag class="eltag" v-for=" i in inglist" closable @close="closetag(i)">{{ i.name }}</el-tag>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="commitchange1()">Confirm</el-button>
        <el-button @click="colsedialog1()">Cancel</el-button>
      </span>
    </el-dialog>

  </el-container>
</template>

<script>
import "../assets/css/global.scss";
import { Light } from "three";
// import { ipcRenderer } from "electron";

export default {
  components: {},

  data() {
    return {
      textarea: null,
      reason: null,
      checkList: [],
      reasondialogVisible: false,

      currentTheme: "lightTheme",
      allData: [],
      isCollapse: false,

      activePath: "/ai/control",
      style: false,
      warningABCurrent: null,
      warningA: null,
      warningB: null,
      warningALG: null,
      timer: null,
      allWarningA: [],
      allWarningB: [],
      warningindex: 0,
      algwarningindex: 0,
      gaojingdialogVisible: false,
      allAlertDetail: "",
      percent: 0,
      show: false,
      dialogVisible: false,
      qualityTest: null,
      qualityText: "",
      warningALGindex: null,
      warningAindex: null,
      type: null,
      warningaindex: 0,
      warningAI: null,
      havedata: false,
      value: true,
      openIndex: null,
      title: null,
      allControlData: {
        result: {
          runbtn: false,
          table: [],
        },
      },
      fromroute: null,
      dialogVisible: false,
      addtype: false,
      options: [],
      props: {
        multiple: true,
        value: "all",
        label: "name",
        children: "child",
        disabled: 'state'
      },
      inglist: [],
      ids: null,
      tags: null,
      PassiveQualitydialogVisible: false,
      warningalarms: [],
      allalarms: [],
      multipleSelection: null,
      alarmindex: 0,
      datetype: true
    };
  },
  directives: {
    clickOutside: {
      bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) { // here I check that click was outside the el and his childrens
          if (!(el === event.target || el.contains(event.target))) { // and if it did, call method provided in attribute value
            vnode.context[binding.expression](event)
          }
        }
        document.body.addEventListener('click', el.clickOutsideEvent)
      },
      unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
      }
    }
  },
  mounted() {


    window.electron.ipcRenderer.on("download-progress", (event, data) => {
      this.percent = data.percent.toFixed(2);
      if (data.percent >= 100) {
        // this.show = false;
      }
    });

    window.electron.ipcRenderer.on("message", (event, data) => {
      console.log(data);
      switch (data.status) {
        case -1:
          this.$Message.error(data.msg);
          break;
        case 0:
          this.$Message.loading(data.msg);
          break;
        case 1:
          this.show = true;
          break;
      }
    });
  },
  created() {
    document.documentElement.setAttribute("theme", "dark");
    localStorage.setItem("theme", JSON.stringify("dark"));
    console.log(this.warningalarms);
    this.havedata = window.sessionStorage.getItem("havedata");
    // console.log(this.havedata);
    this.getMenuList();
    this.openIndex = this.$route.query.index;
    this.fromroute = this.$route.query.from;

    // console.log(this.$route.path);
    if (this.$route.path === '/openMore') {
      this.fromroute = 'have';

    }
    if (this.openIndex) {
      // console.log(596565);
      this.getmodeltype()
    } else {
      // console.log(123131);
    }
    if (this.$route.path === "/ai/control") {
      this.activePath = "/ai/control";
    } else {
      this.activePath = window.sessionStorage.getItem("activePath");
    }

    let theme = localStorage.getItem("theme")
      ? JSON.parse(localStorage.getItem("theme"))
      : "light";
    // console.log(theme);
    this.style = theme == "light" ? false : true;
    // console.log(JSON.parse(localStorage.getItem("theme")));

    if (theme == "light") {
      this.$store.commit("", theme);
    } else {
      this.$store.commit("changetype", theme);
    }
    document.documentElement.setAttribute("theme", theme);
    // this.getquality();
    // console.log(this.style);
  },
  watch: {
    $route(to, from) {
      console.log(to.path);

      if (to.path == '/openMore') {
        console.log(23232323);
        this.havedata = true
        this.openIndex = this.$route.query.index;
        this.fromroute = 'have';
        this.getmodeltype()
        window.sessionStorage.setItem("havedata", true);
      } else {
        console.log(444444444);
        this.havedata = false
        this.openIndex = this.$route.query.index;
        this.fromroute = this.$route.query.from;
        console.log(this.openIndex);
        window.sessionStorage.setItem("havedata", false);
      }
    }
  },
  computed: {
    noChildren() {
      return this.allData.filter((item) => !item.child);
    },
    hasChildren() {
      return this.allData.filter((item) => item.child);
    },

  },
  sockets: {

    warningAB(res) {
      // console.log(res);
      this.warningABCurrent = JSON.parse(res);
      console.log(this.warningABCurrent.A);
      if (this.warningABCurrent.B != []) {
        this.warningB = this.warningABCurrent.B;
      }
      if (this.warningABCurrent.A) {
        this.warningA = this.warningABCurrent.A;
        console.log(this.warningA);
      }
      if (this.warningABCurrent.AI != []) {
        this.warningAI = this.warningABCurrent.AI;
      }
      // console.log(this.warningA);
      if (
        this.$route.path != "/alertDetail" &&
        this.$route.path != "/parameterSetting" &&
        this.warningB
      ) {
        if (this.warningA.length > 0) {

          this.gaojingdialogVisible = true
        } else {
          this.gaojingdialogVisible = false

        }
        // this.open1();

      }
      this.allWarningB.push(this.warningB);

      if (this.warningABCurrent.alarms &&
        this.$route.path != "/parameterSetting") {
        console.log(this.warningABCurrent);
        if (this.allalarms.length < 1) {
          this.allalarms.push(this.warningABCurrent)
        }
        let findindex = ''
        let result = this.allalarms.find((item, index) => {
          if (item.name == this.warningABCurrent.name) {
            // console.log(index);
            findindex = index
            return item.name == this.warningABCurrent.name
          }
        })
        if (result) {
          this.allalarms[findindex].alarms = this.warningABCurrent.alarms
          // item.alarms = this.warningABCurrent.alarms
        } else {
          this.allalarms.push(this.warningABCurrent)
        }


        console.log(this.allalarms);

        this.qualityopen()

      } else if (this.warningABCurrent.A.length > 0) {
        if (
          this.$route.path != "/alertDetail" &&
          this.$route.path != "/parameterSetting"
        ) {

          this.gaojingdialogVisible = true

        }
      }
    },
  },
  methods: {
    clickOutside() {
      this.$refs.select.blur()
    },

    handleClick(row) {
      // console.log(row);
      this.$router.push({
        path: "/alertDetail",
        query: {
          id: row.id,
          index: row.index,
        },
      });
      this.gaojingdialogVisible = false
      this.PassiveQualitydialogVisible = false

    },
    qualityopen() {
      this.warningalarms = []
      // this.alarmindex++
      for (let a = 0; a < this.allalarms.length; a++) {
        for (let b = 0; b < this.allalarms[a].alarms.length; b++) {
          this.warningalarms.push(this.allalarms[a].alarms[b])
        }
      }
      if (this.warningalarms.length > 0) {
        // console.log(this.warningA.length, 11111111111111111111111);
        this.PassiveQualitydialogVisible = true

        // this.gaojingdialogVisible = false
        // this.gaojingdialogVisible = true
        if (this.warningA.length > 0 && this.alarmindex == 0) {
          this.gaojingdialogVisible = false
          this.alarmindex = 1
          // this.open();
        }
      } else {
        this.alarmindex = 0
        this.PassiveQualitydialogVisible = false
      }
    },
    changeadd() {
      this.addtype = true
    },
    colsedialog1() {
      this.addtype = false
      this.dialogVisible = false
    },
    async commitchange1() {
      if (this.ids.length > 0) {
        const { data: res1 } = await this.$http.post("/abwarning/statechange", {
          ids: this.ids,
          tags: this.tags,
          state: true
        });
        if (res1.meta.status == 200) {
          // this.openquality()
          this.addtype = false

          this.dialogVisible = false
          return this.$message.success(res1.meta.msg);
        }
      }
      this.addtype = false
      this.dialogVisible = false
    },
    async closetag(value) {
      console.log(value);
      const { data: res1 } = await this.$http.post("/abwarning/statechange", {
        ids: [value.id],
        tags: [value.tag],
        state: false
      });
      if (res1.meta.status == 200) {
        this.openquality()
        return this.$message.success(res1.meta.msg);
      }
    },
    async onChange(value) {
      console.log(value);
      let ids = [];
      let tags = [];
      for (let a = 0; a < value.length; a++) {
        // console.log(value[a]);
        ids.push(value[a][1].id)
        tags.push(value[a][1].tag)
      }
      // console.log(ids);
      this.ids = ids;
      this.tags = tags
      // for

    },
    async openquality() {
      // this.options == []
      this.ids = [];
      this.tags = []
      let arr = []
      const { data: res } = await this.$http.get("/abwarning/qualityinfo");
      console.log(res);
      for (let a = 0; a < res.data.length; a++) {
        for (let b = 0; b < res.data[a].child.length; b++) {

          // console.log(res.data[a].child[b]);
          res.data[a].child[b].all = {
            id: res.data[a].child[b].id,
            name: res.data[a].child[b].name,
            tag: res.data[a].child[b].tag,
          }
        }
        arr.push(
          {
            id: res.data[a].group,
            name: res.data[a].groupname,
            child: res.data[a].child,
            all: {
              id: res.data[a].group,
              name: res.data[a].groupname,
            }
          }
        )
      }
      console.log(arr);
      this.options = arr
      let truelist = []
      for (let b = 0; b < this.options.length; b++) {
        for (let c = 0; c < this.options[b].child.length; c++) {
          if (this.options[b].child[c].state) {
            truelist.push(this.options[b].child[c])
          }
        }
      }
      console.log(truelist);
      this.inglist = truelist
      this.dialogVisible = true
    },
    handleClose() {
      // this.options == []
      this.addtype = false
      this.dialogVisible = false
    },
    async getmodeltype() {
      const { data: res } = await this.$http.get("/modules/init", {
        params: { index: this.openIndex },
      });
      console.log(res);
      this.allControlData = res.data;
      this.title = res.data.result.name
      this.value = res.data.result.runbtn
    },
    async runBtn() {
      // console.log(this.inAerationData[index].result.runbtn);
      if (this.value == true) {
        const { data: res1 } = await this.$http.post("modules/control", {
          index: this.openIndex,
          flag: true,
        });
        console.log(res1);
        this.$store.commit("changerunbt", res1.data.operate);
        if (res1.meta.status == 403) {
          this.getIntelligentAerationData();
          return this.$message.error(res1.meta.msg);
        }
      } else if (this.value == false) {
        // this.reasondialogVisible = true
        const { data: res1 } = await this.$http.post("modules/control", {
          index: this.openIndex,
          flag: false,
        });
        // const { data: res2 } = await this.$http.post("modules/control", {
        //   index: this.openIndex,
        //   flag: false,
        // });
        // this.$store.commit("changerunbt", res2.data.operate);
        // if (res2.meta.status == 403) {
        //   this.getIntelligentAerationData();
        //   return this.$message.error(res2.meta.msg);
        // }
      }

    },

    getMenuList() {
      this.allData = JSON.parse(window.sessionStorage.getItem("user"));
      // console.log(this.allData);
    },
    toggleCollapse() {
      this.isCollapse = !this.isCollapse;
      this.$store.commit("changeopen", this.isCollapse);
    },
    saveNavState(activePath) {
      // console.log(activePath);
      this.activePath = activePath;
      window.sessionStorage.setItem("activePath", activePath);
    },
    loginout() {
      window.sessionStorage.clear();
      this.$router.push("/login");
    },
    handleChangeStyle() {

      this.style = !this.style;
      console.log(this.style);
      // this.$store.commit("changetype", this.style);
      this.$store.commit("changeTheme");
      if (this.style == false) {
        document.documentElement.setAttribute("theme", "light");
        localStorage.setItem("theme", JSON.stringify("light"));
        // document.getElementsByTagName("body")[0].className = this.currentTheme;
      } else if (this.style == true) {
        document.documentElement.setAttribute("theme", "dark");
        localStorage.setItem("theme", JSON.stringify("dark"));
        // document.getElementsByTagName("body")[0].className = this.currentTheme;
      }
    },


    defineCallBack(message, i) {
      console.log(i);
      // console.log(message[i].id);
      this.$router.push({
        path: "/alertDetail",
        query: {
          id: message[i].id,
          index: message[i].index,
        },
      });
    },
    async getquality() {
      const { data: res } = await this.$http.get("qualitybtn/state");
      this.qualityTest = res.data;
      console.log(res);
      for (let i = 0; i < this.qualityTest.length; i++) {
        if (this.qualityTest[i].flag == true) {
          this.qualityText = "Instrument Calibration...";
        }
        if (
          this.qualityTest[0].flag == false &&
          this.qualityTest[1].flag == false &&
          this.qualityTest[2].flag == false &&
          this.qualityTest[3].flag == false
        ) {
          this.qualityText = "Instrument Calibration";
        }
      }
    },
    openbtn(item) {
      this.dialogVisible = true;
    },
    BtnStyle(item, index) {
      if (this.qualityTest[index].flag == true) {
        return { backgroundColor: "#3CB371", color: "#fff" };
      } else {
        return { backgroundColor: "#909399", color: "#fff" };
      }
    },
    textbtn() {
      if (this.qualityText == "Instrument Calibration...") {
        return { backgroundColor: "#3CB371", color: "#fff" };
      }
    },

    defineCallBack(message, i) {
      console.log(i);
      // console.log(message[i].id);
      this.$router.push({
        path: "/alertDetail",
        query: {
          id: message[i].id,
          index: message[i].index,
        },
      });
    },
    async getquality() {
      const { data: res } = await this.$http.get("qualitybtn/state");
      this.qualityTest = res.data;
      console.log(res);
      for (let i = 0; i < this.qualityTest.length; i++) {
        if (this.qualityTest[i].flag == true) {
          this.qualityText = "Instrument Calibration...";
        }
        if (
          this.qualityTest[0].flag == false &&
          this.qualityTest[1].flag == false &&
          this.qualityTest[2].flag == false &&
          this.qualityTest[3].flag == false
        ) {
          this.qualityText = "Instrument Calibration";
        }
      }
    },
    openbtn(item) {
      this.dialogVisible = true;
    },
    BtnStyle(item, index) {
      if (this.qualityTest[index].flag == true) {
        return { backgroundColor: "#3CB371", color: "#fff" };
      } else {
        return { backgroundColor: "#909399", color: "#fff" };
      }
    },
    textbtn() {
      if (this.qualityText == "Instrument Calibration...") {
        return { backgroundColor: "#3CB371", color: "#fff" };
      }
    },
    async qualityBtn(item, index) {
      if (item.flag == false) {
        const { data: res } = await this.$http.post("qualitybtn/change", {
          id: item.id,
          flag: true,
        });
        console.log(res);
        if (res.meta.status == 200) {
          // this.dialogVisible = false;
          // this.getquality();
          this.$message.success(res.meta.msg);
        }
      }
      if (item.flag == true) {
        const { data: res } = await this.$http.post("qualitybtn/change", {
          id: item.id,
          flag: false,
        });
        console.log(res);
        if (res.meta.status == 200) {
          // this.dialogVisible = false;
          // this.getquality();
          this.$message.success(res.meta.msg);
        }
      }
    },
    async cbrBtn(index, id) {
      id = this.allControlData.result.pool[index].id;
      // console.log(this.inAerationData);
      // console.log(id);
      if (this.allControlData.result.pool[index].flag == true) {
        const { data: res1 } = await this.$http.post("modules/pool", {
          index: this.allControlData.index,
          id: id,
          flag: false,
        });
        if (res1.meta.status == 403) {
          // this.getControlData(this.openIndex);
          this.getmodeltype()

          return this.$message.error(res1.meta.msg);
        }
      } else if (this.allControlData.result.pool[index].flag == false) {
        const { data: res2 } = await this.$http.post("modules/pool", {
          index: this.allControlData.index,
          id: id,
          flag: true,
        });
        if (res2.meta.status == 403) {
          this.getmodeltype()

          return this.$message.error(res2.meta.msg);
        }
      }
      this.getmodeltype()

    },
    cbrBtnStyle(index) {
      // console.log(434343);

      if (this.allControlData.result.pool[index].flag == true) {
        return { backgroundColor: "#1890ff", color: "fff" };
      } else {
        return { backgroundColor: "#b8b8b8", color: "fff" };
      }
    },
    handleCommand(command) {
      // console.log(command);
      if (command == 'a') {
        this.style = !this.style;
        console.log(this.style);
        // this.$store.commit("changetype", this.style);
        this.$store.commit("changeTheme");
        if (this.style == false) {
          document.documentElement.setAttribute("theme", "light");
          localStorage.setItem("theme", JSON.stringify("light"));
          // document.getElementsByTagName("body")[0].className = this.currentTheme;
        } else if (this.style == true) {
          document.documentElement.setAttribute("theme", "dark");
          localStorage.setItem("theme", JSON.stringify("dark"));
          // document.getElementsByTagName("body")[0].className = this.currentTheme;
        }
      } else if (command == 'update') {
        // this.updateApp()
      } else if (command == 'c') {
        this.datetype = !this.datetype;
        console.log();
        if (this.datetype == false) {
          this.$store.commit("changesdatetype", 'c');
        } else if (this.datetype == true) {
          this.$store.commit("changesdatetype", 'a');
        }
      } else {
        this.loginout()
      }
      // this.$message('click on item ' + command);
    },

    goback() {
      this.$router.go(-1)
    },

    handleSelectionChange(val) {
      this.multipleSelection = val
    }
  },
};
</script>
<style lang="scss" scoped>
.home-container {
  height: 100% !important;
  // background: rgba(235, 236, 237, 1);
}

.el-header {

  height: 60px !important;
  // line-height: 60px !important;
  background-color: #fafafc;
  margin-bottom: 20px;
  // display: flex;
  display: grid;
  grid-template-columns: 3fr 3fr 3fr;
  color: #fff;
  font-size: 22px;
  align-items: center;
  justify-content: space-between;

  .title {
    font-size: 20px;
    color: black;
    display: flex;
    // justify-content: center !important;
    align-items: center !important;

    .goback {
      margin-right: 15px;
      cursor: pointer;
    }

    span {

      margin-right: 30px;
    }
  }

  .loooogo {
    width: 15%;
  }

  .factoryname {
    margin-left: 20px;
  }

  .avatar {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-right: 30px;

    .avatar_background {
      position: absolute;
      z-index: 1;
      text-align: center;

      img {
        width: 36px;

      }
    }

    .avatar_img {
      position: absolute;
      z-index: 2;

      img {

        width: 26px;
      }
    }
  }

  .admin {
    color: #4d4d4d;
    font-size: 14px;
    // margin-right: 30px;
  }

  .changetype {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 63px;
    height: 26px;
    opacity: 1;
    border-radius: 4px;
    background: rgba(225, 226, 226, 1);
    margin-left: 30px;
    margin-right: 40px;

    .sun {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      width: 26px;
      height: 20px;
      opacity: 1;
      border-radius: 4px;
      background: rgba(250, 250, 252, 1);
      margin-left: 4px;
      box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.1);
    }

    .moon {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      width: 26px;
      height: 20px;
      opacity: 1;
      border-radius: 4px;
      // background: rgba(250, 250, 252, 1);
      margin-right: 4px;
      // box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.1);
    }
  }

  .loginout {
    margin-left: 20px !important;
    margin-right: 18px;
    margin-top: 16px;
    color: #00a8e8;
    // box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.1);
  }

  .loginout1 {
    display: none;
  }
}

.cbrBtn {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  text-align: center;
  // margin: 0 auto;
  margin-left: 20px;
}

.statusdes {
  // padding-bottom: 20px;
  margin-left: 20px;
  font-size: 20px;

  // padding: 10px 46px;
  .status_color {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: red;
    // color: #ee5a3d;
  }
}

::v-deep .el-aside {
  // flex: 1;
  // background: rgba(28, 28, 28, 1);
  overflow: hidden !important;
  height: 60px;
  // margin-top: 14px !important;
  // margin-bottom: 20px !important;
  // border-radius: 12px !important;
  // height: 940px !important;
  // position: fixed !important;
  // overflow-x: hidden;
  // transition: 0.3s;
}

.el-main {
  // overflow-y: scroll;
  padding: 0;
}

.el-aside:hover .shoqibutton {
  display: inline-block !important;
  cursor: pointer;
}

.el-menu {
  border-right: 0;
  // margin-top: 14px;
}

.asideIcon {
  padding-left: 20px;
}

.asideSpan {
  padding-left: 10px;
  font-size: 15px;
}

.subItemSpan {
  padding-left: 24px;
}

.btn-change-style {
  margin-top: 16px;
}

.styleChange {
  color: #1890ff;
}

.elAside {
  display: none !important;
}

.textbtn {
  margin-left: 20px !important;
  margin-right: 18px;
  margin-top: 16px;
  color: #1890ff;
  // box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.1);
}

.textbtn {
  color: #3cb371;
  font-weight: 600;
  // background-image: url("../assets/loading123.gif");

  // background-size: 16%;
  // background-position: 95% 50%;
}

::v-deep .el-switch {
  .el-switch__label * {
    line-height: 1;
    font-size: 12px;
    display: inline-block;
  }

  .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
    font-size: 12px !important;
  }


  .el-switch__label--right {
    z-index: 1;
    right: 22px;
  }


  .el-switch__label--left {
    z-index: 1;
    left: 22px;
  }


  .el-switch__label.is-active {
    display: block;
  }


}

::v-deep .el-switch .el-switch__core,
.el-switch .el-switch__label {
  width: 54px !important;
}

// ::v-deep .el-menu-item {
//   background-color: red;
// }

.navbar {
  // display: none
}

.header:hover .navbar {
  display: flex;
}

.admin {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .quality {
    padding: 0 10px;
    margin-right: 50px;
    // width: 87px;
    height: 30px;
    opacity: 1;
    border-radius: 34px;
    background: rgba(34, 38, 49, 1);
    border: 0.5px solid rgba(255, 99, 71, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    color: rgba(255, 99, 71, 1);
    cursor: pointer;
  }
}

::v-deep .el-dialog {
  // width: 796px;
  // height: 450px;
  opacity: 1;
  border-radius: 30px;
  background: rgba(255, 255, 255, 1);
}

// ::v-deep .el-dialog__body {
//   padding: 10px 20px;
//   text-align: center;
// }
.dialogbigtitle {
  display: flex;
  align-items: center;
  justify-content: center;

  .titlebg {
    width: 696.68px;
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 400;
    line-height: 28.96px;
    color: rgba(77, 74, 74, 1);
  }
}

.dialogtitle {
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  .titlebg {
    width: 696.68px;
    display: flex;
    align-items: center;
  }

  .titlespan {
    margin-right: 10px;

  }

  img {
    width: 20px;
    height: 20px;
    margin-right: 20px;

  }

  span {
    font-size: 18px;
    font-weight: 400;
    color: rgba(77, 74, 74, 1);
    text-align: left;
    vertical-align: middle;
  }
}

.dialogtable {
  margin-top: 40px;
  // text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  .border {
    width: 696.68px;
    height: 240px;
    opacity: 1;
    border-radius: 12px;
    border: 0.8px solid rgba(73, 138, 244, 1);
    text-align: left;
    // display: flex;
    // justify-content: left;
    // align-items: center;

    .borderspan {
      margin-left: 34px;
      margin-right: 34px;
      margin-top: 17px;
      font-size: 18px;
      font-weight: 400;
      color: rgba(73, 138, 244, 1);
    }

    .tagbox {
      margin-left: 34px;
      margin-right: 34px;
      height: 250px;
      overflow: scroll;
      // background-color: red;

      .eltag {
        margin-right: 20px;
        margin-top: 20px;
        padding: 0 20px;
        height: 40px;
        line-height: 40px;
        font-size: 15px;
      }
    }
  }


}

::v-deep .el-dialog.PassiveQuality {
  width: 1276px;
  opacity: 1;
  border-radius: 35px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 12px 50px 9px rgba(222, 60, 60, 0.25);
}

.PassiveQuality {
  .dialogtitle {
    // text-align: center;
    // width: 857px;
    height: 58px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;

    .titlebg {
      width: 857px;
      border-radius: 159px;
      background: rgba(254, 236, 234, 1);
      display: flex;
      justify-content: center;
      align-items: center;

    }

    img {
      // line-height: 58px;
      width: 31px;
      height: 26px;
      // opacity: 1;
      // background: rgba(212, 48, 48, 1);

    }

    span {
      font-size: 24px;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 58px;
      color: rgba(212, 48, 48, 1);
      text-align: left;
      vertical-align: middle;
    }
  }

  .dialogtable {
    margin: 10px 67px;

    ::-webkit-scrollbar {
      border-radius: 6px;
      width: 5px !important;
      background-color: #ccc;
    }

    ::-webkit-scrollbar-thumb {

      border-radius: 6px;
      background-color: #989898;
      height: 30px;
    }

    .codMain {
      width: 100%;
      height: 100%;
      margin: 0 auto;
      z-index: 99;
      // background-color: #fff;
    }
  }

  .commit {
    font-size: 18px;

    width: 96.32px;
    height: 45px;
    // opacity: 1;
    border-radius: 4px;
    background: rgba(236, 245, 255, 1);
    border: 0.5px solid rgba(73, 162, 255, 1);
    margin-right: 125px;
    margin-bottom: 40px;
  }

  .nocommit {
    font-size: 18px;
    width: 96.32px;
    height: 45px;
    // opacity: 1;
    border-radius: 4px;
    background: rgba(236, 245, 255, 1);
    border: 0.5px solid rgba(73, 162, 255, 1);
    // margin-right: 125px;
    margin-bottom: 40px;
  }

  .del-model {
    z-index: 9999999999
  }


}

::v-deep.el-switch__core {
  width: 135px !important;
  // height: 15px;
  // /*color:#409EFF;*/
}

::v-deep.el-input__inner {
  // width: 400px !important;
}

::v-deep.el-cascader .el-input {
  width: 400px !important;
}

.reasonitem {
  margin-bottom: 10px;
}

// .navbar:hover .navbar {
//   display: block;
// }</style>