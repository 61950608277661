<template>
  <div class="chartOne">
    <div class="titleall">
      <h6 class="title" :style="themetype == 'chalk' ? 'color:#fff' : ''">{{ chartTitle }}</h6>
      <span class="tspan">{{ chartUnit }}</span>
    </div>
    <div :id="codMain" class="codMain"></div>
  </div>
</template>

<script>
import * as XLSX from "xlsx";
import XLSXS from "xlsx-style-cptable";
import FileSaver from "file-saver";
import { mapState } from "vuex";
export default {
  props: {
    codMain: String,
    ChartData: Object,
    firstindex: Number
  },
  data() {
    return {
      // one: this.ChartData,
      themetype: null,
      one: this.ChartData,
      myChart: null,
      timer: null,
      codChart: null,
      chartData: {},
      seriesData: [],
      openIndex: null,
      chartTitle: "",
      chartUnit: "",
      initOption: {
        backgroundColor: '',
        animation: true,
        animationDuration: 1500,
        dataZoom: [
          {
            type: "inside",
          },
        ],
        // color: ['red'],
        legend: {
          data: "",
          itemHeight: 11,
          itemWidth: 22,
          textStyle: {
            rich: {
              a: {
                verticalAlign: "middle",
              },
            },
            padding: [0, 0, -2, 0],
          },

        },
        grid: {
          top: "12%",
          left: "3%",
          right: "4%",
          bottom: "13%",
          containLabel: true,
        },

        xAxis: {
          type: "category",
          boundaryGap: false,
          axisLine: {
            show: true,
            lineStyle: {
              color: "#fffff",
            },
          },
          textStyle: { color: "#fffff" },
          axisTick: { show: false },
          axisLabel: {
            show: true,
            textStyle: {
              color: "rgba(163, 163, 163, 1)",
            }
          },

        },
        yAxis: [
          {
            type: "value",
            splitLine: {
              show: false
            },
            axisLine: { show: false },

          },
        ],
        tooltip: {
          confine: true,
          trigger: "axis",
          axisPointer: {
            type: "line",
            z: 0,
            lineStyle: {
              color: "#2D3443",
            },
          },
          textStyle: {
            align: "left",
          },
          extraCssText: "z-index: 99999",
        },
        series: [
          {
            name: "",
            data: "",
            symbol: "none",
            smooth: true,
            lineStyle: { width: 1.6 },
            type: "line",
            label: {
              show: false,
              position: "right",
            },
            itemStyle: {
              lineStyle: {
                color: '"#1890ff"',
                width: 1.2,
              },
            },
            zlevel: 999,
          },
        ],
      },
    };
  },
  beforeDestroy() {

    clearInterval(this.timer);
  },
  mounted() {
    let theme = localStorage.getItem("theme")
      ? JSON.parse(localStorage.getItem("theme"))
      : "light";
    this.themetype = theme == "dark" ? 'chalk' : 'no';
    this.initChart();
    this.timer = window.setInterval(() => {
      setTimeout(this.initChart(), 0);
    }, 300000);
    window.addEventListener("resize", () => {
      this.codChart.resize();
    });
  },
  computed: {
    ...mapState(["theme"]),
  },
  watch: {
    "$store.state.open"(newVal, oldVal) {
      this.$nextTick(() => {
        // this.codChart.resize();
        setTimeout(() => {
          console.log(this.codChart);
          this.codChart.resize();
        }, 10);
      });
    },
    ChartData(newval) {
      this.codChart.clear();
      if (newval) {
        this.initChart();
      }
    },
    theme(newval) {
      // console.log("nidaye");
      this.themetype = newval
      this.codChart.dispose();
      this.initChart();
    },
  },
  methods: {
    initChart() {
      console.log(this.ChartData);
      this.chartTitle = this.ChartData.title;
      // console.log(this.chartTitle);
      this.chartUnit = this.ChartData.legend[0].unit;
      if (this.chartUnit == '-') {
        this.chartUnit = ''
      }
      this.initOption.xAxis.data = this.ChartData.xAxis;
      this.initOption.yAxis = []
      this.initOption.series = []
      //   const legendData = this.ChartData.legend.map((item) => {
      //     return item.name;
      //   });
      //   this.initOption.legend.data = legendData;
      let selected_item = {};
      this.ChartData.legend.map((item, index, array) => {
        var key_name = item.name;
        // if (index > 1) {
        //   selected_item[key_name] = false;
        // } else {
        //   selected_item[key_name] = true;
        // }
        this.initOption.title = {
          left: "center",
          text: this.chartTitle,
          show: false,
        };
        this.initOption.yAxis = [
          {
            type: "value",

            splitLine: {
              show: false,
            },
            axisLine: { show: false },
            axisLabel: {
              textStyle: {
                color: "#828282",
              },
            },
            axisTick: { show: false },
          },
        ];
        if (index === 0) {
          this.initOption.series[index] = {
            name: item.name,
            data: item.yAxis,
            symbol: "none",
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1,
                  [{
                    offset: 0,
                    color: 'rgba(0, 128, 128, 1)'
                  }, {
                    offset: 1, color: 'rgba(0, 128, 128, 0.1)'
                  }]),
              },
            },
            smooth: false,
            lineStyle: { width: 1.6 },
            type: "line",
            label: {
              show: false,
              position: "right",
            },
            itemStyle: {
              normal: {
                color: '#498AF4',
                lineStyle: {
                  color: 'rgba(0, 128, 128, 1)',
                  width: 1.2,
                },
              }
            },
          };
        } else {
          // console.log(item);
          this.initOption.series[index] = {
            name: item.name,
            data: item.yAxis,
            symbol: "none",
            smooth: false,
            lineStyle: { width: 1.6 },
            type: "line",
            // yAxisIndex: index,
            label: {
              show: false,
              position: "right",
            },

          };
        }
      });
      if (this.firstindex == 0) {

        this.initOption.legend.selected = selected_item;
      }
      console.log(this.initOption);
      let newPromise = new Promise((resolve) => {
        resolve();
      });
      newPromise.then(() => {
        // console.log(this.theme);
        this.myChart = this.$echarts.init(
          document.getElementById(this.codMain),
          this.theme
        );

        this.myChart.setOption(this.initOption);
        this.codChart = this.$echarts.init(
          document.getElementById(this.codMain)
        );
        if (this.codChart == null) {
          this.codChart = this.$echarts.init(
            document.getElementById(this.codMain)
          );
        }
        this.codChart.clear();
        this.codChart.setOption(this.initOption);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.chartOne {
  // background-color: red !important;
  width: 100%;
  // height: 100%;
  margin: 0 auto;
  //   box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  overflow-y: hidden;

  .titleall {
    text-align: center;

    .title {
      display: inline-block;
      font-weight: 500;
      font-size: 18px;
      margin: 9px;
      margin-right: 10px;
    }

    .tspan {
      color: #a6a6a6;
    }
  }

  ::-webkit-scrollbar {
    border-radius: 6px;
    width: 5px !important;
    background-color: #ccc;
  }

  ::-webkit-scrollbar-thumb {

    border-radius: 6px;
    background-color: #989898;
    height: 30px;
  }

  .codMain {
    // background-color: red !important;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    z-index: 99;
    // background-color: #fff;
  }

  h6 {
    padding-left: 36px;
    text-align: left;
    font-size: 12px;
    color: rgb(76, 76, 76);
    font-weight: 600;
  }
}
</style>
