import { render, staticRenderFns } from "./LinkageModuleCOD.vue?vue&type=template&id=3b010ef6&scoped=true&"
import script from "./LinkageModuleCOD.vue?vue&type=script&lang=js&"
export * from "./LinkageModuleCOD.vue?vue&type=script&lang=js&"
import style0 from "./LinkageModuleCOD.vue?vue&type=style&index=0&id=3b010ef6&prod&lang=scss&scoped=true&"
import style1 from "./LinkageModuleCOD.vue?vue&type=style&index=1&id=3b010ef6&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b010ef6",
  null
  
)

export default component.exports