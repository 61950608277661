<template>
  <div class="linkage-module-COD">
    <div class="header">Equalisation</div>
    <swiper class="swiper-container" id="linkage-module-swiper" ref="mySwiper" :options="swiperOption"
      :not-next-tick="true" v-if="Object.keys(chartOptions).length">
      <swiper-slide v-for="item in moduleList" :key="item">
        <div class="base-info">
          <div class="title">{{ chartOptions[item] && chartOptions[item].title }}</div>
          <div class="switch-box">
            <el-popover placement="bottom" trigger="click" popper-class="linkage-module-popover"
              @show="onPopoverShow(item)">
              <div class="popover-content">
                <div class="value-item" v-for="(targetItem, index) in chartOptions[item]
        ? chartOptions[item].targetList
        : []" :key="index">
                  {{ targetItem.label }}：{{ targetItem.value }}
                </div>
              </div>
              <span slot="reference">
                <i class="iconfont icon-xiangxiajiantou" />
                {{ { AO_DN: "NOx-N", AO_DO: "DO", OE_O3: "COD" }[item] }}
                Recommendation
              </span>
            </el-popover>
          </div>
        </div>

        <div class="chart-box">
          <LineChart :chartOptions="chartOptions[item] ? chartOptions[item].options : {}" height="100%"
            @onChartDataZoom="onChartDataZoom(chartOptions[item] ? chartOptions[item].title : '')" />
        </div>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
      <div slot="button-prev" :class="moduleList.length <= 2 ? 'swiperStyle' : 'swiper-button-prev'"
        @click="handleSwiperButton"></div>
      <div slot="button-next" :class="moduleList.length <= 2 ? 'swiperStyle' : 'swiper-button-next'"
        @click="handleSwiperButton"></div>
    </swiper>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import LineChart from "@/components/SimModule/components/LineChart.vue";
import commonApi from "@/api/common.js";

let timer = null, chartTimer = null;
export default {
  components: {
    LineChart,
    swiper,
    swiperSlide,
  },
  props: {
    tabName: String,
    moduleList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    swiperOptionFun() {
      return this.swiperOption;
    },
  },
  data() {
    return {
      switchValue: false,
      mySwiper: null,
      swiperOption: {
        // loop: true, // 
        slidesPerView: 2,
        observer: true,
        observeParents: true,
        freeMode: false,
        spaceBetween: 16,

        mousewheel: false,

        pagination: {
          el: ".swiper-pagination",
          clickable: true,

        },
        prevButton: "#linkage-module-swiper .swiper-button-prev",
        nextButton: "#linkage-module-swiper .swiper-button-next",
        navigation: {
          nextEl: "#linkage-module-swiper .swiper-button-next",
          prevEl: "#linkage-module-swiper .swiper-button-prev",
        },
      },
      initChartOptions: {
        animation: true,
        animationDuration: 1500,
        color: ["#2366A8", "#73C0DE", "#ff6367"],
        dataZoom: [
          {
            type: "inside",
          },
        ],
        legend: {
          data: "",
          itemHeight: 11,
          itemWidth: 22,
          textStyle: {
            rich: {
              a: {
                verticalAlign: "middle",
              },
            },
            padding: [0, 0, -2, 0],
          },
        },
        grid: {
          top: "16%",
          left: "3%",
          right: "4%",
          bottom: "5%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: false,
          },
        },
        tooltip: {
          confine: true,
          trigger: "axis",
          axisPointer: {
            type: "line",
            z: 0,
            lineStyle: {
              color: "#2D3443",
            },
          },
          textStyle: {
            align: "left",
          },
          extraCssText: "z-index: 99999",
        },
        series: [
          {
            name: "",
            data: "",
            symbol: "none",
            smooth: true,
            lineStyle: { width: 1.6 },
            type: "line",
            label: {
              show: false,
              position: "right",
            },
            itemStyle: {
              lineStyle: {
                color: '"#1890ff"',
                width: 1.2,
              },
            },
            zlevel: 999,
          },
        ],
      },
      chartOptions: {},
      chartoptionsArr: [],
      chartData: {
        AO_DO: {
          "xAxis": [
            "2024-09-19 15:10:02",
            "2024-09-19 15:11:03",
            "2024-09-19 15:12:02",
            "2024-09-19 15:13:02",
            "2024-09-19 15:14:02",
            "2024-09-19 15:15:02",
            "2024-09-19 15:16:03",
            "2024-09-19 15:17:03",
            "2024-09-19 15:18:02",
            "2024-09-19 15:19:03",
            "2024-09-19 15:20:03",
            "2024-09-19 15:21:03",
            "2024-09-19 15:22:03",
            "2024-09-19 15:23:03",
            "2024-09-19 15:24:03",
            "2024-09-19 15:25:03",
            "2024-09-19 15:26:03",
            "2024-09-19 15:27:03",
            "2024-09-19 15:28:03",
            "2024-09-19 15:29:03",
            "2024-09-19 15:30:03",
            "2024-09-19 15:31:03",
            "2024-09-19 15:32:03",
            "2024-09-19 15:33:03",
            "2024-09-19 15:34:03",
            "2024-09-19 15:35:03",
            "2024-09-19 15:36:03",
            "2024-09-19 15:37:03",
            "2024-09-19 15:38:03",
            "2024-09-19 15:39:03",
            "2024-09-19 15:40:03",
            "2024-09-19 15:41:03",
            "2024-09-19 15:42:03",
            "2024-09-19 15:43:03",
            "2024-09-19 15:44:03",
            "2024-09-19 15:45:03",
            "2024-09-19 15:46:03",
            "2024-09-19 15:47:03",
            "2024-09-19 15:48:03",
            "2024-09-19 15:49:03",
            "2024-09-19 15:50:03",
            "2024-09-19 15:51:03",
            "2024-09-19 15:52:03",
            "2024-09-19 15:53:03",
            "2024-09-19 15:54:03",
            "2024-09-19 15:55:03",
            "2024-09-19 15:56:03",
            "2024-09-19 15:57:03",
            "2024-09-19 15:58:03",
            "2024-09-19 15:59:03",
            "2024-09-19 16:00:03",
            "2024-09-19 16:01:03",
            "2024-09-19 16:02:03",
            "2024-09-19 16:03:03",
            "2024-09-19 16:04:03",
            "2024-09-19 16:05:03",
            "2024-09-19 16:06:03",
            "2024-09-19 16:07:03",
            "2024-09-19 16:08:03",
            "2024-09-19 16:09:03",
            "2024-09-19 16:10:03",
            "2024-09-19 16:11:03",
            "2024-09-19 16:12:03",
            "2024-09-19 16:13:03",
            "2024-09-19 16:14:03",
            "2024-09-19 16:15:03",
            "2024-09-19 16:16:03",
            "2024-09-19 16:17:03",
            "2024-09-19 16:18:03",
            "2024-09-19 16:19:03",
            "2024-09-19 16:20:03",
            "2024-09-19 16:21:03",
            "2024-09-19 16:22:03",
            "2024-09-19 16:23:03",
            "2024-09-19 16:24:03",
            "2024-09-19 16:25:03",
            "2024-09-19 16:26:03",
            "2024-09-19 16:27:03",
            "2024-09-19 16:28:03",
            "2024-09-19 16:29:03",
            "2024-09-19 16:30:03",
            "2024-09-19 16:31:03",
            "2024-09-19 16:32:03",
            "2024-09-19 16:33:03",
            "2024-09-19 16:34:03",
            "2024-09-19 16:35:03",
            "2024-09-19 16:36:03",
            "2024-09-19 16:37:03",
            "2024-09-19 16:38:03",
            "2024-09-19 16:39:03",
            "2024-09-19 16:40:03",
            "2024-09-19 16:41:03",
            "2024-09-19 16:42:03",
            "2024-09-19 16:43:03",
            "2024-09-19 16:44:03",
            "2024-09-19 16:45:03",
            "2024-09-19 16:46:03",
            "2024-09-19 16:47:03",
            "2024-09-19 16:48:03",
            "2024-09-19 16:49:03",
            "2024-09-19 16:50:03",
            "2024-09-19 16:51:03",
            "2024-09-19 16:52:03",
            "2024-09-19 16:53:03",
            "2024-09-19 16:54:03",
            "2024-09-19 16:55:03",
            "2024-09-19 16:56:03",
            "2024-09-19 16:57:03",
            "2024-09-19 16:58:03",
            "2024-09-19 16:59:03",
            "2024-09-19 17:00:03",
            "2024-09-19 17:01:03",
            "2024-09-19 17:02:03",
            "2024-09-19 17:03:03",
            "2024-09-19 17:04:03",
            "2024-09-19 17:05:03",
            "2024-09-19 17:06:03",
            "2024-09-19 17:07:03",
            "2024-09-19 17:08:03",
            "2024-09-19 17:09:03",
            "2024-09-19 17:10:03",
            "2024-09-19 17:11:03",
            "2024-09-19 17:12:03",
            "2024-09-19 17:13:03",
            "2024-09-19 17:14:03",
            "2024-09-19 17:15:03",
            "2024-09-19 17:16:03",
            "2024-09-19 17:17:03",
            "2024-09-19 17:18:03",
            "2024-09-19 17:19:03",
            "2024-09-19 17:20:03",
            "2024-09-19 17:21:03",
            "2024-09-19 17:22:03",
            "2024-09-19 17:23:03",
            "2024-09-19 17:24:03",
            "2024-09-19 17:25:03",
            "2024-09-19 17:26:03",
            "2024-09-19 17:27:03",
            "2024-09-19 17:28:03",
            "2024-09-19 17:29:03",
            "2024-09-19 17:30:03",
            "2024-09-19 17:31:03",
            "2024-09-19 17:32:03",
            "2024-09-19 17:33:03",
            "2024-09-19 17:34:03",
            "2024-09-19 17:35:03",
            "2024-09-19 17:36:03",
            "2024-09-19 17:37:03",
            "2024-09-19 17:38:03",
            "2024-09-19 17:39:03",
            "2024-09-19 17:40:03",
            "2024-09-19 17:41:03",
            "2024-09-19 17:42:03",
            "2024-09-19 17:43:03",
            "2024-09-19 17:44:03",
            "2024-09-19 17:45:03",
            "2024-09-19 17:46:03",
            "2024-09-19 17:47:03",
            "2024-09-19 17:48:03",
            "2024-09-19 17:49:03",
            "2024-09-19 17:50:03",
            "2024-09-19 17:51:03",
            "2024-09-19 17:52:03",
            "2024-09-19 17:53:03",
            "2024-09-19 17:54:03",
            "2024-09-19 17:55:03",
            "2024-09-19 17:56:03",
            "2024-09-19 17:57:03",
            "2024-09-19 17:58:03",
            "2024-09-19 17:59:03",
            "2024-09-19 18:00:03",
            "2024-09-19 18:01:03",
            "2024-09-19 18:02:03",
            "2024-09-19 18:03:03",
            "2024-09-19 18:04:03",
            "2024-09-19 18:05:03",
            "2024-09-19 18:06:03",
            "2024-09-19 18:07:03",
            "2024-09-19 18:08:03",
            "2024-09-19 18:09:03",
            "2024-09-19 18:10:04",
            "2024-09-19 18:11:03",
            "2024-09-19 18:12:03",
            "2024-09-19 18:13:03",
            "2024-09-19 18:14:04",
            "2024-09-19 18:15:04",
            "2024-09-19 18:16:04",
            "2024-09-19 18:17:04",
            "2024-09-19 18:18:04",
            "2024-09-19 18:19:04",
            "2024-09-19 18:20:04",
            "2024-09-19 18:21:04",
            "2024-09-19 18:22:04",
            "2024-09-19 18:23:04",
            "2024-09-19 18:24:04",
            "2024-09-19 18:25:04",
            "2024-09-19 18:26:04",
            "2024-09-19 18:27:04",
            "2024-09-19 18:28:04",
            "2024-09-19 18:29:04",
            "2024-09-19 18:30:04",
            "2024-09-19 18:31:04",
            "2024-09-19 18:32:04",
            "2024-09-19 18:33:04",
            "2024-09-19 18:34:04",
            "2024-09-19 18:35:04",
            "2024-09-19 18:36:04",
            "2024-09-19 18:37:04",
            "2024-09-19 18:38:04",
            "2024-09-19 18:39:04",
            "2024-09-19 18:40:04",
            "2024-09-19 18:41:04",
            "2024-09-19 18:42:04",
            "2024-09-19 18:43:04",
            "2024-09-19 18:44:04",
            "2024-09-19 18:45:04",
            "2024-09-19 18:46:04",
            "2024-09-19 18:47:04",
            "2024-09-19 18:48:04",
            "2024-09-19 18:49:04",
            "2024-09-19 18:50:04",
            "2024-09-19 18:51:04",
            "2024-09-19 18:52:04",
            "2024-09-19 18:53:04",
            "2024-09-19 18:54:04",
            "2024-09-19 18:55:04",
            "2024-09-19 18:56:04",
            "2024-09-19 18:57:04",
            "2024-09-19 18:58:04",
            "2024-09-19 18:59:04",
            "2024-09-19 19:00:04",
            "2024-09-19 19:01:04",
            "2024-09-19 19:02:04",
            "2024-09-19 19:03:04",
            "2024-09-19 19:04:04",
            "2024-09-19 19:05:04",
            "2024-09-19 19:06:04",
            "2024-09-19 19:07:04",
            "2024-09-19 19:08:04",
            "2024-09-19 19:09:04",
            "2024-09-19 19:10:04",
            "2024-09-19 19:11:04",
            "2024-09-19 19:12:04",
            "2024-09-19 19:13:04",
            "2024-09-19 19:14:04",
            "2024-09-19 19:15:04",
            "2024-09-19 19:16:04",
            "2024-09-19 19:17:04",
            "2024-09-19 19:18:04",
            "2024-09-19 19:19:04",
            "2024-09-19 19:20:04",
            "2024-09-19 19:21:04",
            "2024-09-19 19:22:04",
            "2024-09-19 19:23:04",
            "2024-09-19 19:24:04",
            "2024-09-19 19:25:04",
            "2024-09-19 19:26:04",
            "2024-09-19 19:27:04",
            "2024-09-19 19:28:04",
            "2024-09-19 19:29:04",
            "2024-09-19 19:30:04",
            "2024-09-19 19:31:04",
            "2024-09-19 19:32:04",
            "2024-09-19 19:33:04",
            "2024-09-19 19:34:04",
            "2024-09-19 19:35:04",
            "2024-09-19 19:36:04",
            "2024-09-19 19:37:04",
            "2024-09-19 19:38:04",
            "2024-09-19 19:39:04",
            "2024-09-19 19:40:04",
            "2024-09-19 19:41:04",
            "2024-09-19 19:42:04",
            "2024-09-19 19:43:04",
            "2024-09-19 19:44:04",
            "2024-09-19 19:45:04",
            "2024-09-19 19:46:04",
            "2024-09-19 19:47:04",
            "2024-09-19 19:48:04",
            "2024-09-19 19:49:04",
            "2024-09-19 19:50:04",
            "2024-09-19 19:51:04",
            "2024-09-19 19:52:04",
            "2024-09-19 19:53:04",
            "2024-09-19 19:54:04",
            "2024-09-19 19:55:04",
            "2024-09-19 19:56:04",
            "2024-09-19 19:57:04",
            "2024-09-19 19:58:04",
            "2024-09-19 19:59:04",
            "2024-09-19 20:00:04",
            "2024-09-19 20:01:04",
            "2024-09-19 20:02:04",
            "2024-09-19 20:03:04",
            "2024-09-19 20:04:04",
            "2024-09-19 20:05:04",
            "2024-09-19 20:06:04",
            "2024-09-19 20:07:04",
            "2024-09-19 20:08:04",
            "2024-09-19 20:09:04",
            "2024-09-19 20:10:04",
            "2024-09-19 20:11:04",
            "2024-09-19 20:12:04",
            "2024-09-19 20:13:04",
            "2024-09-19 20:14:04",
            "2024-09-19 20:15:04",
            "2024-09-19 20:16:04",
            "2024-09-19 20:17:04",
            "2024-09-19 20:18:04",
            "2024-09-19 20:19:04",
            "2024-09-19 20:20:04",
            "2024-09-19 20:21:04",
            "2024-09-19 20:22:04",
            "2024-09-19 20:23:04",
            "2024-09-19 20:24:04",
            "2024-09-19 20:25:04",
            "2024-09-19 20:26:04",
            "2024-09-19 20:27:04",
            "2024-09-19 20:28:04",
            "2024-09-19 20:29:04",
            "2024-09-19 20:30:04",
            "2024-09-19 20:31:04",
            "2024-09-19 20:32:04",
            "2024-09-19 20:33:04",
            "2024-09-19 20:34:04",
            "2024-09-19 20:35:04",
            "2024-09-19 20:36:04",
            "2024-09-19 20:37:04",
            "2024-09-19 20:38:04",
            "2024-09-19 20:39:04",
            "2024-09-19 20:40:04",
            "2024-09-19 20:41:04",
            "2024-09-19 20:42:04",
            "2024-09-19 20:43:04",
            "2024-09-19 20:44:04",
            "2024-09-19 20:45:04",
            "2024-09-19 20:46:04",
            "2024-09-19 20:47:04",
            "2024-09-19 20:48:04",
            "2024-09-19 20:49:04",
            "2024-09-19 20:50:04",
            "2024-09-19 20:51:04",
            "2024-09-19 20:52:04",
            "2024-09-19 20:53:04",
            "2024-09-19 20:54:04",
            "2024-09-19 20:55:04",
            "2024-09-19 20:56:04",
            "2024-09-19 20:57:04",
            "2024-09-19 20:58:04",
            "2024-09-19 20:59:04",
            "2024-09-19 21:00:04",
            "2024-09-19 21:01:04",
            "2024-09-19 21:02:04",
            "2024-09-19 21:03:04",
            "2024-09-19 21:04:04",
            "2024-09-19 21:05:04",
            "2024-09-19 21:06:04",
            "2024-09-19 21:07:04",
            "2024-09-19 21:08:04",
            "2024-09-19 21:09:04"
          ],
          "series": [
            {
              "name": "Smart Aeration System",
              "unit": "mg/L",
              "target": [
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12,
                1.12
              ],
              "predict_target": [
                1.44,
                1.44,
                1.44,
                1.44,
                1.44,
                1.44,
                1.44,
                1.44,
                1.44,
                1.44,
                1.44,
                1.44,
                1.44,
                1.44,
                1.44,
                1.44,
                1.44,
                1.44,
                1.44,
                1.44,
                1.33,
                1.33,
                1.33,
                1.33,
                1.33,
                1.33,
                1.33,
                1.33,
                1.33,
                1.33,
                1.33,
                1.33,
                1.33,
                1.33,
                1.33,
                1.33,
                1.33,
                1.33,
                1.33,
                1.33,
                1.33,
                1.33,
                1.33,
                1.33,
                1.33,
                1.33,
                1.33,
                1.33,
                1.33,
                1.33,
                1.0,
                1.0,
                1.0,
                1.0,
                1.0,
                1.0,
                1.0,
                1.0,
                1.0,
                1.0,
                1.0,
                1.0,
                1.0,
                1.0,
                1.0,
                1.0,
                1.0,
                1.0,
                1.0,
                1.0,
                1.0,
                1.0,
                1.0,
                1.0,
                1.0,
                1.0,
                1.0,
                1.0,
                1.0,
                1.0,
                1.42,
                1.42,
                1.42,
                1.42,
                1.42,
                1.42,
                1.42,
                1.42,
                1.42,
                1.42,
                1.42,
                1.42,
                1.42,
                1.42,
                1.42,
                1.42,
                1.42,
                1.42,
                1.42,
                1.42,
                1.42,
                1.42,
                1.42,
                1.42,
                1.42,
                1.42,
                1.42,
                1.42,
                1.42,
                1.29,
                1.29,
                1.29,
                1.29,
                1.29,
                1.29,
                1.29,
                1.29,
                1.29,
                1.29,
                1.29,
                1.29,
                1.29,
                1.29,
                1.29,
                1.29,
                1.29,
                1.29,
                1.29,
                1.29,
                1.29,
                1.29,
                1.29,
                1.29,
                1.29,
                1.29,
                1.29,
                1.29,
                1.29,
                1.29,
                1.37,
                1.37,
                1.37,
                1.37,
                1.37,
                1.37,
                1.37,
                1.37,
                1.37,
                1.37,
                1.37,
                1.37,
                1.37,
                1.37,
                1.37,
                1.37,
                1.37,
                1.37,
                1.37,
                1.37,
                1.37,
                1.37,
                1.37,
                1.37,
                1.37,
                1.37,
                1.37,
                1.37,
                1.37,
                1.37,
                1.5,
                1.5,
                1.5,
                1.5,
                1.5,
                1.5,
                1.5,
                1.5,
                1.5,
                1.5,
                1.5,
                1.5,
                1.5,
                1.5,
                1.5,
                1.5,
                1.5,
                1.5,
                1.5,
                1.5,
                1.5,
                1.5,
                1.5,
                1.5,
                1.5,
                1.5,
                1.5,
                1.5,
                1.5,
                1.5,
                1.38,
                1.38,
                1.38,
                1.38,
                1.38,
                1.38,
                1.38,
                1.38,
                1.38,
                1.38,
                1.38,
                1.38,
                1.38,
                1.38,
                1.38,
                1.38,
                1.38,
                1.38,
                1.38,
                1.38,
                1.38,
                1.38,
                1.38,
                1.38,
                1.38,
                1.38,
                1.38,
                1.38,
                1.38,
                1.36,
                1.36,
                1.36,
                1.36,
                1.36,
                1.36,
                1.36,
                1.36,
                1.36,
                1.36,
                1.36,
                1.36,
                1.36,
                1.36,
                1.36,
                1.36,
                1.36,
                1.36,
                1.36,
                1.36,
                1.36,
                1.36,
                1.36,
                1.36,
                1.36,
                1.36,
                1.36,
                1.36,
                1.36,
                1.36,
                1.5,
                1.5,
                1.5,
                1.5,
                1.5,
                1.5,
                1.5,
                1.5,
                1.5,
                1.5,
                1.5,
                1.5,
                1.5,
                1.5,
                1.5,
                1.5,
                1.5,
                1.5,
                1.5,
                1.5,
                1.5,
                1.5,
                1.5,
                1.5,
                1.5,
                1.5,
                1.5,
                1.5,
                1.5,
                1.5,
                1.31,
                1.31,
                1.31,
                1.31,
                1.31,
                1.31,
                1.31,
                1.31,
                1.31,
                1.31,
                1.31,
                1.31,
                1.31,
                1.31,
                1.31,
                1.31,
                1.31,
                1.31,
                1.31,
                1.31,
                1.31,
                1.31,
                1.31,
                1.31,
                1.31,
                1.31,
                1.31,
                1.31,
                1.31,
                1.33,
                1.33,
                1.33,
                1.33,
                1.33,
                1.33,
                1.33,
                1.33,
                1.33,
                1.33,
                1.33,
                1.33,
                1.33,
                1.33,
                1.33,
                1.33,
                1.33,
                1.33,
                1.33,
                1.33,
                1.33,
                1.33,
                1.33,
                1.33,
                1.33,
                1.33,
                1.33,
                1.33,
                1.33,
                1.33,
                1.35,
                1.35,
                1.35,
                1.35,
                1.35,
                1.35,
                1.35,
                1.35,
                1.35,
                1.35,
                1.35,
                1.35,
                1.35
              ],
              "value": [
                1.13,
                1.14,
                1.14,
                1.16,
                1.17,
                1.17,
                1.19,
                1.23,
                1.2,
                1.23,
                1.24,
                1.25,
                1.27,
                1.28,
                1.3,
                1.29,
                1.27,
                1.29,
                1.3,
                1.32,
                1.31,
                1.3,
                1.27,
                1.25,
                1.28,
                1.26,
                1.25,
                1.24,
                1.22,
                1.24,
                1.19,
                1.19,
                1.12,
                1.12,
                1.12,
                1.12,
                1.1,
                1.12,
                1.11,
                1.11,
                1.12,
                1.15,
                1.13,
                1.12,
                1.16,
                1.15,
                1.14,
                1.15,
                1.14,
                1.18,
                1.15,
                1.19,
                1.2,
                1.23,
                1.23,
                1.21,
                1.23,
                1.3,
                1.34,
                1.33,
                1.34,
                1.32,
                1.36,
                1.37,
                1.36,
                1.37,
                1.36,
                1.37,
                1.35,
                1.35,
                1.34,
                1.32,
                1.29,
                1.3,
                1.25,
                1.24,
                1.21,
                1.2,
                1.23,
                1.22,
                1.2,
                1.18,
                1.17,
                1.15,
                1.16,
                1.14,
                1.14,
                1.16,
                1.18,
                1.2,
                1.16,
                1.17,
                1.18,
                1.17,
                1.2,
                1.22,
                1.21,
                1.21,
                1.27,
                1.3,
                1.3,
                1.29,
                1.29,
                1.3,
                1.35,
                1.37,
                1.38,
                1.34,
                1.35,
                1.36,
                1.34,
                1.34,
                1.31,
                1.29,
                1.26,
                1.26,
                1.25,
                1.25,
                1.24,
                1.23,
                1.24,
                1.24,
                1.22,
                1.19,
                1.18,
                1.18,
                1.18,
                1.17,
                1.14,
                1.16,
                1.16,
                1.12,
                1.1,
                1.09,
                1.14,
                1.16,
                1.19,
                1.19,
                1.18,
                1.2,
                1.2,
                1.21,
                1.23,
                1.28,
                1.27,
                1.29,
                1.27,
                1.28,
                1.3,
                1.31,
                1.29,
                1.32,
                1.34,
                1.36,
                1.32,
                1.33,
                1.32,
                1.34,
                1.33,
                1.31,
                1.3,
                1.29,
                1.29,
                1.26,
                1.26,
                1.22,
                1.21,
                1.16,
                1.17,
                1.2,
                1.2,
                1.18,
                1.16,
                1.17,
                1.16,
                1.18,
                1.18,
                1.19,
                1.21,
                1.2,
                1.22,
                1.26,
                1.24,
                1.23,
                1.25,
                1.25,
                1.28,
                1.28,
                1.27,
                1.28,
                1.29,
                1.32,
                1.34,
                1.35,
                1.33,
                1.36,
                1.31,
                1.3,
                1.3,
                1.29,
                1.27,
                1.25,
                1.25,
                1.22,
                1.2,
                1.15,
                1.16,
                1.14,
                1.11,
                1.12,
                1.13,
                1.12,
                1.1,
                1.08,
                1.08,
                1.09,
                1.1,
                1.09,
                1.13,
                1.16,
                1.17,
                1.18,
                1.21,
                1.22,
                1.24,
                1.29,
                1.3,
                1.31,
                1.3,
                1.29,
                1.28,
                1.3,
                1.31,
                1.32,
                1.31,
                1.32,
                1.28,
                1.27,
                1.27,
                1.26,
                1.24,
                1.23,
                1.19,
                1.18,
                1.18,
                1.17,
                1.17,
                1.13,
                1.14,
                1.16,
                1.17,
                1.13,
                1.12,
                1.11,
                1.11,
                1.11,
                1.13,
                1.14,
                1.17,
                1.16,
                1.16,
                1.18,
                1.2,
                1.18,
                1.22,
                1.25,
                1.27,
                1.24,
                1.24,
                1.27,
                1.27,
                1.29,
                1.3,
                1.27,
                1.27,
                1.29,
                1.29,
                1.32,
                1.28,
                1.23,
                1.24,
                1.2,
                1.19,
                1.2,
                1.19,
                1.18,
                1.2,
                1.17,
                1.17,
                1.16,
                1.15,
                1.17,
                1.15,
                1.14,
                1.12,
                1.11,
                1.13,
                1.12,
                1.12,
                1.13,
                1.4,
                1.39,
                1.41,
                1.35,
                1.2,
                1.35,
                1.38,
                1.48,
                1.32,
                1.25,
                1.23,
                1.27,
                1.44,
                1.3,
                1.33,
                1.4,
                1.33,
                1.41,
                1.44,
                1.3,
                1.48,
                1.43,
                1.15,
                1.44,
                1.42,
                1.45,
                1.35,
                1.38,
                1.37,
                1.3,
                1.43,
                1.39,
                1.21,
                1.42,
                1.33,
                1.24,
                1.27,
                1.24,
                1.28,
                1.3,
                1.53,
                1.32,
                1.12,
                1.34,
                1.37,
                1.24,
                1.4,
                1.25,
                1.29,
                1.36,
                1.27,
                1.34,
                1.46,
                1.26,
                1.45,
                1.15,
                1.42,
                1.51,
                1.22,
                1.6
              ]
            }
          ],
          "parting": "2024-09-19 20:09:04"
        },
        AO_DN: {
          "xAxis": [
            "2024-09-19 15:10:02",
            "2024-09-19 15:11:03",
            "2024-09-19 15:12:02",
            "2024-09-19 15:13:02",
            "2024-09-19 15:14:02",
            "2024-09-19 15:15:02",
            "2024-09-19 15:16:03",
            "2024-09-19 15:17:03",
            "2024-09-19 15:18:02",
            "2024-09-19 15:19:03",
            "2024-09-19 15:20:03",
            "2024-09-19 15:21:03",
            "2024-09-19 15:22:03",
            "2024-09-19 15:23:03",
            "2024-09-19 15:24:03",
            "2024-09-19 15:25:03",
            "2024-09-19 15:26:03",
            "2024-09-19 15:27:03",
            "2024-09-19 15:28:03",
            "2024-09-19 15:29:03",
            "2024-09-19 15:30:03",
            "2024-09-19 15:31:03",
            "2024-09-19 15:32:03",
            "2024-09-19 15:33:03",
            "2024-09-19 15:34:03",
            "2024-09-19 15:35:03",
            "2024-09-19 15:36:03",
            "2024-09-19 15:37:03",
            "2024-09-19 15:38:03",
            "2024-09-19 15:39:03",
            "2024-09-19 15:40:03",
            "2024-09-19 15:41:03",
            "2024-09-19 15:42:03",
            "2024-09-19 15:43:03",
            "2024-09-19 15:44:03",
            "2024-09-19 15:45:03",
            "2024-09-19 15:46:03",
            "2024-09-19 15:47:03",
            "2024-09-19 15:48:03",
            "2024-09-19 15:49:03",
            "2024-09-19 15:50:03",
            "2024-09-19 15:51:03",
            "2024-09-19 15:52:03",
            "2024-09-19 15:53:03",
            "2024-09-19 15:54:03",
            "2024-09-19 15:55:03",
            "2024-09-19 15:56:03",
            "2024-09-19 15:57:03",
            "2024-09-19 15:58:03",
            "2024-09-19 15:59:03",
            "2024-09-19 16:00:03",
            "2024-09-19 16:01:03",
            "2024-09-19 16:02:03",
            "2024-09-19 16:03:03",
            "2024-09-19 16:04:03",
            "2024-09-19 16:05:03",
            "2024-09-19 16:06:03",
            "2024-09-19 16:07:03",
            "2024-09-19 16:08:03",
            "2024-09-19 16:09:03",
            "2024-09-19 16:10:03",
            "2024-09-19 16:11:03",
            "2024-09-19 16:12:03",
            "2024-09-19 16:13:03",
            "2024-09-19 16:14:03",
            "2024-09-19 16:15:03",
            "2024-09-19 16:16:03",
            "2024-09-19 16:17:03",
            "2024-09-19 16:18:03",
            "2024-09-19 16:19:03",
            "2024-09-19 16:20:03",
            "2024-09-19 16:21:03",
            "2024-09-19 16:22:03",
            "2024-09-19 16:23:03",
            "2024-09-19 16:24:03",
            "2024-09-19 16:25:03",
            "2024-09-19 16:26:03",
            "2024-09-19 16:27:03",
            "2024-09-19 16:28:03",
            "2024-09-19 16:29:03",
            "2024-09-19 16:30:03",
            "2024-09-19 16:31:03",
            "2024-09-19 16:32:03",
            "2024-09-19 16:33:03",
            "2024-09-19 16:34:03",
            "2024-09-19 16:35:03",
            "2024-09-19 16:36:03",
            "2024-09-19 16:37:03",
            "2024-09-19 16:38:03",
            "2024-09-19 16:39:03",
            "2024-09-19 16:40:03",
            "2024-09-19 16:41:03",
            "2024-09-19 16:42:03",
            "2024-09-19 16:43:03",
            "2024-09-19 16:44:03",
            "2024-09-19 16:45:03",
            "2024-09-19 16:46:03",
            "2024-09-19 16:47:03",
            "2024-09-19 16:48:03",
            "2024-09-19 16:49:03",
            "2024-09-19 16:50:03",
            "2024-09-19 16:51:03",
            "2024-09-19 16:52:03",
            "2024-09-19 16:53:03",
            "2024-09-19 16:54:03",
            "2024-09-19 16:55:03",
            "2024-09-19 16:56:03",
            "2024-09-19 16:57:03",
            "2024-09-19 16:58:03",
            "2024-09-19 16:59:03",
            "2024-09-19 17:00:03",
            "2024-09-19 17:01:03",
            "2024-09-19 17:02:03",
            "2024-09-19 17:03:03",
            "2024-09-19 17:04:03",
            "2024-09-19 17:05:03",
            "2024-09-19 17:06:03",
            "2024-09-19 17:07:03",
            "2024-09-19 17:08:03",
            "2024-09-19 17:09:03",
            "2024-09-19 17:10:03",
            "2024-09-19 17:11:03",
            "2024-09-19 17:12:03",
            "2024-09-19 17:13:03",
            "2024-09-19 17:14:03",
            "2024-09-19 17:15:03",
            "2024-09-19 17:16:03",
            "2024-09-19 17:17:03",
            "2024-09-19 17:18:03",
            "2024-09-19 17:19:03",
            "2024-09-19 17:20:03",
            "2024-09-19 17:21:03",
            "2024-09-19 17:22:03",
            "2024-09-19 17:23:03",
            "2024-09-19 17:24:03",
            "2024-09-19 17:25:03",
            "2024-09-19 17:26:03",
            "2024-09-19 17:27:03",
            "2024-09-19 17:28:03",
            "2024-09-19 17:29:03",
            "2024-09-19 17:30:03",
            "2024-09-19 17:31:03",
            "2024-09-19 17:32:03",
            "2024-09-19 17:33:03",
            "2024-09-19 17:34:03",
            "2024-09-19 17:35:03",
            "2024-09-19 17:36:03",
            "2024-09-19 17:37:03",
            "2024-09-19 17:38:03",
            "2024-09-19 17:39:03",
            "2024-09-19 17:40:03",
            "2024-09-19 17:41:03",
            "2024-09-19 17:42:03",
            "2024-09-19 17:43:03",
            "2024-09-19 17:44:03",
            "2024-09-19 17:45:03",
            "2024-09-19 17:46:03",
            "2024-09-19 17:47:03",
            "2024-09-19 17:48:03",
            "2024-09-19 17:49:03",
            "2024-09-19 17:50:03",
            "2024-09-19 17:51:03",
            "2024-09-19 17:52:03",
            "2024-09-19 17:53:03",
            "2024-09-19 17:54:03",
            "2024-09-19 17:55:03",
            "2024-09-19 17:56:03",
            "2024-09-19 17:57:03",
            "2024-09-19 17:58:03",
            "2024-09-19 17:59:03",
            "2024-09-19 18:00:03",
            "2024-09-19 18:01:03",
            "2024-09-19 18:02:03",
            "2024-09-19 18:03:03",
            "2024-09-19 18:04:03",
            "2024-09-19 18:05:03",
            "2024-09-19 18:06:03",
            "2024-09-19 18:07:03",
            "2024-09-19 18:08:03",
            "2024-09-19 18:09:03",
            "2024-09-19 18:10:04",
            "2024-09-19 18:11:03",
            "2024-09-19 18:12:03",
            "2024-09-19 18:13:03",
            "2024-09-19 18:14:04",
            "2024-09-19 18:15:04",
            "2024-09-19 18:16:04",
            "2024-09-19 18:17:04",
            "2024-09-19 18:18:04",
            "2024-09-19 18:19:04",
            "2024-09-19 18:20:04",
            "2024-09-19 18:21:04",
            "2024-09-19 18:22:04",
            "2024-09-19 18:23:04",
            "2024-09-19 18:24:04",
            "2024-09-19 18:25:04",
            "2024-09-19 18:26:04",
            "2024-09-19 18:27:04",
            "2024-09-19 18:28:04",
            "2024-09-19 18:29:04",
            "2024-09-19 18:30:04",
            "2024-09-19 18:31:04",
            "2024-09-19 18:32:04",
            "2024-09-19 18:33:04",
            "2024-09-19 18:34:04",
            "2024-09-19 18:35:04",
            "2024-09-19 18:36:04",
            "2024-09-19 18:37:04",
            "2024-09-19 18:38:04",
            "2024-09-19 18:39:04",
            "2024-09-19 18:40:04",
            "2024-09-19 18:41:04",
            "2024-09-19 18:42:04",
            "2024-09-19 18:43:04",
            "2024-09-19 18:44:04",
            "2024-09-19 18:45:04",
            "2024-09-19 18:46:04",
            "2024-09-19 18:47:04",
            "2024-09-19 18:48:04",
            "2024-09-19 18:49:04",
            "2024-09-19 18:50:04",
            "2024-09-19 18:51:04",
            "2024-09-19 18:52:04",
            "2024-09-19 18:53:04",
            "2024-09-19 18:54:04",
            "2024-09-19 18:55:04",
            "2024-09-19 18:56:04",
            "2024-09-19 18:57:04",
            "2024-09-19 18:58:04",
            "2024-09-19 18:59:04",
            "2024-09-19 19:00:04",
            "2024-09-19 19:01:04",
            "2024-09-19 19:02:04",
            "2024-09-19 19:03:04",
            "2024-09-19 19:04:04",
            "2024-09-19 19:05:04",
            "2024-09-19 19:06:04",
            "2024-09-19 19:07:04",
            "2024-09-19 19:08:04",
            "2024-09-19 19:09:04",
            "2024-09-19 19:10:04",
            "2024-09-19 19:11:04",
            "2024-09-19 19:12:04",
            "2024-09-19 19:13:04",
            "2024-09-19 19:14:04",
            "2024-09-19 19:15:04",
            "2024-09-19 19:16:04",
            "2024-09-19 19:17:04",
            "2024-09-19 19:18:04",
            "2024-09-19 19:19:04",
            "2024-09-19 19:20:04",
            "2024-09-19 19:21:04",
            "2024-09-19 19:22:04",
            "2024-09-19 19:23:04",
            "2024-09-19 19:24:04",
            "2024-09-19 19:25:04",
            "2024-09-19 19:26:04",
            "2024-09-19 19:27:04",
            "2024-09-19 19:28:04",
            "2024-09-19 19:29:04",
            "2024-09-19 19:30:04",
            "2024-09-19 19:31:04",
            "2024-09-19 19:32:04",
            "2024-09-19 19:33:04",
            "2024-09-19 19:34:04",
            "2024-09-19 19:35:04",
            "2024-09-19 19:36:04",
            "2024-09-19 19:37:04",
            "2024-09-19 19:38:04",
            "2024-09-19 19:39:04",
            "2024-09-19 19:40:04",
            "2024-09-19 19:41:04",
            "2024-09-19 19:42:04",
            "2024-09-19 19:43:04",
            "2024-09-19 19:44:04",
            "2024-09-19 19:45:04",
            "2024-09-19 19:46:04",
            "2024-09-19 19:47:04",
            "2024-09-19 19:48:04",
            "2024-09-19 19:49:04",
            "2024-09-19 19:50:04",
            "2024-09-19 19:51:04",
            "2024-09-19 19:52:04",
            "2024-09-19 19:53:04",
            "2024-09-19 19:54:04",
            "2024-09-19 19:55:04",
            "2024-09-19 19:56:04",
            "2024-09-19 19:57:04",
            "2024-09-19 19:58:04",
            "2024-09-19 19:59:04",
            "2024-09-19 20:00:04",
            "2024-09-19 20:01:04",
            "2024-09-19 20:02:04",
            "2024-09-19 20:03:04",
            "2024-09-19 20:04:04",
            "2024-09-19 20:05:04",
            "2024-09-19 20:06:04",
            "2024-09-19 20:07:04",
            "2024-09-19 20:08:04",
            "2024-09-19 20:09:04",
            "2024-09-19 20:10:04",
            "2024-09-19 20:11:04",
            "2024-09-19 20:12:04",
            "2024-09-19 20:13:04",
            "2024-09-19 20:14:04",
            "2024-09-19 20:15:04",
            "2024-09-19 20:16:04",
            "2024-09-19 20:17:04",
            "2024-09-19 20:18:04",
            "2024-09-19 20:19:04",
            "2024-09-19 20:20:04",
            "2024-09-19 20:21:04",
            "2024-09-19 20:22:04",
            "2024-09-19 20:23:04",
            "2024-09-19 20:24:04",
            "2024-09-19 20:25:04",
            "2024-09-19 20:26:04",
            "2024-09-19 20:27:04",
            "2024-09-19 20:28:04",
            "2024-09-19 20:29:04",
            "2024-09-19 20:30:04",
            "2024-09-19 20:31:04",
            "2024-09-19 20:32:04",
            "2024-09-19 20:33:04",
            "2024-09-19 20:34:04",
            "2024-09-19 20:35:04",
            "2024-09-19 20:36:04",
            "2024-09-19 20:37:04",
            "2024-09-19 20:38:04",
            "2024-09-19 20:39:04",
            "2024-09-19 20:40:04",
            "2024-09-19 20:41:04",
            "2024-09-19 20:42:04",
            "2024-09-19 20:43:04",
            "2024-09-19 20:44:04",
            "2024-09-19 20:45:04",
            "2024-09-19 20:46:04",
            "2024-09-19 20:47:04",
            "2024-09-19 20:48:04",
            "2024-09-19 20:49:04",
            "2024-09-19 20:50:04",
            "2024-09-19 20:51:04",
            "2024-09-19 20:52:04",
            "2024-09-19 20:53:04",
            "2024-09-19 20:54:04",
            "2024-09-19 20:55:04",
            "2024-09-19 20:56:04",
            "2024-09-19 20:57:04",
            "2024-09-19 20:58:04",
            "2024-09-19 20:59:04",
            "2024-09-19 21:00:04",
            "2024-09-19 21:01:04",
            "2024-09-19 21:02:04",
            "2024-09-19 21:03:04",
            "2024-09-19 21:04:04",
            "2024-09-19 21:05:04",
            "2024-09-19 21:06:04",
            "2024-09-19 21:07:04",
            "2024-09-19 21:08:04",
            "2024-09-19 21:09:04"
          ],
          "series": [
            {
              "name": "Smart Denitrification Control System",
              "unit": "mg/L",
              "target": [
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8
              ],
              "predict_target": [
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8,
                7.8
              ],
              "value": [
                6.13,
                6.18,
                6.16,
                6.24,
                6.53,
                6.59,
                6.52,
                6.36,
                6.37,
                5.95,
                5.93,
                6.01,
                5.84,
                5.88,
                6.13,
                6.08,
                6.11,
                6.08,
                6.06,
                5.87,
                5.88,
                5.84,
                5.88,
                5.94,
                6.26,
                6.22,
                6.23,
                6.27,
                6.3,
                6.42,
                6.38,
                6.34,
                6.4,
                6.35,
                5.92,
                5.95,
                5.97,
                6.01,
                5.9,
                6.3,
                6.27,
                6.26,
                6.26,
                6.26,
                6.25,
                6.26,
                6.2,
                6.29,
                6.25,
                6.14,
                6.22,
                6.15,
                6.1,
                6.22,
                6.29,
                6.32,
                6.22,
                6.35,
                6.41,
                6.32,
                6.31,
                6.37,
                6.32,
                6.39,
                6.38,
                6.27,
                6.38,
                6.46,
                6.41,
                6.33,
                6.33,
                6.29,
                6.35,
                6.35,
                6.41,
                6.43,
                6.43,
                6.35,
                6.36,
                6.41,
                6.52,
                6.45,
                6.43,
                6.49,
                6.19,
                6.17,
                6.19,
                6.15,
                6.15,
                6.35,
                6.35,
                6.33,
                6.54,
                6.52,
                6.31,
                6.4,
                6.36,
                6.49,
                6.42,
                6.53,
                6.49,
                6.69,
                6.51,
                6.54,
                6.53,
                6.46,
                6.49,
                6.43,
                6.43,
                6.51,
                6.56,
                6.5,
                6.46,
                6.37,
                6.27,
                6.31,
                6.31,
                6.38,
                6.36,
                6.49,
                6.45,
                6.44,
                6.54,
                6.52,
                6.65,
                6.62,
                6.71,
                6.77,
                6.83,
                6.63,
                6.59,
                6.63,
                6.62,
                6.54,
                6.73,
                6.73,
                6.78,
                6.66,
                6.57,
                6.74,
                6.72,
                6.72,
                6.66,
                6.8,
                6.23,
                6.27,
                6.23,
                6.17,
                6.11,
                6.93,
                6.89,
                6.97,
                7.06,
                7.07,
                6.37,
                6.4,
                6.37,
                6.3,
                6.46,
                6.1,
                6.11,
                6.1,
                6.34,
                6.35,
                6.37,
                6.37,
                6.39,
                6.21,
                6.29,
                6.0,
                6.05,
                6.02,
                6.1,
                6.13,
                6.5,
                6.56,
                6.51,
                6.31,
                6.24,
                6.04,
                6.04,
                6.03,
                6.27,
                6.27,
                6.53,
                6.58,
                6.48,
                6.54,
                6.53,
                6.47,
                6.53,
                6.56,
                6.6,
                6.57,
                6.52,
                6.56,
                6.62,
                6.47,
                6.53,
                6.35,
                6.39,
                6.32,
                6.28,
                6.25,
                6.04,
                6.08,
                6.08,
                6.19,
                6.18,
                6.28,
                6.19,
                6.31,
                6.31,
                6.36,
                6.51,
                6.56,
                6.52,
                6.65,
                6.67,
                6.56,
                6.57,
                6.56,
                6.57,
                6.62,
                6.89,
                6.89,
                6.95,
                6.96,
                6.96,
                6.93,
                6.74,
                6.73,
                6.75,
                6.73,
                6.78,
                6.8,
                6.67,
                6.79,
                6.73,
                6.79,
                6.82,
                6.82,
                6.74,
                6.77,
                6.36,
                6.33,
                6.41,
                6.24,
                6.28,
                6.5,
                6.48,
                6.49,
                6.35,
                6.37,
                6.39,
                6.36,
                6.38,
                6.33,
                6.27,
                6.48,
                6.54,
                6.51,
                6.47,
                6.52,
                6.46,
                6.48,
                6.44,
                6.6,
                6.6,
                6.9,
                6.9,
                6.89,
                6.88,
                6.9,
                6.41,
                6.39,
                6.43,
                6.49,
                6.45,
                6.53,
                6.49,
                6.51,
                6.51,
                6.44,
                6.25,
                6.33,
                6.28,
                6.28,
                6.3,
                6.49,
                6.54,
                6.58,
                6.49,
                6.52,
                6.47,
                6.44,
                6.44,
                6.56,
                6.51,
                6.43,
                7.69,
                7.63,
                7.83,
                7.89,
                7.78,
                7.81,
                7.9,
                7.74,
                7.87,
                7.95,
                7.87,
                7.84,
                7.85,
                7.81,
                7.75,
                7.81,
                7.8,
                7.86,
                7.62,
                7.85,
                7.84,
                8.17,
                7.81,
                7.95,
                7.79,
                7.78,
                7.92,
                7.9,
                7.67,
                7.69,
                7.66,
                7.55,
                7.99,
                7.77,
                7.56,
                7.9,
                7.79,
                7.65,
                8.08,
                7.8,
                7.83,
                7.71,
                7.81,
                7.81,
                7.7,
                7.81,
                7.71,
                7.83,
                7.71,
                7.92,
                7.68,
                7.75,
                7.82,
                7.73,
                7.83,
                7.84,
                7.77,
                7.92,
                7.71,
                7.88
              ]
            }
          ],
          "parting": "2024-09-19 20:09:04"
        },
        OE_O3: {
          "xAxis": [
            "2024-09-19 15:10:02",
            "2024-09-19 15:11:03",
            "2024-09-19 15:12:02",
            "2024-09-19 15:13:02",
            "2024-09-19 15:14:02",
            "2024-09-19 15:15:02",
            "2024-09-19 15:16:03",
            "2024-09-19 15:17:03",
            "2024-09-19 15:18:02",
            "2024-09-19 15:19:03",
            "2024-09-19 15:20:03",
            "2024-09-19 15:21:03",
            "2024-09-19 15:22:03",
            "2024-09-19 15:23:03",
            "2024-09-19 15:24:03",
            "2024-09-19 15:25:03",
            "2024-09-19 15:26:03",
            "2024-09-19 15:27:03",
            "2024-09-19 15:28:03",
            "2024-09-19 15:29:03",
            "2024-09-19 15:30:03",
            "2024-09-19 15:31:03",
            "2024-09-19 15:32:03",
            "2024-09-19 15:33:03",
            "2024-09-19 15:34:03",
            "2024-09-19 15:35:03",
            "2024-09-19 15:36:03",
            "2024-09-19 15:37:03",
            "2024-09-19 15:38:03",
            "2024-09-19 15:39:03",
            "2024-09-19 15:40:03",
            "2024-09-19 15:41:03",
            "2024-09-19 15:42:03",
            "2024-09-19 15:43:03",
            "2024-09-19 15:44:03",
            "2024-09-19 15:45:03",
            "2024-09-19 15:46:03",
            "2024-09-19 15:47:03",
            "2024-09-19 15:48:03",
            "2024-09-19 15:49:03",
            "2024-09-19 15:50:03",
            "2024-09-19 15:51:03",
            "2024-09-19 15:52:03",
            "2024-09-19 15:53:03",
            "2024-09-19 15:54:03",
            "2024-09-19 15:55:03",
            "2024-09-19 15:56:03",
            "2024-09-19 15:57:03",
            "2024-09-19 15:58:03",
            "2024-09-19 15:59:03",
            "2024-09-19 16:00:03",
            "2024-09-19 16:01:03",
            "2024-09-19 16:02:03",
            "2024-09-19 16:03:03",
            "2024-09-19 16:04:03",
            "2024-09-19 16:05:03",
            "2024-09-19 16:06:03",
            "2024-09-19 16:07:03",
            "2024-09-19 16:08:03",
            "2024-09-19 16:09:03",
            "2024-09-19 16:10:03",
            "2024-09-19 16:11:03",
            "2024-09-19 16:12:03",
            "2024-09-19 16:13:03",
            "2024-09-19 16:14:03",
            "2024-09-19 16:15:03",
            "2024-09-19 16:16:03",
            "2024-09-19 16:17:03",
            "2024-09-19 16:18:03",
            "2024-09-19 16:19:03",
            "2024-09-19 16:20:03",
            "2024-09-19 16:21:03",
            "2024-09-19 16:22:03",
            "2024-09-19 16:23:03",
            "2024-09-19 16:24:03",
            "2024-09-19 16:25:03",
            "2024-09-19 16:26:03",
            "2024-09-19 16:27:03",
            "2024-09-19 16:28:03",
            "2024-09-19 16:29:03",
            "2024-09-19 16:30:03",
            "2024-09-19 16:31:03",
            "2024-09-19 16:32:03",
            "2024-09-19 16:33:03",
            "2024-09-19 16:34:03",
            "2024-09-19 16:35:03",
            "2024-09-19 16:36:03",
            "2024-09-19 16:37:03",
            "2024-09-19 16:38:03",
            "2024-09-19 16:39:03",
            "2024-09-19 16:40:03",
            "2024-09-19 16:41:03",
            "2024-09-19 16:42:03",
            "2024-09-19 16:43:03",
            "2024-09-19 16:44:03",
            "2024-09-19 16:45:03",
            "2024-09-19 16:46:03",
            "2024-09-19 16:47:03",
            "2024-09-19 16:48:03",
            "2024-09-19 16:49:03",
            "2024-09-19 16:50:03",
            "2024-09-19 16:51:03",
            "2024-09-19 16:52:03",
            "2024-09-19 16:53:03",
            "2024-09-19 16:54:03",
            "2024-09-19 16:55:03",
            "2024-09-19 16:56:03",
            "2024-09-19 16:57:03",
            "2024-09-19 16:58:03",
            "2024-09-19 16:59:03",
            "2024-09-19 17:00:03",
            "2024-09-19 17:01:03",
            "2024-09-19 17:02:03",
            "2024-09-19 17:03:03",
            "2024-09-19 17:04:03",
            "2024-09-19 17:05:03",
            "2024-09-19 17:06:03",
            "2024-09-19 17:07:03",
            "2024-09-19 17:08:03",
            "2024-09-19 17:09:03",
            "2024-09-19 17:10:03",
            "2024-09-19 17:11:03",
            "2024-09-19 17:12:03",
            "2024-09-19 17:13:03",
            "2024-09-19 17:14:03",
            "2024-09-19 17:15:03",
            "2024-09-19 17:16:03",
            "2024-09-19 17:17:03",
            "2024-09-19 17:18:03",
            "2024-09-19 17:19:03",
            "2024-09-19 17:20:03",
            "2024-09-19 17:21:03",
            "2024-09-19 17:22:03",
            "2024-09-19 17:23:03",
            "2024-09-19 17:24:03",
            "2024-09-19 17:25:03",
            "2024-09-19 17:26:03",
            "2024-09-19 17:27:03",
            "2024-09-19 17:28:03",
            "2024-09-19 17:29:03",
            "2024-09-19 17:30:03",
            "2024-09-19 17:31:03",
            "2024-09-19 17:32:03",
            "2024-09-19 17:33:03",
            "2024-09-19 17:34:03",
            "2024-09-19 17:35:03",
            "2024-09-19 17:36:03",
            "2024-09-19 17:37:03",
            "2024-09-19 17:38:03",
            "2024-09-19 17:39:03",
            "2024-09-19 17:40:03",
            "2024-09-19 17:41:03",
            "2024-09-19 17:42:03",
            "2024-09-19 17:43:03",
            "2024-09-19 17:44:03",
            "2024-09-19 17:45:03",
            "2024-09-19 17:46:03",
            "2024-09-19 17:47:03",
            "2024-09-19 17:48:03",
            "2024-09-19 17:49:03",
            "2024-09-19 17:50:03",
            "2024-09-19 17:51:03",
            "2024-09-19 17:52:03",
            "2024-09-19 17:53:03",
            "2024-09-19 17:54:03",
            "2024-09-19 17:55:03",
            "2024-09-19 17:56:03",
            "2024-09-19 17:57:03",
            "2024-09-19 17:58:03",
            "2024-09-19 17:59:03",
            "2024-09-19 18:00:03",
            "2024-09-19 18:01:03",
            "2024-09-19 18:02:03",
            "2024-09-19 18:03:03",
            "2024-09-19 18:04:03",
            "2024-09-19 18:05:03",
            "2024-09-19 18:06:03",
            "2024-09-19 18:07:03",
            "2024-09-19 18:08:03",
            "2024-09-19 18:09:03",
            "2024-09-19 18:10:04",
            "2024-09-19 18:11:03",
            "2024-09-19 18:12:03",
            "2024-09-19 18:13:03",
            "2024-09-19 18:14:04",
            "2024-09-19 18:15:04",
            "2024-09-19 18:16:04",
            "2024-09-19 18:17:04",
            "2024-09-19 18:18:04",
            "2024-09-19 18:19:04",
            "2024-09-19 18:20:04",
            "2024-09-19 18:21:04",
            "2024-09-19 18:22:04",
            "2024-09-19 18:23:04",
            "2024-09-19 18:24:04",
            "2024-09-19 18:25:04",
            "2024-09-19 18:26:04",
            "2024-09-19 18:27:04",
            "2024-09-19 18:28:04",
            "2024-09-19 18:29:04",
            "2024-09-19 18:30:04",
            "2024-09-19 18:31:04",
            "2024-09-19 18:32:04",
            "2024-09-19 18:33:04",
            "2024-09-19 18:34:04",
            "2024-09-19 18:35:04",
            "2024-09-19 18:36:04",
            "2024-09-19 18:37:04",
            "2024-09-19 18:38:04",
            "2024-09-19 18:39:04",
            "2024-09-19 18:40:04",
            "2024-09-19 18:41:04",
            "2024-09-19 18:42:04",
            "2024-09-19 18:43:04",
            "2024-09-19 18:44:04",
            "2024-09-19 18:45:04",
            "2024-09-19 18:46:04",
            "2024-09-19 18:47:04",
            "2024-09-19 18:48:04",
            "2024-09-19 18:49:04",
            "2024-09-19 18:50:04",
            "2024-09-19 18:51:04",
            "2024-09-19 18:52:04",
            "2024-09-19 18:53:04",
            "2024-09-19 18:54:04",
            "2024-09-19 18:55:04",
            "2024-09-19 18:56:04",
            "2024-09-19 18:57:04",
            "2024-09-19 18:58:04",
            "2024-09-19 18:59:04",
            "2024-09-19 19:00:04",
            "2024-09-19 19:01:04",
            "2024-09-19 19:02:04",
            "2024-09-19 19:03:04",
            "2024-09-19 19:04:04",
            "2024-09-19 19:05:04",
            "2024-09-19 19:06:04",
            "2024-09-19 19:07:04",
            "2024-09-19 19:08:04",
            "2024-09-19 19:09:04",
            "2024-09-19 19:10:04",
            "2024-09-19 19:11:04",
            "2024-09-19 19:12:04",
            "2024-09-19 19:13:04",
            "2024-09-19 19:14:04",
            "2024-09-19 19:15:04",
            "2024-09-19 19:16:04",
            "2024-09-19 19:17:04",
            "2024-09-19 19:18:04",
            "2024-09-19 19:19:04",
            "2024-09-19 19:20:04",
            "2024-09-19 19:21:04",
            "2024-09-19 19:22:04",
            "2024-09-19 19:23:04",
            "2024-09-19 19:24:04",
            "2024-09-19 19:25:04",
            "2024-09-19 19:26:04",
            "2024-09-19 19:27:04",
            "2024-09-19 19:28:04",
            "2024-09-19 19:29:04",
            "2024-09-19 19:30:04",
            "2024-09-19 19:31:04",
            "2024-09-19 19:32:04",
            "2024-09-19 19:33:04",
            "2024-09-19 19:34:04",
            "2024-09-19 19:35:04",
            "2024-09-19 19:36:04",
            "2024-09-19 19:37:04",
            "2024-09-19 19:38:04",
            "2024-09-19 19:39:04",
            "2024-09-19 19:40:04",
            "2024-09-19 19:41:04",
            "2024-09-19 19:42:04",
            "2024-09-19 19:43:04",
            "2024-09-19 19:44:04",
            "2024-09-19 19:45:04",
            "2024-09-19 19:46:04",
            "2024-09-19 19:47:04",
            "2024-09-19 19:48:04",
            "2024-09-19 19:49:04",
            "2024-09-19 19:50:04",
            "2024-09-19 19:51:04",
            "2024-09-19 19:52:04",
            "2024-09-19 19:53:04",
            "2024-09-19 19:54:04",
            "2024-09-19 19:55:04",
            "2024-09-19 19:56:04",
            "2024-09-19 19:57:04",
            "2024-09-19 19:58:04",
            "2024-09-19 19:59:04",
            "2024-09-19 20:00:04",
            "2024-09-19 20:01:04",
            "2024-09-19 20:02:04",
            "2024-09-19 20:03:04",
            "2024-09-19 20:04:04",
            "2024-09-19 20:05:04",
            "2024-09-19 20:06:04",
            "2024-09-19 20:07:04",
            "2024-09-19 20:08:04",
            "2024-09-19 20:09:04",
            "2024-09-19 20:10:04",
            "2024-09-19 20:11:04",
            "2024-09-19 20:12:04",
            "2024-09-19 20:13:04",
            "2024-09-19 20:14:04",
            "2024-09-19 20:15:04",
            "2024-09-19 20:16:04",
            "2024-09-19 20:17:04",
            "2024-09-19 20:18:04",
            "2024-09-19 20:19:04",
            "2024-09-19 20:20:04",
            "2024-09-19 20:21:04",
            "2024-09-19 20:22:04",
            "2024-09-19 20:23:04",
            "2024-09-19 20:24:04",
            "2024-09-19 20:25:04",
            "2024-09-19 20:26:04",
            "2024-09-19 20:27:04",
            "2024-09-19 20:28:04",
            "2024-09-19 20:29:04",
            "2024-09-19 20:30:04",
            "2024-09-19 20:31:04",
            "2024-09-19 20:32:04",
            "2024-09-19 20:33:04",
            "2024-09-19 20:34:04",
            "2024-09-19 20:35:04",
            "2024-09-19 20:36:04",
            "2024-09-19 20:37:04",
            "2024-09-19 20:38:04",
            "2024-09-19 20:39:04",
            "2024-09-19 20:40:04",
            "2024-09-19 20:41:04",
            "2024-09-19 20:42:04",
            "2024-09-19 20:43:04",
            "2024-09-19 20:44:04",
            "2024-09-19 20:45:04",
            "2024-09-19 20:46:04",
            "2024-09-19 20:47:04",
            "2024-09-19 20:48:04",
            "2024-09-19 20:49:04",
            "2024-09-19 20:50:04",
            "2024-09-19 20:51:04",
            "2024-09-19 20:52:04",
            "2024-09-19 20:53:04",
            "2024-09-19 20:54:04",
            "2024-09-19 20:55:04",
            "2024-09-19 20:56:04",
            "2024-09-19 20:57:04",
            "2024-09-19 20:58:04",
            "2024-09-19 20:59:04",
            "2024-09-19 21:00:04",
            "2024-09-19 21:01:04",
            "2024-09-19 21:02:04",
            "2024-09-19 21:03:04",
            "2024-09-19 21:04:04",
            "2024-09-19 21:05:04",
            "2024-09-19 21:06:04",
            "2024-09-19 21:07:04",
            "2024-09-19 21:08:04",
            "2024-09-19 21:09:04"
          ],
          "series": [
            {
              "name": "Smart Ozonation Control System",
              "unit": "mg/L",
              "target": [
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5,
                21.5
              ],
              "predict_target": [
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0,
                24.0
              ],
              "value": [
                12.86,
                12.86,
                12.86,
                12.86,
                12.86,
                12.86,
                12.86,
                12.86,
                12.86,
                12.86,
                12.86,
                12.86,
                12.86,
                12.86,
                12.86,
                12.86,
                12.86,
                12.86,
                12.86,
                12.86,
                12.86,
                12.86,
                12.86,
                12.86,
                12.86,
                12.86,
                12.86,
                12.86,
                12.86,
                12.86,
                12.86,
                12.86,
                12.86,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                13.05,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                12.92,
                13.93,
                13.93,
                13.93,
                13.93,
                13.93,
                13.93,
                13.93,
                13.93,
                13.93,
                13.93,
                13.93,
                13.93,
                13.93,
                13.93,
                13.93,
                13.93,
                13.93,
                13.93,
                13.93,
                13.93,
                13.93,
                13.93,
                13.93,
                13.93,
                13.93,
                13.93,
                13.93,
                24.0,
                23.92,
                24.05,
                24.01,
                24.16,
                24.02,
                23.97,
                23.95,
                24.02,
                24.08,
                23.95,
                23.98,
                24.08,
                24.12,
                23.98,
                23.89,
                23.96,
                23.76,
                23.97,
                24.09,
                24.08,
                23.92,
                24.0,
                24.01,
                23.97,
                23.99,
                23.96,
                23.91,
                24.0,
                24.0,
                23.94,
                23.9,
                23.96,
                23.85,
                24.08,
                23.84,
                23.89,
                24.15,
                23.94,
                24.07,
                24.09,
                23.95,
                23.99,
                24.03,
                24.02,
                23.86,
                24.0,
                23.96,
                23.92,
                24.14,
                24.0,
                24.0,
                24.13,
                24.08,
                23.75,
                24.15,
                24.15,
                24.06,
                24.06,
                23.99
              ]
            }
          ],
          "parting": "2024-09-19 20:09:04"
        }
      }
    };
  },
  watch: {
    moduleList: {
      handler(val) {
        if (val) {
          val.map((item) => {
            this.getChartData(item);
            this.getTargetData(item);
          });
          timer && clearInterval(timer);
          timer = setInterval(() => {
            val.map((item) => {
              this.getChartData(item);
            });
          }, 60000);
        }
      },
      immediate: true,
    },
  },
  destroyed() {
    clearInterval(timer);
  },
  methods: {
    getChartData(moduleCode) {
      const params = {
        module: moduleCode,
      };
      console.log(moduleCode);
      this.$nextTick(() => {

        // if (res.data && res.data.code === 200) {
        const data = this.chartData[moduleCode];
        const xAxis = data.xAxis;

        const series = [];
        data.series.map((item, index) => {
          let markArea = {};
          if (index === 0) {
            markArea = {
              silent: true,
              data: [
                [
                  {
                    name: "History",
                    xAxis: xAxis[0],
                    itemStyle: {
                      color: "rgba(255, 141, 26, 0.0)",
                    },
                    label: {
                      show: true,
                      color: 'rgba(168, 168, 168, 1)',
                    },
                  },
                  {
                    xAxis: data.parting,
                  },
                ],
                [
                  {
                    name: "Forecast",
                    xAxis: data.parting,
                    itemStyle: {
                      color: "rgba(255, 99, 71, 0.3)",
                    },
                    label: {
                      show: true,
                      color: 'rgba(168, 168, 168, 1)',
                    },
                  },
                  {
                    xAxis: xAxis[xAxis.length - 1],
                  },
                ],
              ],
            };
          }
          series.push(
            {
              name: "Current Value",
              data: item.value,
              symbol: "none",
              smooth: false,
              type: "line",
              markArea: {
                ...markArea,
              },
              lineStyle: {
                color: 'rgba(0, 128, 128, 1)',
                width: 1.2,
              },
              areaStyle: {
                normal: {
                  color: new this.$echarts.graphic.LinearGradient(
                    0,
                    0,
                    0,
                    1,
                    [
                      { offset: 0, color: "rgba(0, 128, 128, 1)" },
                      { offset: 1, color: "rgba(0, 128, 128, 0.1)" },
                    ],
                    false
                  ),
                },
              },
            },
            {
              name: "Historical Target",
              data: item.target,
              symbol: "none",
              smooth: false,
              type: "line",
              lineStyle: {
                normal: {
                  type: "dashed",
                },
              },
            },
            {
              name: "AI Recommended Target",
              data: item.predict_target,
              symbol: "none",
              smooth: false,
              type: "line",
              lineStyle: {
                normal: {
                  type: "dashed",
                },
              },
            },
          );
        });
        const options = { ...this.initChartOptions, series };
        options.xAxis.data = xAxis;
        options.yAxis.name = data.series[0].unit;
        options.legend.show = false;

        const chartOptions = { ...this.chartOptions };
        if (chartOptions[moduleCode]) {
          chartOptions[moduleCode].options = options;
          chartOptions[moduleCode].title = data.series[0].name;
        } else {
          chartOptions[moduleCode] = {
            options,
            title: data.series[0].name,
          };
        }
        this.chartOptions = chartOptions;
        // }

      });
      this.$http
        .get("/api/linkage", {
          params: { ...params },
        })
    },
    getTargetData(moduleCode) {
      const params = {
        module: moduleCode,
      };
      this.chartOptions = {
        AO_DN: {
          title: "Smart Denitrification Control System",
          targetList: [
            {
              "value": 7.8,
              "label": "Aeration Tank1#NO᙮-N"
            },
            {
              "value": 7.8,
              "label": "Aeration Tank3#NO᙮-N"
            },
            {
              "value": 7.8,
              "label": "Aeration Tank4#NO᙮-N"
            }
          ]
        },
        AO_DO: {
          title: "Smart Aeration System",
          targetList: [{ "value": 1.35, "label": "DO" }]
        },
        OE_O3: {
          title: "Smart Ozonation Control System",
          targetList: [{ "value": 24.0, "label": "COD" }]
        }
      }
    },
    handleSwiperButton() {

    },
    onPopoverShow(type) {

    },
    onChartDataZoom(type) {

    },
  },
};
</script>

<style lang="scss" scoped>
.linkage-module-COD {
  padding: 16px;
  width: 100%;
  height: 380px;
  border-radius: 12px;
  background: rgba(47, 50, 59, 1);
  box-shadow: 0px 2px 17px -8px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;

  .header {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: rgba(242, 242, 242, 1);
  }

  .base-info {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .switch-box {
      display: flex;
      align-items: center;
      color: rgba(64, 224, 208, 1);
      cursor: pointer;
    }

    .el-switch {
      margin-left: 16px;
    }
  }

  .swiper-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .swiper-slide {
      height: 100%;
    }

    .chart-box {
      width: 100%;
      height: calc(100% - 30px);
    }

    ::v-deep .swiper-button-prev,
    .swiper-button-next {
      background-size: 20px 20px;
    }

    .swiper-button-prev {
      left: -8px;
    }

    .swiper-button-next {
      right: -8px;
    }

    .swiper-pagination {
      bottom: 0;
    }

    .swiperStyle {
      display: none !important;
    }
  }
}
</style>

<style lang="scss">
.linkage-module-popover {
  .popover-content {
    font-size: 14px;
    line-height: 28px;

    .value-item {
      &:not(:last-child) {
        margin-bottom: 6px;
      }
    }
  }
}

.swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l4.2%2C4.2L8.4%2C22l17.8%2C17.8L22%2C44L0%2C22z'%20fill%3D'%2340e0d0'%2F%3E%3C%2Fsvg%3E");

}

.swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L5%2C44l-4.2-4.2L18.6%2C22L0.8%2C4.2L5%2C0z'%20fill%3D'%2340e0d0'%2F%3E%3C%2Fsvg%3E");

}
</style>
