import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../components/Home.vue";
// import Login from "../components/Login.vue";
import openMore from "../views/controlModules/openMore.vue";

// import Entrance from "../components/entrance.vue";
// import tuozhuai from "../views/tuozhuai/index.vue";
import ctrIndex from "../views/ctrIndex.vue";
const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  { path: "/", redirect: "/ai/control" },
  // { path: "/login", component: Login },
  // { path: "/entrance", component: Entrance },
  // { path: "/tuozhuai", component: tuozhuai },
  {
    path: "/home",
    component: Home,
    children: [
      // { path: "/ai/control1", component: datacontrol },
      { path: "/ai/control", component: ctrIndex },
      { path: "/openMore", component: openMore },
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  // caseSensitive: true,
  routes,
});

// router.beforeEach((to, from, next) => {
//   if (to.query.id) {
//     // this.$store.commit("changeid", to.query.id);
//     window.sessionStorage.setItem("waringid", to.query.id);
//   }
//   console.log(to);
//   if (to.path === "/login") return next();
//   const tokenStr = window.sessionStorage.getItem("access_token");
//   if (!tokenStr) return next("/login");
//   next();

//   if (to.path === "/parameterSetting" || to.path === "/parametersetting") {
//     console.log(123213213213213213);
//     var loginStr = window.sessionStorage.getItem("loginIndex1");
//     console.log(typeof loginStr);
//     console.log(loginStr);
//     if (loginStr === "0") {
//       next();
//       console.log(666);
//     } else {
//       console.log(888);
//       next({
//         path: "/parameterLogin",
//       });
//     }
//   }
//   // if (to.path === "/parameterSetting") {
//   //   const loginStr = window.sessionStorage.getItem("loginIndex1");
//   //   console.log(loginStr);
//   //   if (loginStr === 1) {
//   //     console.log(34);
//   //     next();
//   //   } else {
//   //     console.log(56);
//   //     next({
//   //       path: "/parameterLogin",
//   //     });
//   //   }
//   // }
// });

export default router;
