<template>
  <div class="warnContainer">
    <h4 class="black" v-if="allControlData.result.runbtn"> AI Smart Control: Active</h4>
    <h4 class="black" v-else>AI Smart Control: Inactive</h4>
    <el-card class="el_card">
      <el-card v-for="(item, index) in allControlData.result.table" :key="index" class="el_card_table">
        <div>
          <p>
            {{ item.visible }}
            <span class="unit">{{
      item.unit
    }}</span>
          </p>
        </div>
        <div v-if="item.target_type == 0">
          <el-row style="vertical-align: middle;">
            <el-col :span="13">
              <div class='coldiv'>
                <span class="now_data">
                  Current Value： &nbsp
                </span>
                <el-input class="now_input" v-model="item.current" style="color: #38d0d2;" disabled></el-input>
              </div>
            </el-col>
            <el-col :span="10">
              <div class="mubiaodiv">
                <span style="margin: 0; margin-bottom: 2px; color: rgba(242, 242, 242, 1)" class="tag_data">
                  Target：
                  {{ item.mintarget }}
                </span>
                <el-input class="target_input" v-model="tag[index].target"
                  @change="(newValue) => changeAction(newValue, tag[index], item)"
                  :disabled="!item.changeable"></el-input>
              </div>
            </el-col>
          </el-row>
        </div>
        <div v-else>
          <el-row style="vertical-align: middle;">
            <el-col :span="12">
              <div class='coldiv'>
                <span class="now_data">
                  Current Value：&nbsp &nbsp
                </span>
                <el-input class="now_input" v-model="item.current" style="color: #38d0d2;" disabled></el-input>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="mubiaodiv">
                <span style="margin: 0; margin-bottom: 2px; color: rgba(242, 242, 242, 1)" class="tag_data">
                  Lower：
                  {{ item.mintarget }}
                </span>
                <el-input class="upperLimit_input" v-model="item.target[0]"
                  @change="(newValue) => changetype(newValue, item, ' Lower')" @keyup.enter.native="enterBlur($event)"
                  :disabled="!item.changeable"></el-input>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="mubiaodiv">
                <span style="margin: 0; margin-bottom: 2px; color: rgba(242, 242, 242, 1)" class="tag_data">
                  Upper：
                </span>
                <el-input class="upperLimit_input" v-model="item.target[1]"
                  @change="(newValue) => changetype(newValue, item, 'Upper')" @keyup.enter.native="enterBlur($event)"
                  :disabled="!item.changeable"></el-input>
              </div>
            </el-col>

          </el-row>
        </div>
      </el-card>

    </el-card>
    <el-dialog title="suggest" :visible.sync="dialogVisible" width="30%" :before-close="handleClose" center>
      <span class="dialogtext" v-if="targettype == 0">
        The AI will do its best to keep Creature {{ changename }} around {{ targetnum }}.
      </span>
      <span class="dialogtext" v-else>
        <span style="color: blue;font-weight: 600;">{{ changename }}{{ downtext }}
        </span>
        &nbsp;limit will be set to &nbsp;
        <span style="color: red;font-weight: 600;" v-if="downtext == ' Lower'">
          {{ down }}
        </span>
        <span style="color: red;font-weight: 600;" v-if="downtext == 'Upper'">
          {{ up }}
        </span>
        <!-- The lower limit will be set to 12 -->
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="colsedialog()">Cancel</el-button>
        <el-button type="primary" @click="commitchange()">Commit</el-button>
      </span>
    </el-dialog>

    <!-- <PromptDialog :visible="promptDialogShow" :moduleCode="moduleCode" :type="promptDialogType"
      :typeName="promptDialogTypeName" :targetName="promptDialogTargetName" :switchStatus="targetSwitchValue"
      :currentModule="allControlData" @onClose="promptDialogShow = false" @onRefresh="onRefresh" /> -->
  </div>
</template>

<script>
import countTo from "vue-count-to";
// import PromptDialog from "@/views/AIIntelligentControl/components/PromptDialog.vue";
import commonApi from "@/api/common.js";

export default {
  components: {
    countTo,
    // PromptDialog,
  },
  props: {
    moduleCode: String,
  },
  data() {
    return {
      allControlData: {
        result: {
          runbtn: false,
          table: [],
        },
      },
      openIndex: null,
      allCurrent: null,
      refreshData: null,
      addonData: null,
      facilityCurrent: null,
      timer: null,
      tag: [],
      dialogVisible: false,
      down: null,
      up: null,
      time: null,
      downtext: null,
      tagid: null,
      changename: null,
      targetnum: null,
      targettype: null,
      popoverVisible: false,
      targetSwitchValue: 0,
      targetList: [],
      promptDialogShow: false,
      promptDialogType: undefined,
      promptDialogTypeName: undefined,
      promptDialogTargetName: undefined,
      targetDetailsShow: false,
    };
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  watch: {
    "$route.query.index"(newVal, oldVal) {
      console.log(newVal);
      clearInterval(this.timer);

      this.openIndex = newVal;
      this.getControlData(this.openIndex);
      this.timer = window.setInterval(() => {
        setTimeout(this.getControlData(this.openIndex), 0);
      }, 600000);
    },
    "$store.state.runbt"(newVal, oldVal) {
      console.log(newVal);
      this.getControlData(this.openIndex);
    },
    moduleCode: {
      handler(val) {
        if (val) {
          this.getTargetList();
          this.getRecommendStatus();
        }
      },
      immediate: true,
    }
  },
  mounted() {
    this.openIndex = this.$route.query.index;
    this.getControlData(this.openIndex);
    this.timer = window.setInterval(() => {
      setTimeout(this.getControlData(this.openIndex), 0);
    }, 600000);
  },
  sockets: {
    data(res) {
      this.allCurrent = JSON.parse(res);
      this.refreshData = this.allCurrent.refresh;
      for (let i = 0; i < this.refreshData[this.openIndex].length; i++) {
        console.log(this.allControlData.result.table[i].current);
        this.allControlData.result.table[i].current =
          this.refreshData[this.openIndex][i];
        if (this.allControlData.result.table[i].current == '-999') {
          this.allControlData.result.table[i].current = 'NA'
        }
      }

    },
  },
  methods: {
    async getControlData(index) {
      const { data: res } = await this.$http.get("/modules/init", {
        params: { index: index },
      });
      this.allControlData = res.data;
      console.log(this.allControlData);
      this.tag = []
      for (let a = 0; a < this.allControlData.result.table.length; a++) {
        if (this.allControlData.result.table[a].current == '-999') {
          this.allControlData.result.table[a].current = 'NA'
        }
        if (this.allControlData.result.table[a].target !== 'NA') {
          this.tag.push(
            {
              id: this.allControlData.result.table[a].id,
              tag: this.allControlData.result.table[a].tag,
              target: ((this.allControlData.result.table[a].target[0] + this.allControlData.result.table[a].target[1]) / 2).toFixed(2),
              fudong: ((this.allControlData.result.table[a].target[1] - this.allControlData.result.table[a].target[0]) / 2).toFixed(2)
            }
          )
        } else {
          this.tag.push(
            {
              id: this.allControlData.result.table[a].id,
              tag: this.allControlData.result.table[a].tag,
              target: '-',
              fudong: '-'
            }
          )
        }
      }
      console.log(this.allControlData);
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
    },
    handleClose(done) {
      this.$confirm('confirm？')
        .then(_ => {
          done();
          this.getControlData(this.openIndex);
        })
        .catch(_ => { });
    },
    colsedialog() {
      this.dialogVisible = false
      // this.getControlData() 
      this.getControlData(this.openIndex);
    },
    async changeAction(newValue, row, item) {
      console.log(newValue, row, item);
      // console.log(row.target.toFixed(2));
      this.down = (Number(row.target) - Number(row.fudong)).toFixed(2)
      this.up = (Number(row.target) + Number(row.fudong)).toFixed(2)
      this.time = Number(item.target[2]) * 100
      this.tagid = row.id
      this.changename = item.visible
      this.downtext = this.down
      this.targettype = item.target_type

      console.log(this.down, this.up);
      if (Number(row.target)) {
        // console.log(123213213213213);
      } else {
        // console.log(99999999999);
        return this.$message.warning("error");

      }
      this.targetnum = row.target
      if (Number(this.downtext) < 0) {
        this.downtext = 0
      }
      if (row.mintarget > row.maxtarget) {
        this.getControlData(this.openIndex);
        return this.$message.info("error！");
      }
      this.dialogVisible = true
      let arr = null
      if (row.visible) {
        arr = "[" + row.target[0] + ',' + row.target[1] + ',' + row.target[2] + "]"

      } else {
        arr = "[" + down + ',' + up + ',' + item.target[2] + "]"
      }
    },
    async changetype(newValue, row, item) {
      console.log(newValue, row, item);
      this.down = row.target[0]
      this.up = row.target[1]
      this.time = row.target[2] * 100
      this.tagid = row.id
      this.changename = row.visible
      this.downtext = item
      this.targettype = row.target_type
      // console.log(this.down, this.up);
      // if (item == ' Lower') {
      //   this.typenum = this.dowm
      // } else {

      // }
      if (Number(this.down)) {
      } else {
        return this.$message.warning("error");
      }
      if (Number(this.up)) {
      } else {
        return this.$message.warning("error");
      }
      this.targetnum = row.target
      if (Number(this.downtext) < 0) {
        this.downtext = 0
      }
      if (row.target[0] > row.target[1]) {
        this.getControlData(this.openIndex);
        return this.$message.info("error！");
      }
      this.dialogVisible = true
      let arr = null
      if (row.visible) {
        arr = "[" + row.target[0] + ',' + row.target[1] + ',' + row.target[2] + "]"

      } else {
        arr = "[" + down + ',' + up + ',' + item.target[2] + "]"
      }
    },
    async commitchange() {
      let arr = null
      console.log(this.time);
      if (this.time) {
        // console.log(row.target);
        arr = "[" + this.down + ',' + this.up + ',' + this.time / 100 + "]"
      } else {
        arr = "[" + Number(this.down) + ',' + Number(this.up) + "]"
      }
      // console.log("[" + Number(this.down) + ',' + Number(this.up) + "]");
      // console.log("[" + this.down + ',' + this.up + ',' + this.time / 100 + "]");
      // return
      const { data: res } = await this.$http.post("modules/targetset", {
        index: this.openIndex,
        id: this.tagid,
        newValue: arr,
      });
      //  console.log(res.meta.msg);
      if (res.meta.status == 403) {
        this.getControlData(this.openIndex);
        return this.$message.error(res.meta.msg);
      }
      if (res.meta.status == 400) {
        this.getControlData(this.openIndex);
        return this.$message.error(res.meta.msg);
      }
      if (res.data.result != true) {
        this.getControlData(this.openIndex);
        return this.$message.error(res.meta.msg);
      }
      this.$message.success("success");
      // this.$emit("closedrawer");
      this.dialogVisible = false
    },

    enterBlur(event) {
      event.target.blur();
    },
    async autoStatus(openIndex) {
      // console.log(this.inAerationData[index].result.deeply);
      if (this.allControlData.result.deeply == true) {
        const { data: res1 } = await this.$http.post("modules/auto", {
          index: openIndex,
          flag: false,
        });
        if (res1.meta.status == 403) {
          this.getControlData(this.openIndex);
          return this.$message.error(res1.meta.msg);
        }
        // console.log(res1);
      } else if (this.allControlData.result.deeply == false) {
        const { data: res2 } = await this.$http.post("modules/auto", {
          index: openIndex,
          flag: true,
        });
        if (res2.meta.status == 403) {
          this.getControlData(this.openIndex);
          return this.$message.error(res2.meta.msg);
        }
        // console.log(res2);
      }
      this.getControlData(this.openIndex);
    },
    autoStyle() {
      if (this.allControlData.result.deeply == true) {
        return { backgroundColor: "#1890ff", color: "fff" };
      } else {
        return { backgroundColor: "#b8b8b8", color: "fff" };
      }
    },
    async cbrBtn(index, id) {
      id = this.allControlData.result.pool[index].id;
      // console.log(this.inAerationData);
      // console.log(id);
      if (this.allControlData.result.pool[index].flag == true) {
        const { data: res1 } = await this.$http.post("modules/pool", {
          index: this.allControlData.index,
          id: id,
          flag: false,
        });
        if (res1.meta.status == 403) {
          this.getControlData(this.openIndex);
          return this.$message.error(res1.meta.msg);
        }
      } else if (this.allControlData.result.pool[index].flag == false) {
        const { data: res2 } = await this.$http.post("modules/pool", {
          index: this.allControlData.index,
          id: id,
          flag: true,
        });
        if (res2.meta.status == 403) {
          this.getControlData(this.openIndex);
          return this.$message.error(res2.meta.msg);
        }
      }
      this.getControlData(this.openIndex);
    },
    cbrBtnStyle(index) {
      if (this.allControlData.result.pool[index].flag == true) {
        return { backgroundColor: "#1890ff", color: "fff" };
      } else {
        return { backgroundColor: "#b8b8b8", color: "fff" };
      }
    },
    onRefresh() {
      this.getControlData(this.openIndex);
      this.getRecommendStatus();
    },
    getRecommendStatus() {
      const params = {
        module: this.moduleCode,
      };
      this.$http
        .get("recommend/status", {
          params: { ...params },
        })
        .then((res) => {
          if (res.data && res.data.code === 200) {
            this.targetSwitchValue = res.data.data.status;
          }
        });
    },
    getTargetList() {
      const params = {
        module: this.moduleCode,
      };
      this.$http
        .get("/api/target", {
          params: { ...params },
        })
        .then((res) => {
          if (res.data && res.data.code === 200) {
            this.targetList = res.data.data;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table {
  width: 100%;
  height: 100%;

  .el-table__header-wrapper table,
  .el-table__body-wrapper table {
    width: 100% !important;
  }

  .el-table__body-wrapper::-webkit-scrollbar {

    // width: 0px;
    background-color: #fff;
  }

  .el-table__body-wrapper::-webkit-scrollbar-thumb {
    border-radius: 1px;
    height: 0px;
    background: #fff;
  }

  .el-table__body-wrapper::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    border-radius: 3px;
    background: #fff;
  }

  .el-table__header-wrapper table,
  .el-table__body-wrapper table {
    width: 100% !important;
  }

  .el-table__body,
  .el-table__footer,
  .el-table__header {
    table-layout: auto !important;
  }
}

::-webkit-scrollbar {
  display: none;
}

.warnContainer {
  // width: 100%;
  height: 100%;
  margin: 0 auto;

  .el_card {
    border-radius: 16px;
    padding: 10px 8px;
    height: 492px;
    overflow: scroll;

    .el_card_table {
      border-radius: 8px;
      margin: 0px 6px 17px;

      p {
        margin: 0;
        margin-bottom: 10px;
        color: #4d4a4a;
        font-size: 18px;
      }

      .unit {
        margin-left: 10px;
        font-size: 14px;
        color: #878787
      }
    }
  }

  .switch {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;

    span {
      /* display: inline-block; */
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      margin-left: 10px;
    }
  }

  .coldiv {
    // height: 40px;
    line-height: 40px;

    .now_data {
      margin: 0;
      margin-bottom: 2px;
      color: rgba(242, 242, 242, 1)a;
      font-size: 16px;
    }

    .now_span {
      font-size: 18px;
      color: #38d0d2;
      margin-right: 6px;
      font-weight: 600;
    }

    .now_input {
      font-size: 18px;
      // margin-right: 6px;
      font-weight: 600;

      width: 60px;


      // ::v-deep .is-disabled .el-input__inner {
      // }
    }
  }

  .mubiaodiv {
    // margin-left: 40px;

    .tag_data {
      font-size: 16px;
      margin: 0;
      margin-bottom: 2px;
      color: rgba(242, 242, 242, 1)
    }

    .el-popover__reference {
      display: inline-block;
    }

  }

  // target
  .target_input {
    width: 90px
  }

  .upperLimit_input {
    width: 40px
  }

  .passive_input {
    width: 40px
  }

  .Pulse {
    display: flex;
    position: relative !important;
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
    margin: 0 auto !important;
    margin-bottom: 20px !important;
  }

  .cbrBtn {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
  }

  .allTable {
    // display: inline-block;
    margin-top: 10px;
    text-align: center;
    overflow: hidden;

    // width: 350px;
    // height: 424px;
    .tableData1 {
      float: left;
      margin: 0 auto;
      border-radius: 8px;
      width: 100%;
    }

    .tableData2 {
      margin: 0 auto;
    }

    .el-table {
      margin: 0 auto;
      border-radius: 20px;
    }
  }
}

::v-deep .el-input__inner {
  height: 40px;
  // background-color: #1f222b;
  border: 0 !important;
  // text-align: center !important;
  padding: 0 !important;
  font-size: 20px;
  font-weight: 600;
  color: #498af4;
}

::v-deep .el-input__inner:focus {
  border: 1px solid #a88247 !important;
  background-color: transparent !important;
}

// ::v-deep.el-card__body,
// .el-main {
//   padding: 20px 12px
// }

::v-deep .el-card__body,
.el-main {
  padding: 16px 20px;
}

::v-deep .el-dialog__wrapper {

  .el-dialog__header {

    .el-dialog__title {
      color: rgba(242, 242, 242, 1) !important;
    }
  }

}

.el-button {
  // border: none !important;
  // color: #fff !important;
}

// .el-input.is-disabled ::v-deep .el-input__inner {
//   background-color: #fff;
//   color: #606266;
// }

h4 {
  margin-top: 20px;
  margin-left: 8px;
  margin-right: 10px;
  text-align: left;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  color: rgb(77, 74, 74);
}

img {
  vertical-align: middle;
}


::v-deep.el-input.is-disabled .el-input__inner {
  // color: #606266;
  color: #38d0d2 !important;
}

.fans {
  animation: wm 1.2s infinite linear;
}

@keyframes wm {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.dialogtext {
  font-size: 24px;
  color: rgba(242, 242, 242, 1);
}

::v-deep .el-dialog {
  border-radius: 10px;

  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0px !important;

}
</style>
<style>
.myClass {
  width: 1600px !important;
  height: 1200px !important;
}
</style>

<style lang="scss">
.target-value-setting-popover {
  .target-item {
    margin-bottom: 6px;
    font-size: 16px;
    line-height: 28px;
    color: #354052;
  }

  .btns-box {
    display: flex;
    align-items: center;
    margin: 26px 0;
    color: #D43030;

    .el-button {
      color: #D43030;
    }

    .switch-box {
      display: flex;
      align-items: center;
      margin-left: 40px;
    }

    .el-switch {
      position: relative;
      margin-right: 6px;

      .el-switch__label {
        position: absolute;
        display: none;
        margin: 0;
        color: #fff !important;
        font-size: 12px !important;
      }

      .el-switch__label.is-active {
        display: block;
      }

      .el-switch__label--left {
        z-index: 1;
        right: 8px;
      }

      .el-switch__label--right {
        z-index: 1;
        left: 8px;
      }
    }
  }

  .text {
    font-size: 14px;
    line-height: 28px;
    color: #808080;
  }

  // .el-dialog__wrapper {
  //   .base-dialog {
  //     .el-dialog__header {

  //       .el-dialog__title {
  //         color: rgba(242, 242, 242, 1) !important;
  //       }
  //     }
  //   }
  // }

}
</style>