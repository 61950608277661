<template>
  <div class="chartOne">
    <h4></h4>
    <div :id="codMain" class="codMain"></div>
  </div>
</template>

<script>
import { format } from 'echarts';
import * as XLSX from "xlsx";
import XLSXS from 'xlsx-style-cptable'
import FileSaver from 'file-saver'
import { mapState } from "vuex";
import { log } from "util";

export default {
  props: {
    codMain: String,
    ChartData: Object,
    datas: Array,
  },
  data() {
    return {
      timer: null,
      codChart: null,
      chartData: {},
      seriesData: [],
      openIndex: null,
      chartTitle: "",
      initOption: {
        animation: true,
        animationDuration: 1500,
        dataZoom: [
          {
            type: "inside",
          },
        ],
        legend: {
          data: "",
          // orient: 'vertical',
          // y: 'center',
          // x: 'right'
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          // y: 20,
          right: "20",
          feature: {
            // dataView: {},
            // saveAsImage: { show: true },
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisLine: {
            show: true,
            lineStyle: {
              color: "#fffff",
            },
          },
          textStyle: { color: "#fffff" },
          axisTick: { show: false },
          axisLabel: {
            show: true,
            textStyle: {
              color: "rgba(163, 163, 163, 1)",
            }
          },
        },
        yAxis: {
          axisLine: {
            show: true,
            lineStyle: { color: "#333" },
          },
          type: "value",
        },
        tooltip: {
          confine: true,
          trigger: "axis",
          axisPointer: {
            type: "line",
            z: 0,
            lineStyle: {
              color: "#2D3443",
            },
          },
          textStyle: {
            align: "left",
          },
        },
        series: [
          {
            name: "",
            data: "",
            symbol: "none",
            smooth: true,
            lineStyle: { width: 1.6 },
            // type: "line",
            label: {
              show: false,
              position: "right",
            },
            itemStyle: {
              lineStyle: {
                color: '"#1890ff"',
                width: 1.2,
              },
            },
            markPoint: {
              data: [],
            },
          },
        ],
      },
    };
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  mounted() {
    console.log(this.ChartData);
    console.log(this.codMain);
    this.initChart();
    // this.timer = window.setInterval(() => {
    //   setTimeout(this.initChart(), 0);
    // }, 300000);
  },
  computed: {
    ...mapState(["theme"]),
  },
  watch: {
    "$store.state.open"(newVal, oldVal) {
      this.codChart.resize();
    },
    theme() {
      // console.log("nidaye");
      this.codChart.dispose();
      this.initChart();
    },
    ChartData(newval) {
      console.log(newval);
      if (newval) {
        this.initChart();
      }
    },
  },
  methods: {
    timestampToTime(timestamp) {
      // console.log(timestamp);
      timestamp = timestamp ? timestamp : null;
      let date = new Date(timestamp);
      let Y = date.getFullYear() + "-";
      let M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      let D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      let h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      let m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      let s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
    timeToTimestamp(time) {
      // console.log(time);
      let timestamp = Date.parse(new Date(time).toString());

      return timestamp;
    },
    changevalue(a, b) {
      let some = [a, b];
      return some;
    },
    use() {
      // console.log("111");
    },
    getFormatDate_XLSX(serial) {
      var utc_days = Math.floor(serial - 25569);
      var utc_value = utc_days * 86400;
      var date_info = new Date(utc_value * 1000);
      var fractional_day = serial - Math.floor(serial) + 0.0000001;
      var total_seconds = Math.floor(86400 * fractional_day);
      var seconds = total_seconds % 60;
      total_seconds -= seconds;
      var hours = Math.floor(total_seconds / (60 * 60));
      var minutes = Math.floor(total_seconds / 60) % 60;
      var d = new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);

      return d;
    },
    initChart() {
      console.log(this.getFormatDate_XLSX);
      if (!("board" in this.ChartData)) {
        this.initOption.xAxis.data = this.ChartData.xAxis;
        const legendData = this.ChartData.legend.map((item) => {
          return item.name;
        });
        this.initOption.legend.data = legendData;
        this.initOption.series = [];
        const allyValue = [];
        let werList = [];
        console.log(this.ChartData);
        this.ChartData.legend.map((item, index, array) => {
          console.log(item);
          console.log(index);
          console.log(item.yAxis);
          let qer = [];
          let wer = [];
          let werIndex = index;
          console.log(werIndex);
          let wertime = [];

          item.yAxis.map((item1) => {
            // console.log(item1);
            // console.log(werIndex);
            qer.push(item1.dataValue);
            wer.push(item1.dataTime);
            wertime.push(
              this.timestampToTime(
                Date.parse(new Date(item1.dataTime).toString()) - 43 * 1000
              )
            );
          });
          werList.push(wer);
          this.initOption.title = {
            left: "5%",
            //   text: this.chartTitle,
            show: false,
          };
          this.initOption.yAxis = [
            {
              type: "value",
              min: this.ChartData.yAxis.min,
              max: this.ChartData.yAxis.max,
            },
          ];
          // console.log(wer);

          let allqwe = [];
          allqwe = qer;
          this.initOption.series[index] = {
            name: item.name,
            data: allqwe,
            symbol: "none",
            smooth: true,
            lineStyle: { width: 1.6 },
            type: "line",
            // yAxisIndex: index,
            label: {
              show: false,
              position: "right",
            },
            itemStyle: {
              lineStyle: {
                color: '"#1890ff"',
                width: 1.2,
              },
            },
            markLine: {
              data: [],
            },
            markPoint: {
              data: [],
            },
          };
          for (let i = 0; i < item.findPoint.length; i++) {
            console.log(item.findPoint);
            if (item.findPoint[i] == "avg") {
              this.initOption.series[index].markLine = {
                data: [{ type: "average", name: "AverageValue" }],
              };
            }
            if (item.findPoint[i] == "min") {
              this.initOption.series[index].markPoint = {
                data: [{ type: "min", name: "mini" }],
              };
            }
            if (item.findPoint[i] == "max") {
              this.initOption.series[index].markPoint = {
                data: [{ type: "max", name: "max" }],
              };
            }
            if (item.findPoint[0] == "min" && item.findPoint[1] == "max") {
              this.initOption.series[index].markPoint = {
                data: [
                  { type: "min", name: "min" },
                  { type: "max", name: "max" },
                ],
              };
            }
            if (item.findPoint[1] == "min" && item.findPoint[0] == "max") {
              this.initOption.series[index].markPoint = {
                data: [
                  { type: "min", name: "min" },
                  { type: "max", name: "max" },
                ],
              };
            }
          }
          this.initOption.tooltip.formatter = function (params) {
            // console.log(wer);
            // console.log(werList);
            // console.log(params);
            let some = [];
            for (let i = 0; i < params.length; i++) {
              some.push(werList[params[i].componentIndex][params[0].dataIndex]);
            }
            // console.log(some);
            let dataStr = `<div></div>`;
            // console.log(dataStr);
            params.forEach((item, index) => {
              // console.log(item);
              // console.log(index);
              dataStr += `<div>
              <span style="display:inline-block;margin-right:5px;width:10px;height:10px;">${some[index]}</span>
          <div style="margin: 0 8px;">
            <span style="display:inline-block;margin-right:5px;width:10px;height:10px;background-color:${item.color};"></span>
            <span>${item.seriesName}</span>
            <span style="float:right;margin-left:20px;">${item.data}</span>
          </div>
        </div>`;
            });
            return dataStr;
          };
          this.initOption.toolbox.feature.dataView = {
            show: false,
            readOnly: false,
            lang: ["DataView", "ShutDown", "ExportExcel"],
            optionToContent(opt) {
              const seriesExcel = opt.series;
              console.log(seriesExcel);
              const axisExcel = werList;
              console.log(axisExcel);

              let max = axisExcel.reduce((prev, next) => {
                return prev.length > next.length ? prev : next;
              });
              console.log(max);

              function aryJoinAry(ary, ary2) {
                var itemAry = [];
                console.log(ary2);
                var minLength;
                if (ary.length > ary2.length) {
                  minLength = ary2.length;
                } else {
                  minLength = ary.length;
                }
                for (var i = 0; i < minLength; i++) {
                  itemAry.push(ary[i]);
                  itemAry.push(ary2[i]);
                }
                return itemAry;
              }

              let something = aryJoinAry(axisExcel, seriesExcel);
              console.log(something);
              let something1 = something.slice(1, something.length + 1);

              let tdHeads = '<td  style="padding: 0 10px">time</td>';
              let tdBodys = "";
              something1.forEach(function (item, index) {
                console.log(item.name);
                if (index % 2 == 1) {
                  tdHeads += '<td  style="padding: 0 10px">time</td>';
                } else {
                  tdHeads +=
                    '<td style="padding: 0 10px">' + item.name + "</td>";
                }
              });
              let table =
                '<table id="exportTab" border="1" style="margin-left:20px;border-collapse:collapse;font-size:14px;text-align:center;"><tbody><tr>' +
                tdHeads +
                " </tr>";

              for (let i = 0, l = max.length; i < l; i++) {
                for (let j = 0; j < something1.length; j++) {
                  console.log(something[j]);
                  if (j % 2 == 0) {
                    if (something1[j].data[i] != undefined) {
                      tdBodys += "<td>" + something1[j].data[i] + "</td>";
                    } else {
                      tdBodys += "<td>" + "-" + "</td>";
                    }
                  } else {
                    if (something1[j][i] != undefined) {
                      tdBodys += "<td>" + something1[j][i] + "</td>";
                    } else {
                      tdBodys += "<td>" + "-" + "</td>";
                    }
                  }
                }
                if (something[0][i] != undefined) {
                  table +=
                    '<tr><td style="padding: 0 10px">' +
                    something[0][i] +
                    tdBodys +
                    "</tr>";
                } else {
                  table +=
                    '<tr><td style="padding: 0 10px">' +
                    "-" +
                    tdBodys +
                    "</tr>";
                }

                tdBodys = "";
              }

              // console.log(table);
              table += "</tbody></table>";
              return table;
            },

            contentToOption(dom, opt) {
              function getFormatDate_XLSX(serial) {
                var utc_days = Math.floor(serial - 25569);
                var utc_value = utc_days * 86400;
                var date_info = new Date(utc_value * 1000);
                var fractional_day = serial - Math.floor(serial) + 0.0000001;
                var total_seconds = Math.floor(86400 * fractional_day);
                var seconds = total_seconds % 60;
                total_seconds -= seconds;
                var hours = Math.floor(total_seconds / (60 * 60));
                var minutes = Math.floor(total_seconds / 60) % 60;
                var d = new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);

                var add0 = (m) => m < 10 ? '0' + m : m;
                var date = d.getTime()
                date = new Date(d.getTime() - 43 * 1000)
                // console.log(date);
                let Y = date.getFullYear() + '-';
                let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
                let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
                let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
                let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
                let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
                return Y + M + D + h + m + s;
                // return d.getTime();
              }

              console.log(dom);
              // console.log(opt);
              let wb = XLSX.utils.table_to_book(
                document.getElementById("exportTab")
              );
              console.log(wb.Sheets.Sheet1);
              for (let i in wb.Sheets.Sheet1) {
                // console.log(ws[i]);
                if (i !== '!cols' && i != '!fullref' && i != '!merges' && i != '!ref' && i != '!rows'
                ) {
                  if (wb.Sheets.Sheet1[i].z === 'm/d/yy') {
                    wb.Sheets.Sheet1[i].v = getFormatDate_XLSX(wb.Sheets.Sheet1[i].v)
                    wb.Sheets.Sheet1[i].t = 's'
                    delete wb.Sheets.Sheet1[i].z
                  }
                }
              }

              let wbout = XLSX.write(wb, {
                bookType: "xlsx",
                bookSST: true,
                type: "array",
              });
              try {
                FileSaver.saveAs(

                  new Blob([wbout], { type: "application/octet-stream" }),
                  `GraphicalAnalysis.xlsx`
                );
              } catch (e) {
                if (typeof console !== "undefined") console.log(e, wbout);
              }
              console.log(wbout);
              return wbout;
            },
          };
        });
        let newPromise = new Promise((resolve) => {
          resolve();
        });
        newPromise.then(() => {

          this.codChart = this.$echarts.init(
            document.getElementById(this.codMain),
            this.theme
          );

          if (this.codChart == null) {
            this.codChart = this.$echarts.init(
              document.getElementById(this.codMain)
            );
          }
          this.codChart.clear();
          this.codChart.setOption(this.initOption);

        });
      } else {
        this.initOption.xAxis.data = this.ChartData.time_axis;
        console.log(this.ChartData.legend);
        const legendData = this.ChartData.legend.map((item) => {
          return item.name;
        });
        let legendData2 = legendData
        // for (var a = 0; a < legendData.length; a++) {
        // for (var b = 0; b < legendData.length; b++) {
        // legendData2
        legendData2.unshift("time");
        let obj = {};
        legendData2.forEach((item, index) => {
          console.log(item);
          obj = {
            ...obj,
            [index]: item
          }
        });
        console.log(obj);
        let jsonData = [];
        for (var a = 0; a < this.ChartData.legend[0].value.length; a++) {
          // let obj1 = {};
          // legendData2.forEach((item, index) => {
          //   obj1 = {
          //     ...obj1,
          //     [index]: this.ChartData.legend[0].value[a]
          //   }
          // });
          // jsonData[a] = obj1
        }
        console.log(jsonData);
        // console.log(legendData2);
        // legendData[0]
        this.initOption.legend.data = legendData;
        this.initOption.series = [];

        this.ChartData.legend.map((item, index, array) => {
          // console.log(this.getFormatDate_XLSX);
          this.initOption.title = {
            left: "5%",
            //   text: this.chartTitle,
            show: false,
          };
          this.initOption.yAxis = [
            {
              type: "value",
            },
          ];
          this.initOption.series[index] = {
            name: item.name,
            data: item.value,
            symbol: "none",
            smooth: true,
            lineStyle: { width: 1.6 },
            type: "line",
            // yAxisIndex: index,
            label: {
              show: false,
              position: "right",
            },
            itemStyle: {
              lineStyle: {
                color: '"#1890ff"',
                width: 1.2,
              },
            },
          };
          // this.initOption.series[index].data = item.yAxis;
          this.initOption.toolbox.feature.dataView = {
            show: false,
            readOnly: false,
            lang: ["DataView", "ShutDown", "ExportExcel"],
            optionToContent(opt, getFormatDate_XLSX) {
              // console.log(this.getFormatDate_XLSX);

              console.log(opt);
              const seriesExcel = opt.series;
              console.log(seriesExcel);
              const axisExcel = opt.xAxis[0].data;
              console.log(axisExcel);
              let tdHeads = '<td  style="padding: 0 10px">time</td>';
              let tdBodys = "";
              seriesExcel.forEach(function (item) {

                tdHeads += '<td style="padding: 0 10px">' + item.name + "</td>";
              });
              let table =
                '<table id="exportTab" border="1" style="margin-left:20px;border-collapse:collapse;font-size:14px;text-align:center;"><tbody><tr>' +
                tdHeads +
                " </tr>";
              for (let i = 0, l = axisExcel.length; i < l; i++) {
                for (let j = 0; j < seriesExcel.length; j++) {
                  tdBodys +=
                    "<td>" +
                    (seriesExcel[j].data[i] !== null
                      ? seriesExcel[j].data[i].toFixed(2)
                      : "-") +
                    "</td>";
                }
                table +=
                  '<tr><td style="padding: 0 10px">' +
                  axisExcel[i] +
                  tdBodys +
                  "</tr>";
                tdBodys = "";
              }
              table += "</tbody></table>";
              return table;
            },

            contentToOption(dom, opt) {
              function getFormatDate_XLSX(serial) {
                var utc_days = Math.floor(serial - 25569);
                var utc_value = utc_days * 86400;
                var date_info = new Date(utc_value * 1000);
                var fractional_day = serial - Math.floor(serial) + 0.0000001;
                var total_seconds = Math.floor(86400 * fractional_day);
                var seconds = total_seconds % 60;
                total_seconds -= seconds;
                var hours = Math.floor(total_seconds / (60 * 60));
                var minutes = Math.floor(total_seconds / 60) % 60;
                var d = new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
                var add0 = (m) => m < 10 ? '0' + m : m;
                var date = d.getTime()
                date = new Date(d.getTime() - 43 * 1000)
                let Y = date.getFullYear() + '-';
                let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
                let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
                let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
                let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
                let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
                return Y + M + D + h + m + s;
                // return d.getTime();
              }

              let wb = XLSX.utils.table_to_book(
                document.getElementById("exportTab")
              );
              console.log(wb.Sheets.Sheet1);
              for (let i in wb.Sheets.Sheet1) {
                // console.log(ws[i]);
                if (i !== '!cols' && i != '!fullref' && i != '!merges' && i != '!ref' && i != '!rows'
                ) {
                  if (wb.Sheets.Sheet1[i].z === 'm/d/yy') {
                    wb.Sheets.Sheet1[i].v = getFormatDate_XLSX(wb.Sheets.Sheet1[i].v)
                    wb.Sheets.Sheet1[i].t = 's'
                    delete wb.Sheets.Sheet1[i].z
                  }
                }
              }
              let wbout = XLSX.write(wb, {
                bookType: "xlsx",
                bookSST: true,
                type: "array",
              });
              try {
                FileSaver.saveAs(

                  new Blob([wbout], { type: "application/octet-stream" }),
                  `GraphicalAnalysis.xlsx`
                );
              } catch (e) {
                if (typeof console !== "undefined") console.log(e, wbout);
              }
              console.log(wbout);
              return wbout;
            },
          };
        });
        let newPromise = new Promise((resolve) => {
          resolve();
        });
        newPromise.then(() => {
          this.codChart = this.$echarts.init(
            document.getElementById(this.codMain),
            this.theme
          );

          if (this.codChart == null) {
            this.codChart = this.$echarts.init(
              document.getElementById(this.codMain)
            );
          }
          this.codChart.clear();
          this.codChart.setOption(this.initOption);

        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.chartOne {
  width: 100%;
  height: 100%;
  margin: 0 auto;

  ::-webkit-scrollbar {
    border-radius: 6px;
    width: 5px !important;
    background-color: #ccc;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: #989898;
    height: 30px;
  }

  .codMain {
    width: 93%;
    height: 74%;
    margin: 0 auto;
  }

  h4 {
    padding-top: 10px;
    padding-left: 36px;
    font-size: 16px;
    color: rgb(76, 76, 76);
  }
}
</style>
