import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import axios from "axios";
import "element-ui/lib/theme-chalk/index.css";
import "./assets/css/global.scss";
import "./assets/iconfont/iconfont.css";
import VueSocketio from "vue-socket.io";
import socketio from "socket.io-client";
import * as echarts from "echarts";
import { VueJsonp } from "vue-jsonp";
import Moment from "moment";
import { Message } from "element-ui";
import dataV from "@jiaminghi/data-view";
// import VueTypedJs from "vue-typed-js";
// Vue.use(VueTypedJs);
Vue.use(dataV);
// import './changeThemes.scss'
// import '../theme/index.css'
// import '../theme_light/index.css'

import "../public/lib/theme/chalk";
import "../public/lib/theme/westeros";
import "@/assets/iconfont/iconfont.css";
import "@/styles/animate.scss";
import "element-ui/lib/theme-chalk/index.css";
// import "@/styles/reset.css";
import "@/styles/global.scss";
import "./assets/font/font.css";
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import locale from "../node_modules/element-ui/lib/locale/lang/en";

Vue.use(VueAwesomeSwiper);

Vue.filter("formatDate", function (value, format = "YYYY-MM-DD HH:mm:ss") {
  return Moment(value).utc().format(format);
});

Vue.use(VueJsonp);
Vue.prototype.$echarts = echarts;

/// baseURL
/// var baseURL is used for replacing by runtime environment value in docker, or building script when needed
/// The content in the quotes is safe to modify, but modification to other parts will BREAK the container building!

const baseURL = "http://jp-backend-wczd";
// const baseURL = "http://192.168.2.53:8000";

Vue.prototype.baseURL = baseURL;

Vue.use(ElementUI, { locale });
Vue.use(
  new VueSocketio({
    debug: true,
    connection: socketio.connect(`${baseURL}/socket`),
  })
);

Vue.prototype.$socketio = socketio;
Vue.prototype.$http = axios;

axios.defaults.baseURL = `${baseURL}/`;

axios.interceptors.request.use((config) => {
  config.headers.Authorization =
    "Bearer" + " " + window.sessionStorage.getItem("access_token");
  return config;
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
