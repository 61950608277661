<template>
  <div class="chartOne">
    <div :id="codMain" class="codMain"></div>
  </div>
</template>

<script>
import echarts from "echarts";
export default {
  name: "index",
  props: {
    codMain: String,
    ChartData: Array,
  },
  data() {
    return {
      codChart: null,
      myChart: null,
      chartData: {},
      seriesData: [],
      openIndex: null,
      chartTitle: "",
    };
  },
  mounted: function () {
    this.$nextTick(function () {
      this.getPie();
    });
    window.addEventListener("resize", () => {
      this.myChart.resize();
    });
  },
  watch: {
    ChartData(newval) {
      console.log(this.codMain);
      console.log(newval);
      console.log(this.myChart);
      this.myChart.clear();
      if (newval) {
        this.getPie();
      }
    },

  },
  beforeDestroy() {
    this.myChart.clear();
  },
  methods: {
    getPie() {
      console.log(this.ChartData);
      // let newPromise = new Promise((resolve) => {
      //   resolve();
      // });
      // newPromise.then(() => {
      //   var myChart = this.$echarts.init(document.getElementById(this.codMain));
      //   this.myChart = myChart
      // });
      // var myChart = this.$echarts.init(document.getElementById(this.codMain));
      // this.myChart = myChart
      var option = {
        title: {},
        tooltip: {},

        color: ['rgba(255, 99, 71, 1)', 'rgba(64, 224, 208, 1)'],
        series: {
          type: "pie",
          radius: "60%",
          radius: ["40%", "55%"],
          //       //   width: 800,
          center: ["50%", "50%"],
          data: this.ChartData,
          label: {
            alignTo: 'edge',

            // formatter: '{b}\n {c} {{d}%}',
            formatter: params => {
              console.log(params)
              return `{name|${params.name}}\n{time|${params.data.number}} \n{percent|${params.data.value}% }`
            },
            minMargin: 5,
            edgeDistance: 10,
            lineHeight: 30,
            fontSize: 15,
            // distanceToLabelLine: 5,
            color: '#999',
            // padding: 4,
            padding: [0, 0, 0, 0],
            rich: {
              time: {
                fontSize: 15,
                color: '#4D4A4A',
                padding: [-10, 0, 20, 0],
              },
              time: {
                fontSize: 18,
                color: 'rgba(242, 242, 242, 1)',
                padding: [-10, 0, 10, 0],
              },
              percent: {
                fontSize: 20,
                fontWeight: 600,
                color: 'inherit',
                padding: [-10, 0, 10, 0],
              }
            }
          },
          labelLine: {
            length: 15,
            length2: 0,
            maxSurfaceAngle: 80
          },
          labelLayout: function (params) {
            console.log(params);
            const isLeft = params.labelRect.x < myChart.getWidth() / 2;
            const points = params.labelLinePoints;
            // Update the end point.
            points[2][0] = isLeft
              ? params.labelRect.x
              : params.labelRect.x + params.labelRect.width;
            return {
              labelLinePoints: points
            };
          },
        },
      };
      let newPromise = new Promise((resolve) => {
        resolve();
      });
      newPromise.then(() => {
        var myChart = this.$echarts.init(document.getElementById(this.codMain));
        this.myChart = myChart
        console.log(option);
        myChart.setOption(option);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.chartOne {
  width: 100%;
  // height: 100%;
  margin: 0 auto;
  // margin-right: 100px;

  ::-webkit-scrollbar {
    border-radius: 6px;
    width: 5px !important;
    background-color: #ccc;
  }

  ::-webkit-scrollbar-thumb {
    //    
    border-radius: 6px;
    background-color: #989898;
    height: 30px;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .codMain {
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }

  h4 {
    padding-top: 10px;
    padding-left: 36px;
    font-size: 16px;
    color: rgb(76, 76, 76);
  }
}
</style>
